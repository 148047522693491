.info-page {
  h1 {
    font-size: 30px;
    margin-bottom: 30px;
    @include breakpoint($tablet) {
      margin-bottom: 60px;
      font-size: 50px;
    }
  }
  .date {
    color: $gray;
    text-transform: uppercase;
    margin-bottom: 15px;
    display: inline-block;
  }
  h2 {
    text-transform: none;
    margin-bottom: 15px;
    a {
      color: $gray-darker;
    }
  }
  .shares {
    @extend .clearfix;
  }
}

.info-box {
  @extend .box;
  margin-bottom: 60px;

  .holder {
    @extend .clearfix;
  }
}