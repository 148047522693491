.hover-element-script-practice {
  display: none;
  position: absolute;
  background: $gray-light;
  min-width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  top: -55px;
  left: -68px;
  @include border-radius(4px);
  &:after {
    @include trianle-bottom(10px, $gray-light);
    bottom: -10px;
    margin: auto;
    left: 0;
    right: 0;
  }
}

.user-course-page {
  ////
  /// Privremene konsultacije
  //
  section.consultation-form {
    h4 {
      margin-top: 0;
      margin-bottom: 12px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    button {
      margin-left: 10px;
    }
  }

  .course-lessons {
    header {
      .row {
        width: calc(100% - 55px);
      }
    }
    header {
      background-color: white;
      padding: 20px 0;
      font-size: 20px;
      position: relative;
      > .container {
        position: relative;
      }
      span {
        font-size: 18px;
      }
      h1 {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        @include breakpoint($mobile) {
          line-height: 46px;
          text-align: left;
        }
        @include breakpoint($tablet) {
          font-size: 36px;
        }
      }
      p {
        margin: 0 40px 0 0;
        display: inline-block;
        font-size: 16px;
      }
      .course-progress {
        width: calc(100% - 70px);
        .progress {
          width: 100%;
          background-color: $gray-light;
          height: 10px;
          .progress-bar {
            background-color: $blue;
            height: 10px;
            width: 0;
            transition: all 1s;
            transition-timing-function: ease-out;
          }
        }
        .cat-icon {
          display: none;
          position: absolute;
          top: -41px;
          right: -21px;
          @include breakpoint($desktop) {
            display: block;
          }
          .hover-element {
            display: none;
            background-color: $gray-light;
            width: 110px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            position: absolute;
            left: -32px;
            bottom: -54px;
            @include border-radius(4px);
            &:after {
              @include trianle-up(10px, $gray-light);
              top: -10px;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
          &:hover {
            .hover-element {
              display: inline-block;
            }
          }
          #c_cat {
            opacity: 0;
            transition: all 0.4s;
          }
        }
      }
      .left-column, .right-column {
        padding-left: 15px;
        padding-right: 15px;
      }
      .right-column {
        position: relative;
        padding-right: 0;
        @include breakpoint($mobile) {
          text-align: right;
        }
      }
      .info {
        line-height: 40px;
        font-family: $font-family-cond;
        @include breakpoint($mobile) {
          display: inline-block;
        }
      }
      .icon {
        position: absolute;
        display: inline-block;
        bottom: -5px;
        svg {
          width: 50px;
          height: 50px;
          path {
            &:first-child {
              fill: $blue;
            }
          }
        }
        &.finished {
          right: -7px;
          svg {
            width: 95px;
            height: 95px;
          }
        }
        &.regular {
          right: 14px;
        }
      }
      #c_title {
        display: inline-block;
        h1 {
          @include breakpoint(0 499px) {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
    .day {
      margin-bottom: 50px;
      > h2 {
        text-indent: 20px;
        margin-top: 0;
        font-size: 18px;
        text-transform: none;
        font-weight: normal;
        color: $blue-dark;
        @include breakpoint($tablet) {
          font-size: 24px;
        }
        span {
          color: $gray-darker;
        }
      }
      .lesson {
        background-color: white;
        border-bottom: 1px solid $gray-lighter;
        &:last-child {
          border-bottom: 0;
        }
        a {
          position: relative;
          padding: 10px;
          display: block;
          line-height: 0;
          color: $gray-dark;
          font-family: $font-family-cond;
          @include transition(color .4s);
          @include breakpoint($mobile) {
            padding: 15px;
          }
          &:hover, &:focus {
            color: $gray-darker;
          }
        }
        .lesson-state {
          float: left;
          display: inline-block;
          text-align: center;
          width: 24px;
          line-height: 24px;
          margin-right: 10px;
          margin-top: 5px;
          position: relative;
          @include breakpoint($mobile) {
            margin-right: 29px;
            margin-top: 0;
          }
          &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: calc(100% + 30px);
            background-color: $gray-lighter;
            right: 0;
            top: -15px;
            @include breakpoint($mobile) {
              right: -15px;
            }
          }
        }
        .lesson-state-1 { // not started
          @extend %lesson-state;
          @include breakpoint($mobile) {
            width: 24px;
            height: 24px;
            line-height: 24px;
          }
        }
        .lesson-state-2 { // in-progress
          @extend %lesson-state;
          border: 4px solid $orange;
          @include breakpoint($mobile) {
            width: 24px;
            height: 24px;
            line-height: 24px;
          }
        }
        .lesson-state-3 { // finished
          @extend %lesson-state;
          border: 4px solid $orange;
          background-color: $orange;
          @include breakpoint($mobile) {
            width: 24px;
            height: 24px;
            line-height: 24px;
          }
        }
        h3 {
          @extend %shorten-text;
          color: inherit;
          line-height: 24px;
          display: inline-block;
          font-weight: normal;
          text-transform: none;
          font-size: 14px;
          margin: 0;
          width: 50%;
          @include breakpoint($mobile) {
            font-size: 16px;
            width: 62%;
          }
          @include breakpoint($desktop-large) {
            width: 77%;
          }
        }
        .icons-holder {
          position: absolute;
          top: 14px;
          right: 10px;
          .scr-mat {
            padding-right: 9px;
            border-right: 1px solid $gray-light;
          }
        }
        .lesson-play, .lesson-script, .lesson-practice {
          display: inline-block;
          i {
            color: $blue-light;
            position: relative;
            top: 2px;
            @include breakpoint($mobile) {
              font-size: 16px;
            }
          }
          .numberCircle {
            position: absolute;
            top: -6px;
            right: -2px;
            width: 14px;
            height: 14px;
            background: $red;
            display: inline-block;
            line-height: 14px;
            text-align: center;
            border-radius: 50%;
            color: white;
            font-size: 10px;
          }
        }
        .lesson-practice {
          position: relative;
          .hover-element {
            @extend .hover-element-script-practice;
          }
          &:hover {
            .hover-element {
              display: block;
            }
          }
          i {
            margin-right: 3px;
          }
        }
        .lesson-duration {
          display: none;
          position: relative;
          top: -2px;
          margin-right: 5px;
          @include breakpoint($tablet) {
            display: inline-block;
          }
        }
        .lesson-play {
          font-size: 14px;
          line-height: 24px;
          min-width: 70px;
          text-align: right;
          @include breakpoint(0 543px) {
            min-width: 45px;
          }
          i {
            margin-left: 3px;
            @include breakpoint(0 543px) {
              display: none;
            }
          }
        }
        .lesson-script {
          position: relative;
          @include breakpoint(0 543px) {
            padding-right: 5px;
            margin-right: 0;
          }
          .hover-element {
            @extend .hover-element-script-practice;
          }
          &:hover {
            .hover-element {
              display: block;
            }
          }
        }
        &:hover {
          h3.title {
            text-decoration: underline;
          }
        }
        &.lesson-module-kviz {
          > a {
            height: 60px;
            @include breakpoint($mobile) {
              height: 80px;
            }
          }
          .title {
            width: 62%;
            font-weight: normal;
            line-height: 1.5;
            @include breakpoint($desktop) {
              font-size: 22px;
              line-height: 1.1;
            }
            b {
              font-size: 14px;
              text-transform: uppercase;
            }
          }
          .play-start-holder {
            float: right;
            margin-top: 9px;
            @include breakpoint($mobile) {
              margin-top: 13px;
            }
            @include breakpoint($desktop-large) {
              margin-top: 9px;
            }
            > button {
              @include breakpoint(0 1199px) {
                display: none;
              }
            }
          }
          .lesson-play {
            margin-right: 10px;
            line-height: normal;
            font-size: 16px;
          }
          .lesson-state {
            line-height: 1;
            margin-top: 13px;
            &:after {
              height: calc(100% + 45px);
              top: -23px;
              @include breakpoint($mobile) {
                height: calc(100% + 56px);
                top: -28px;
              }
            }
          }
        }
      }
      .quiz {
        background-color: white;
        @extend .clearfix;
        .quiz-head {
          background-color: darken($blue-dark, 20%);
          padding: 20px 30px;
          float: left;
          font-size: 16px;
          color: $blue;
          text-transform: uppercase;
          font-weight: bold;
          margin-right: 15px;
          line-height: 1;
        }
        .quiz-body {
          @extend .clearfix;
          padding: 8px;
          > * {
            float: left;
            &:not(.btn) {
              padding-top: 12px;
              padding-bottom: 12px;
            }
            &:first-child {
              position: relative;
              padding-left: 60px;
              &:before {
                content: "";
                position: absolute;
                width: 1px;
                height: calc(100% + 16px);
                background-color: $gray-lighter;
                top: -8px;
                left: 45px;
              }
            }
          }
          h3 {
            color: darken($blue-dark, 20%);
            font-size: 16px;
            margin: 0 0 0 10px;
            border-left: 1px solid $gray-lighter;
            padding-left: 9px;
          }
          a {
            float: right;
            position: relative;
            top: 10px;
          }

        }
        &.finished-quiz {
          .quiz-body {
            h2 {
              font-size: 30px;
              color: $blue;
              margin: 0;
              padding-top: 6px;
              padding-bottom: 6px;
            }
            h3 {
              text-transform: none;
              border-left: none;
              font-size: 20px;
              color: $gray-dark;
            }
          }
        }
      }
      &:last-of-type {
        margin-bottom: 90px;
      }
    }
    .total-info {
      padding: 18px 18px 13px;
      margin-top: -30px;
      h3.total-info-text {
        font-size: 24px;
        width: 100%;
        margin: 0;
      }
      .duration-info {
        padding-top: 1px;
        font-size: 16px;
      }
    }
  }
  .training-courses {
    .training-courses-title {
      margin-bottom: 20px;

    }
    margin-top: 36px;
    .course-list {
      padding-left: 0;
      list-style: none;
      .course-item {
        margin-bottom: 10px;
        .course_link {
          margin-bottom: 5px;
          font-size: 16px;
          color: $gray-darker;
          font-family: $font-family-cond;;
        }
        .progress {
          margin-top: 5px;
        }
      }
    }
  }
  .consultations {
    margin-top: 36px;
  }
  .consultations {
    background-color: $aqua-lighten;
    padding: 20px;

    strong {
      display: block;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 30px;
    }

    a {
      @extend .disable-shadow;
      font-weight: bold;
      text-transform: capitalize;
      @include breakpoint($tablet 991px) {
        padding: 5px 25px;
        min-width: 115px;
        height: 35px;
      }
    }
  }
  // CSS ZA ASIDE KURS POZICIJU START
  //.course-archive {
  //  @include breakpoint($desktop 1199px) {
  //    display: none;
  //  }
  //  margin-bottom: 50px;
  //  .holder {
  //    .hover {
  //      .detail-holder {
  //        @include breakpoint(0 1199px) {
  //          left: 50%;
  //          top: 50%;
  //          transform: translate(-50%, -50%);
  //        }
  //        @include breakpoint($desktop-large) {
  //          top: 24%;
  //        }
  //      }
  //    }
  //  }
  //  .title {
  //    font-size: 18px;
  //    margin-bottom: 10px;
  //  }
  //  footer {
  //    margin-top: 10px;
  //  }
  //  .badges>li>a, .badges>li span {
  //    padding: 0 16px 0 10px;
  //  }
  //  .badges>li>a svg, .badges>li span svg {
  //    width: 22px;
  //    height: 21px;
  //    top: 6px;
  //  }
  //  .badges>li>a>i, .badges>li>a svg, .badges>li span>i, .badges>li span svg {
  //    margin-left: 3px;
  //    margin-right: 4px;
  //  }
  //  &.large-box {
  //    .holder .hover .detail-holder {
  //      @include breakpoint($desktop-large) {
  //        top: 29%;
  //      }
  //    }
  //  }
  //}
  // CSS ZA ASIDE KURS POZICIJU END
  .course-archive {
    .title {
      @include breakpoint($tablet) {
        font-size: 24px;
        min-height: 52px;
      }
    }
    &:not(.large-box) .text {
      height: auto;
      margin-bottom: 10px;
    }
    >.text {
      min-height: 60px;
    }
  }
}

.courses-page {
  .course-entry {
    .content {
      .block-dates {
      }
      > section {
        &:nth-child(odd) {
          background-color: $gray-lighter;
          .holder-circle-main {
            background-color: white;
          }
        }
        &:nth-child(even) {
          background-color: white;
          .holder-circle-main {
            background-color: $gray-lighter;
          }
        }
      }
    }
  }
  &.online-page {
    #course-details {
      #course-details-content {
        article {
          > *:not(i) {
            font-size: 14px;

          }
        }
      }
      .modal-header {
        padding-bottom: 10px;
        position: relative;
        h3 {
          margin-bottom: 0;
        }
        &:after {
          display: none;
        }
        #courseLinkDetail {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          a {
            @extend .disable-shadow;
          }
        }
      }
      .modal-body {
        padding-top: 0;
      }
    }
  }
}

// samo za kurs detaljnije modal
#course-details {
  @include breakpoint(0 990px) {
    .modal-dialog {
      width: 90%;
      margin: 20px 10px 10px;
    }
  }
}