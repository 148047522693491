.forms-legend {
    border-bottom: 1px solid $gray-light;
    margin-bottom: 20px;
    legend {
        border-bottom: none;
        margin-bottom: 5px;
    }
}

.edit-profile {
    .icheck-orange.gender-radio {
        margin-top: 10px;
        @include breakpoint($desktop) {
            margin-top: 38px;
        }
    }
    .modal-btn {
        margin-top: -10px;
        @include breakpoint($desktop) {
            margin-top: -25px;
        }
    }
    form {
        > .btn {
            margin-bottom: 20px;
            @include breakpoint($tablet) {
                margin-bottom: 0;
            }
        }
    }
    .newsletter {
        font-family: $font-family-cond;
        .icheck-orange {
            label {
                cursor: pointer;
                margin-bottom: 0;
                font-weight: normal;
            }
            span {
                font-size: 18px;
                color: $gray-darker;
            }
        }
        .info {
            padding-left: 30px;
            padding-top: 5px;
            p {
                margin-bottom: 0;
            }
        }
    }
    .info-message-box {
        max-width: 1140px;
        transform: translateX(15px);
    }
}

.edit-profile, .user-portfolio {
    label[for]:not(.error) {
        position: relative;
        left: 10px;
    }
}

#user-panel {
    .avatar-holder {
        .user-avatar {
            a {
                @include avatar-img(140px);
                border: 5px solid $blue-dark;
                position: relative;
                @include breakpoint($tablet 990px) {
                    @include avatar-img(90px);
                }
                .icon-holder {
                    i {
                        font-size: 60px;
                        color: $orange;
                    }
                }
                .edit-avatar {
                    opacity: 0;
                    transition: all .3s;
                    line-height: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    width: 140px;
                    height: 140px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background: transparentize($gray-darker, .3);
                    @include border-radius(50%);
                    @include breakpoint($tablet 990px) {
                        width: 90px;
                        height: 90px;
                    }
                    i {
                        color: white;
                        font-size: 35px;
                        @include breakpoint($tablet 990px) {
                            font-size: 25px;
                        }
                    }
                    p {
                        font-size: 13px;
                        color: white;
                        @extend %bold-roboto-cond;
                        margin: 5px 0 0;
                    }
                }
                &:hover {
                    .edit-avatar {
                        opacity: 1;
                        transition: all .3s;
                    }
                }
            }
        }
        .holder {
            .registration-date {
                p {
                    margin: 0;
                    font-family: $font-family-cond;
                }
            }
        }
    }
    .profile-info {
        margin-top: -20px;
        margin-bottom: 40px;
    }
    .user-portfolio-content {
        .box {
            legend {
                margin-top: 10px;
            }
        }
        .portfolio-profile {
            form {
                .form-group.browse-group {
                    float: left;
                    width: 100%;
                    @include breakpoint(0 543px) {
                        margin-bottom: 20px;
                    }
                    @include breakpoint($mobile) {
                        float: none;
                        width: auto;
                    }
                }
                .btn-success {
                    float: left;
                    @include breakpoint($mobile) {
                        float: none;
                    }
                }
            }
        }
    }
}

.user-portfolio {
    .bordered-list {
        margin-bottom: 30px;
        @include breakpoint($desktop) {
            margin-bottom: 0;
        }
    }
    .char-remaining-text {
        margin-top: -25px;
        #counter{
            transition: all 0.8s;
            color: $gray-dark;
        }
    }
}