.contact-page {
    padding-bottom: 0 !important;

    .map-canvas {
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    main {
        min-height: 600px;
        @include breakpoint($tablet) {
            height: calc(100vh - 82px - 300px);
        }

        article {
            transition: all 400ms;
        }

        .minimizer {
            background-color: $gray;
            color: white;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .minimized {
            width: 60px !important;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .main-container {
        color: #000;
        position: relative;
        pointer-events: none;
        .btn-green {
            pointer-events: all;
        }
    }
    .full-height-background-element {
        background-color: #acbcc9; // Color of rivers at Google maps.
    }

    .contact-address {
        @extend .box;
        @include triangle(22px, -20px);
        .title {
            margin-bottom: 0;
            color: $gray-darker;
        }
        .address-title {
            color: $gray-darker;
            margin-bottom: 20px;
        }
        .left-col {
            @extend .col-sm-6;
            @extend .list-unstyled;
            @include breakpoint($tablet) {
                border-right: 1px solid rgba(153, 153, 153, .5);
            }
        }
        .right-col {
            @extend .col-sm-6;
            @extend .list-unstyled;
            @include breakpoint($tablet) {
                padding-left: 20px;
            }
        }
        li {
            color: $gray;
            margin-bottom: 0;
            font-size: 16px;
            font-family: $font-family-cond;
        }
        .link {
            color: $green;
        }
    }

    // School Info
    .school-info {
        &.box {
            pointer-events: all;
            h2 {
                font-size: 26px;
                margin: 0;
                &:nth-child(2) {
                    margin-bottom: 15px;
                }
                span {
                    text-transform: uppercase;
                }
            }
        }
        @include breakpoint($desktop) {
            &.box {
                width: 500px;
                &.has-arrow {
                    &:before {
                        content: " ";
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border: 10px solid transparent;
                        border-right: 10px solid #fff;
                        position: absolute;
                        top: 22px;
                        left: -20px;
                    }
                }
            }
        }
    }

    .modal-body{
        .contact-recaptcha{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            .g-recaptcha{
                display: inline-block;
                margin-bottom: 25px;
            }
            .btn{
                margin-bottom: 25px;
                display: inline-block;
            }
        }
    }
    #send-us-mail {
        @extend .clearfix;
        button {
            float: left;
        }
        .ks-viber {
            margin-left: 10px;
            pointer-events: all;
            width: 50px;
            height: 50px;
            border-radius: 3px;
            overflow: hidden;
            display: block;
            float: left;
            img {
                width: 50px
            }
            &:hover {
                opacity: .9;
            }
        }
    }
}