/* OLD CALENDAR */

.month,
.weekdays {
  ul {
    list-style-type: none;
  }
}

.days {
  list-style-type: none;
}

.month {
  padding: 70px 25px;
  width: 100%;
  background: #38b4d6;
  text-align: center;
}

.month ul {
  margin: 0;
  padding: 0;
}

.month ul li {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.month .prev {
  float: left;
  padding-top: 10px;
}

.month .next {
  float: right;
  padding-top: 10px;
}

.weekdays {
  margin: 0;
  padding: 10px 0;
  background-color: #ddd;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: #666;
  text-align: center;
}

.days {
  padding: 10px 0;
  background: #eee;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: #777;
}

.days li .active {
  padding: 5px;
  background: #38b4d6;
  color: white !important
}

.activeDay {
  background: gold !important;
}

.table {
  thead, tbody {
    th, td {
      font-size: 14px !important;
    }
  }
}

/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {
  .weekdays li, .days li {
    width: 13.1%;
  }
}

@media screen and (max-width: 420px) {
  .weekdays li, .days li {
    width: 12.5%;
  }
  .days li .active {
    padding: 2px;
  }
}

@media screen and (max-width: 290px) {
  .weekdays li, .days li {
    width: 12.2%;
  }
}

/* OLD CALENDAR */

/* NEW CALENDAR */

#calendar {
  width: 290px;
  font-family: $font-family-cond;
  ul {
    @extend .list-unstyled;
  }
  .label, .dates {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    li {
      width: 14.2857143%;
      padding: 10px 0;
      text-align: center;
    }
  }
  .label {
    li {
      font-size: 16px;
      color: #ccc;
      text-transform: uppercase;
    }
  }
  .dates {
    li {
      cursor: pointer;
    }
  }
  .greenBackground {
    background-color: $green-new1;
  }
  .active-cell {
    background-color: $blue-new1;
  }
  .greenBackground,
  .active-cell {
    color: white;
  }
}

.calendar-holder {
  border-bottom: 1px solid #E0DEDC;
  margin-bottom: 20px;
  @include breakpoint($tablet) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
  }
  .bigDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      color: $green-new1;
      font-size: 20px;
      font-family: $font-family-goth;
    }
    .date-day {
      font-size: 50px;
      line-height: 1;
      @include breakpoint($desktop) {
        font-size: 96px;
      }
    }
  }
}

/* NEW CALENDAR */