%hover-element {
  transition: all .3s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: transparentize($navy, .3);
  @include breakpoint($desktop) {
    opacity: 0;
    cursor: none;
    background-color: transparent;
  }
}

%on-mouse-hover {
  @include breakpoint($desktop) {
    transition: all .3s;
    cursor: pointer;
    background-color: transparentize($navy, .3);
    opacity: 1;
  }
}

%button-hover-style {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
}

.home-page {
  .main-container {
    padding-top: 25px;
    @include breakpoint($tablet) {
      padding-top: 50px;
    }
  }
  .course-archive {
    margin-bottom: 50px;
    @include breakpoint(1920px) {
      footer {
        .ratings-sprite-holder {
          .ratings-sprite {
            background-position: 0 97%;
          }
        }
      }
    }
  }
  .students-box {
    margin-bottom: 50px;
    .name a {
      font-size: 18px;
    }
    footer {
      @extend .clearfix;
      border: none;
      padding-top: 0;
      .left {
        float: left;
        text-transform: uppercase;
        padding-top: 13px;
        color: $gray;
        font-size: 12px;
      }
    }
    .featured-info:hover {
      opacity: .8;
    }
  }

  .baner-company {
    position: relative;
    margin-bottom: 48px;
    padding-top: 10px;
    background-color: #539cd5;
    .bc-body {
      padding: 0 20px 30px;
      p {
        color: white;
        font-size: 15px;
        font-family: $font-family-cond;
        letter-spacing: 0.5px;
        line-height: 1.4;
        margin-bottom: 30px;
      }
      h2 {
        font-family: $font-family-goth;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        margin-top: -20px;
        margin-bottom: 20px;
      }
      .btn {
        display: table;
        margin: 0 auto;
        padding: 7px 50px;
      }
    }
  }

  .top-students {
    position: relative;
    background-image: url("../images/top-list-img.jpg");
    background: #273655;
    background-image: url("../images/top-list-img.jpg"), -moz-linear-gradient(top, #223150 0%, #405B90 100%);
    background-image: url("../images/top-list-img.jpg"), -webkit-linear-gradient(top, #223150 0%, #405B90 100%);
    background-image: url("../images/top-list-img.jpg"), linear-gradient(to bottom, #223150 0%, #405B90 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#223150', endColorstr='#405B90', GradientType=0);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    padding-top: 20px;
    margin: 0 auto 50px;
    width: 304px;
    height: 350px;
    @include breakpoint($mobile) {
      padding-top: 40px;
      height: 418px;
      max-width: 361px;
      width: 100%;
    }
    @include breakpoint($desktop) {
      height: 340px;
    }
    @include breakpoint($desktop-large) {
      height: 418px;
    }
    .img-holder {
      position: relative;
      margin-bottom: 20px;
      .user-photo {
        > img {
          width: 80px;
          margin: 0 auto;
        }
        .student-icons {
          display: block;
          margin: 0 auto;
        }
      }
      > span {
        display: block;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        svg {
          width: 25px;
        }
      }
    }
    h3, h4 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    h3 {
      color: white;
      margin-bottom: 5px;
    }
    h4 {
      color: #FFE05D;
      margin-bottom: 0;
    }
    .hover {
      a {
        @extend %button-hover-style;
      }
      @extend %hover-element;
      &:hover {
        @extend %on-mouse-hover;
      }
    }
  }

  .promo-video {
    position: relative;
    display: table;
    margin: 0 auto 48px;
    .holder {
      a {
        @extend %button-hover-style;
      }
      .hover {
        @extend %hover-element;
        &:hover {
          @extend %on-mouse-hover;
        }
      }
    }
  }

  // Privremeni css za banere
  .baner-social {
    text-align: center;
    margin-bottom: 50px;
    display: block;
    iframe {
      display: block;
      margin: auto;
    }
    .fb_page span {
      margin-bottom: 50px;
    }
  }
  .home-cover {
    position: relative;

    display: none;
    @include breakpoint($mobile 1199px) {
      display: block;
    }
    .holder {
      bottom: 33px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      @include breakpoint($desktop-large) {
        width: 930px;
      }
      h2 {
        font-size: 22px;
        text-transform: uppercase;
        text-align: center;
        color: white;
        border-bottom: 3px solid white;
        font-weight: bold;
        @include breakpoint($tablet) {
          margin-bottom: 20px;
          font-size: 50px;
        }
        @include breakpoint($desktop-large) {
          font-size: 80px;
        }
      }
      h3 {
        margin: 0;
        color: white;
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
        @include breakpoint($tablet) {
          font-size: 30px;
        }
        @include breakpoint($desktop-large) {
          font-size: 40px;
        }
      }
    }
  }
}

// Comments hover menu
.comments-hover-menu {
  opacity: 0;
  position: absolute;
  width: 0;
  text-align: left;
  bottom: 30px;
  padding-bottom: 0;
  left: -5px;
  @include border-radius(3px);
  transition: padding-bottom .3s;
  z-index: -10;
  cursor: default;
  @include breakpoint($tablet) {
    bottom: 60px;
  }
  > .comments-holder {
    display: none;
    padding: 15px 15px 20px;
    background-color: $navy-user;
    &:after {
      opacity: 0;
      @include trianle-bottom(8px, $navy-user);
      left: 20px;
      bottom: 8px;
      transition: bottom .3s;
    }
  }
  header {
    margin-bottom: 15px;
    @extend .clearfix;
  }
  .img-holder {
    float: left;
    margin-right: 10px;
    font-size: 0;
    .user-avatar {
      > img {
        border: 2px solid white;
        display: block !important;
        @include avatar-img(40px);
      }
      &.background {
        .w100 {
          width: 40px;
          height: 40px;
          line-height: 40px;
          img {
            width: 25px;
          }
        }
      }
    }
  }
  .info-holder {
    float: left;
    h3 {
      margin-top: 0;
      font-size: 16px;
      color: white;
      text-transform: capitalize;
      margin-bottom: 5px;
    }
    h4 {
      font-size: 12px;
      color: $blue-light;
      text-transform: capitalize;
      margin: 0;
      span {
        color: white;
      }
    }
  }
  q {
    font-size: 12px;
    display: block;
    color: white;
    margin-bottom: 20px;
    &:after, &:before {
      content: "";
    }
    &:before {
      display: none;
    }
  }
  a {
    display: table;
    margin: auto;
  }
}

// Students comments
.students-comments {
  margin-top: 20px;
  margin-bottom: 10px;
  @include breakpoint($tablet) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  ul {
    @extend .list-unstyled;
    @extend .clearfix;
    li {
      float: left;
      margin-right: 10px;
      cursor: pointer;
      margin-bottom: 5px;
      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.comments-more {
        line-height: 40px;
        margin-right: 10px;
        @include breakpoint($tablet) {
          line-height: 60px;
        }
      }
      font-size: 20px;
      font-family: $font-family-cond;
      color: $gray-light;
      @include breakpoint($tablet) {
        font-size: 34px;
      }
      &:hover {
        .comments-hover-menu {
          opacity: 1;
          padding-bottom: 25px;
          transition: padding-bottom .3s;
          z-index: 10;
          width: 290px;
          > .comments-holder {
            display: block;
            &:after {
              opacity: 1;
              bottom: 17px;
              transition: bottom .3s;
            }
          }
        }
        @include breakpoint($tablet) {
          .comments-hover-menu {
            left: 5px;
          }
        }
      }
      @include breakpoint(0 400px) {
        &:first-child  {
          &:hover {
            .comments-hover-menu {
              left: -21px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 34px;

              }
            }
          }
        }
        &:nth-child(2) {
          &:hover {
            .comments-hover-menu {
              left: -75px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 87px;

              }
            }
          }
        }
        &:nth-child(3) {
          &:hover {
            .comments-hover-menu {
              left: -118px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 130px;

              }
            }
          }
        }
        &:nth-child(4) {
          &:hover {
            .comments-hover-menu {
              left: -178px;
              width: 280px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 189px;

              }
            }
          }
        }
      }
      @include breakpoint(401px 543px) {
        &:nth-child(3) {
          &:hover {
            .comments-hover-menu {
              left: -45px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 58px;

              }
            }
          }
        }
        &:nth-child(4) {
          &:hover {
            .comments-hover-menu {
              left: -95px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 108px;

              }
            }
          }
        }
        &:nth-child(5) {
          &:hover {
            .comments-hover-menu {
              left: -135px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 147px;

              }
            }
          }
        }
        &:nth-child(6) {
          &:hover {
            .comments-hover-menu {
              left: -185px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 198px;

              }
            }
          }
        }
        &:nth-child(7) {
          &:hover {
            .comments-hover-menu {
              left: -145px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 158px;

              }
            }
          }
        }
        &:nth-child(8) {
          &:hover {
            .comments-hover-menu {
              left: -195px;
              z-index: 999 !important;
              .comments-holder:after {
                left: 208px;

              }
            }
          }
        }
      }
      @include breakpoint($mobile 767px) {
        &:nth-child(6) {
          &:hover {
            .comments-hover-menu {
              left: -50px;
              .comments-holder {
                &:after {
                  left: 63px;
                }
              }
            }
          }
        }
        &:nth-child(7) {
          &:hover {
            .comments-hover-menu {

              left: -230px;
              .comments-holder {
                &:after {
                  left: 243px;
                }
              }
            }
          }
        }
        &:nth-child(8) {
          &:hover {
            .comments-hover-menu {
              left: -240px;
              .comments-holder {
                &:after {
                  left: 253px;
                }
              }
            }
          }
        }
      }
      &.comments-more {
        a {
          color: $gray-light;
        }
      }
      >.user-avatar {
        &.background {
          @include breakpoint(0 767px) {
            .student-icons.w100 {
              width: 40px;
              height: 40px;
              line-height: 40px;
              img {
                width: 25px;
              }
            }
          }
        }
      }
      @include breakpoint(0 400px) {
        &:not(.comments-more) {
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            display: none;
          }
        }
      }
      @include breakpoint(401px 500px) {
        &:not(.comments-more) {
          &:nth-child(7),
          &:nth-child(8) {
            display: none;
          }
        }
      }
    }
    li:not(.comments-more) {
      background-color: $gray-lighter;
      text-align: center;
      position: relative;
      width: 40px;
      height: 40px;
      @include border-radius(50%);
      @include breakpoint($tablet) {
        width: 60px;
        height: 60px;
      }
      > img {
        @include avatar-img (40px);
        @include breakpoint($tablet) {
          @include avatar-img (60px);
        }
      }
      [class^='ksi-'] {
        color: $blue;
        font-size: 28px;
        @include breakpoint($tablet) {
          font-size: 42px;
        }
      }
    }
  }
}

.free-space-school {
  @extend .clearfix;
  margin-bottom: 10px;
  @include breakpoint($mobile) {
    margin-bottom: 0;
  }
  span {
    float: left;
    @include avatar-img (40px);
    display: inline-block;
    background-color: $blue;
    margin-right: 10px;
    i {
      font-size: 20px;
      color: white;
    }
  }
  p {
    font-size: 16px;
    color: $gray-dark;
    float: left;
    margin-bottom: 0;
    line-height: 40px;
  }
}



