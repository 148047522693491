.impressions-page {

  .avatar-holder {
    height: 75px;
    position: relative;
  }

  .impressions-box {
    margin-bottom: 50px;
    article {
      .user-avatar {
        position: static;
        margin: 0 auto 5px;
        @include breakpoint($tablet) {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .name {
        text-align: center;
        margin-bottom: 20px;
        @include breakpoint($tablet) {
          margin-bottom: 5px;
        }
        a {
          font-weight: bold;
          color: $gray-darker;
        }
        @include breakpoint($tablet) {
          text-align: left;
        }
      }
    }
    .avatar-holder {
        margin-bottom: 15px;
    }
  }
}

.impressions-box {
  @extend .box;
  h2 {
    text-transform: none;
    margin-bottom: 0;
    font-weight: bold;
  }
  .holder {
    @extend .clearfix;
    margin-bottom: 10px;
  }
  .date {
    float: right;
    text-transform: uppercase;
    font-family: $font-family-cond;
    margin-top: 10px;
    color: $gray;
  }
  h3 {
    text-transform: uppercase;
    color: $red;
    margin-bottom: 20px;
    font-size: 18px;
  }

  article {
    .user-avatar {
      @include user-avatar(75px);
      .student-icons {
        &.w100 {
          width: 75px;
          height: 75px;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
          }
        }
      }
    }
    .name {
      margin-top: 0;
      text-transform: none;
      font-size: 16px;
      color: $gray-darker;
    }
    q {
      display: inline-block;
      margin-bottom: 15px;
      min-height: 30px;
      &:after, &:before {
        content: '';

      }
    }
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 20px;
    q + a {
      display: table;
    }
  }
}

.online-impression {
    .impressions-box {
        .student-icons.w50 {
            @extend .w100;
         }
        border-bottom: none;
        padding-bottom: 0;
    }
}