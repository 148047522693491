.baner-gallery {
  width: 100%;
  overflow: hidden;
  padding-top: 25px;
  position: relative;
  top: -25px;
  margin-bottom: -25px;
  .badges {
    top: 0;
  }
  >div {
    min-width: 3000px;
    margin-left: 50%;
    @media (max-width: 1380px) {
      min-width: 2400px;
    }
    >.popup-gallery {
      @extend .clearfix;
      width: 100%;
      margin-left: -50%;
      >a {
        float: left;
        width: 250px;
        @media (max-width: 1380px) {
          width: 200px;
        }
        >img {
          width: 100%;
        }
      }
    }
  }
}