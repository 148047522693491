%user-header-title-style {
  header.header {
    padding-bottom: 15px;
    border-bottom: 5px solid $gray-lighter;
    margin-bottom: 50px;
    h2 {
      font-size: 26px;
      text-transform: uppercase;
    }
    p {
      margin: 0;

    }
  }
}

#user-panel {
    .consultation-info-header {
        position: relative;
        min-height: 170px;
        text-align: center;
        background-color: $gray-lighter;
        padding-top: 30px;
        @include breakpoint($mobile) {
            min-height: 170px;
        }
        @include breakpoint($tablet) {
            min-height: 215px;
        }
        @include breakpoint($desktop-large) {
            padding-top: 50px;
        }
        h2 {
            text-transform: uppercase;
        }
        p {
            font-size: 16px;
            color: $gray-darker;
            span {
                font-size: 22px;
            }
            .tiket-link {
                color: $orange-light;
            }
        }
    }
    .content-filters {
        margin-bottom: 30px;
        @include breakpoint($tablet) {
            margin-bottom: 0;
        }
        li {
            a {
                &.consultation-tabs {
                    text-transform: uppercase;
                    &.active, &:hover {
                        background-color: white !important;
                    }
                    &.tab-orange {
                        background-color: $orange;
                    }
                    &.tab-green {
                        background-color: $green;
                    }
                }
                i {
                    position: relative;
                    top: 4px;
                    margin-right: 15px;
                    @include breakpoint($tablet 990px) {
                        display: none;
                    }
                }
            }
        }
    }
    .user-consultation-content {
      @extend %user-header-title-style;
        form {
            fieldset {
                label {
                    font-size: 16px;
                }
            }
            .add-file, .add-file-info {
                float: left;
            }
            .add-file {
                margin-right: 10px;
                margin-bottom: 5px;
                @include breakpoint($tablet) {
                    margin-bottom: 0;
                }
            }
            .add-file-info {
                margin-bottom: 15px;
                @include breakpoint($tablet) {
                    line-height: 50px;
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .text-right {
                text-align: left;
                @include breakpoint($desktop) {
                    text-align: right;
                }
            }
            .termins {
                .form-group {
                    margin-bottom: 0;
                    @include breakpoint($tablet) {
                        margin-bottom: 30px;
                    }
                }
                p {
                    margin-bottom: 30px;
                    @include breakpoint($tablet) {
                        margin-bottom: 0;
                        margin-top: 22px;
                        line-height: 50px;
                    }
                }
            }
            .select2-container--default .select2-selection--single {
                background-color: $gray-lighter;
            }
            .md-editor {
                .editor-toolbar {
                    i {
                        color: transparent;
                        text-shadow: none !important;
                    }
                    &.fullscreen {
                        z-index: 60;
                    }
                }
                .CodeMirror-fullscreen {
                    z-index: 60;
                }
                .editor-preview-side {
                    z-index: 60;
                }
            }
        }
        table {
            .state {
                margin-right: 8px;
                position: relative;
                font-size: 26px;
                height: 18px;
                float: left;
                top: -10px;
            }
            .state-r {
                color: $red;
            }
            .state-g {
                color: $green;
            }
            .state-b {
                color: $blue;
            }
        }
        .available-table {
            tr.expired {
                td {
                    color: $red;
                }
                .expiration {
                    font-weight: bold;
                }
            }
        }
    }
}

// KUPOVINA KONSULTACIJA
#kupi {
    .subtitle {
        margin-bottom: 60px;
    }
    table {
        width: 100%;
        margin-bottom: 40px;
      font-size: 16px;
        p {
            display: none;
            @include breakpoint($tablet) {
                display: inline;
            }
        }
        td, th {
            padding: 20px;
            &:first-child {
                padding-left: 0;
            }
            &:not(:last-of-type) {
                border-right: solid 1px $gray-light;
            }
        }
        thead {
            border-bottom: solid 4px $gray-light;
            th {
                padding: 5px 20px;
            }
            .amount {
                width: 140px;
            }
        }
        tbody {
            border-bottom: solid 4px $gray-light;
            tr {
                border-bottom: solid 2px $gray-light;
            }
            input {
                width: 100px;
            }
          h4 {
            font-size: 16px;
          }
        }
        tfoot {
            td {
                &:first-child {
                    text-align: right;
                }
                &:last-child {
                    background-color: $gray-light;
                }
            }
            h3 {
                margin-bottom: 0;
            }
        }
    }
}

// KONSULTACIJE TICKET RAZGOVOR
.user-consulting {
    .consulting-head {
        padding-bottom: 10px;
        border-bottom: solid 4px $gray-light;
        margin-bottom: 40px;
        overflow: hidden;
        h3 {
            margin-bottom: 15px;
        }
        p.c-detail {
            line-height: 32px;
            float: left;
        }
        .categories {
            list-style-type: none;
            float: left;
            display: inline;
            padding-left: 30px;
            li {
                display: inline;
                float: left;
                padding: 6px 15px;
                margin-right: 5px;
                background-color: $gray-light;
                border-radius: 3px;
            }
        }
    }

    .consulting-body {
        border-left: solid 3px $gray-light;
        padding-left: 50px;
        margin-left: 37px;
        .message {
            position: relative;
            padding-bottom: 25px;
            margin-bottom: 40px;
            border-bottom: solid 3px $gray-light;
            .user-avatar {
                width: 70px;
                height: 70px;
                position: absolute;
                left: -87px;
                top: -20px;
                border: solid 3px $gray-light;
                border-radius: 50%;
                img {
                    width: 70px;
                }
                i {
                    font-size: 51px;
                    &.ksi-male {
                        margin-left: -1px;
                    }
                }
                &.background {
                    background-color: white;
                    overflow: hidden;
                }
            }
            .name {
                margin-bottom: 0;
            }
            .timestamp {
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .content {
                margin-bottom: 40px;
            }
            &:nth-child(2) {
                border-bottom: none;
            }
        }
        .student-icons {
            &.w100 {
                width: 64px;
                height: 64px;
                line-height: 64px;
                img {
                    width: 35px;

                }
            }
        }
    }
}

.consultation-confirmation {
    table.consultation-table {
        width: 100%;
        th, td {
            font-size: 16px;
            padding: 13px;
            color: white;
            border-right: none;
        }
        h2, h3, h4 {
            color: white;
        }
        .column-sm {
            width: 135px;
            white-space: nowrap;
        }
        .column-md {
            width: 235px;
            white-space: nowrap;
        }
        tbody {
            tr {
                background-color: transparent !important;
                border-bottom: solid 1px white;
                border-top: solid 1px white;
            }
        }
        tfoot {
            .bor-bot-white {
                border-bottom: solid 1px white;
            }
        }
    }
}

.user-consultation {
    .buy-consultation-holder {
        @extend .clearfix;
        margin-bottom: 60px;
        button {
            float: left;
            @include breakpoint(0 543px) {
                margin-bottom: 20px;
            }
            @include breakpoint($mobile) {
                float: right;
            }
        }
        p {
            float: left;
            @include breakpoint($mobile) {
                float: right;
            }
            margin-right: 20px;
            margin-bottom: 0;
            @include breakpoint($mobile) {
              line-height: 50px;
            }
        }
    }
    .info-message-box {
        max-width: 1140px;
        transform: translateX(15px);
    }
}
