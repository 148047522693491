.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}
.table {
  thead {
    tr {
      th {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $gray-lighter;
      }
    }
  }
  thead, tbody {
    tr {
      td, th {
        border: none;
        color: $gray-darker;
        padding: 13px;
        border-right: 1px solid $gray-light;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}