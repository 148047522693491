.owl-carousel {
    // display none until init
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;

    .owl-wrapper {
        // Clearfix
        &:after {
            content: ".";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0;
        }

        display: none;
        position: relative;
        -webkit-transform: translate3d(0px, 0px, 0px);
    }
    .owl-wrapper-outer {
        overflow: hidden;
        position: relative;
        width: 100%;

        &.autoHeight {
            -webkit-transition: height 500ms ease-in-out;
            -moz-transition: height 500ms ease-in-out;
            -ms-transition: height 500ms ease-in-out;
            -o-transition: height 500ms ease-in-out;
            transition: height 500ms ease-in-out;
        }
    }

    .owl-item {
        float: left;
    }

    // fix
    .owl-wrapper,
    .owl-item {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility:    hidden;
        -ms-backface-visibility:     hidden;
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
    }
}

// Controls
.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .owl-page,
    .owl-buttons div {
        cursor: pointer;
    }
}

// mouse grab icon
.grabbing { 
    cursor: url(#{$owl-image-path}grabbing.png) 8 8, move;
}
