.badges {
    @extend .list-unstyled;
    @extend .clearfix;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    z-index: 30;
    > li {
        float: left;
        width: 50%;
        > a, span {
            @include border-radius(50px 0 50px 50px);
            display: inline-block;
            padding: 0 18px;
            background-color: white;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: white;
            font-size: 14px;
            text-transform: uppercase;
            // blue
            &.blue {
                background-color: $blue;
            }
            &.green {
                background-color: $green;
            }
            &.orange {
                background-color: $orange;
            }
            &.red {
                background-color: $red-lighter;
            }
            > i {
                display: none;
            }
            svg {
                display: none;
                height: 29px;
                position: relative;
                top: 10px;
            }
        }
    }
    @include breakpoint($desktop) {
        top: -25px;
        > li {
            display: inline-block;
            overflow: hidden;
            position: relative;
            //&.first {
            //    right: 22px;
            //}
            //&.second {
            //    left: 22px;
            //}
            > a, span {
                height: 50px;
                line-height: 50px;

                > i, svg {
                    display: inline-block;
                    margin-left: -5px;
                }
            }
        }
    }
    &.single-badge {
        >li {
            width: 100%;
        }
    }
}