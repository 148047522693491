.wishlist {
  width: 32px;
  height: 32px;
  @include border-radius(50%);
  position: relative;
  border: 2px solid;
  transition: all .3s;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -2px;
    margin: auto;
    width: 16px;
    height: 14px;
  }
  &.case-one {
    border-color: white;
    svg {
      path {
        fill: white;
        transition: all .3s;
      }
    }
  }
  &.case-two {
    border-color: #CCCCCC;
    svg {
      path {
        fill: #CCCCCC;
        transition: all .3s;
      }
    }
  }
  &.case-three {
    border-color: $red-light;
    svg {
      path {
        fill: $red-light;
        transition: all .3s;
      }
    }
    &:hover {
      border-color: white;
      transition: all .3s;
      svg {
        path {
          fill: white;
          transition: all .3s;
        }
      }
    }
  }
  &:hover {
    border-color: $red-light;
    transition: all .3s;
    svg {
      path {
        fill: $red-light;
        transition: all .3s;
      }
    }
  }
}

.course-archive {
  .wishlist {
    display: block;
    margin: 10px auto 0;
  }
}

// Header dd wishlist

.user-wishlist {
  @extend .user-notification;
  .icons-holder {
    svg {
      width: 20px;
      top: 8px;
    }
    .not-badge {
      background-color: #FABA71;
    }
  }
  .user-dd-menu {
    a {
      position: relative;
    }
  }
  .wishlistIcon {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 25px;
    left: 25px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #d75a4a;
    padding-top: 6px;
    svg {
      width: 16px;
      height: 14px;
    }
  }
}