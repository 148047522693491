.page-404 {
  background-color: $blue-dark;
  color: white;
  .content {
    font-family: $font-family-cond;
    display: table;
    margin: 0 auto;
    h2 {
      @include breakpoint($desktop) {
        font-size: 50px;
      }
      color: white;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
    }
    .svg-holder {
      margin-top: 30px;
      margin-bottom: 20px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    h3 {
      font-size: 26px;
      color: white;
      margin-bottom: 20px;
    }
    ol {
      @include list-unstyled;
      font-size: 18px;
      margin-bottom: 40px;
    }
    .holder {
      margin-bottom: 50px;
      a:first-child {
        @include breakpoint(0 543px) {
          display: table;
          margin: 0 0 20px;
        }
        @include breakpoint($mobile) {
          margin-right: 20px;
        }
      }
    }
  }
}