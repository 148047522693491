@mixin popup-variant($name, $bkg-color) {
  .popup-#{$name} {
    background-color: $bkg-color;
  }
}

@include popup-variant(error, $red);
@include popup-variant(success, $green);
@include popup-variant(info, $blue);

.popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100%;
  padding: 10px;
  @include breakpoint(0 990px) {
    &:not(.man-case) {
      padding: 40px 30px 10px;
    }
  }
  @include breakpoint($mobile) {
    width: 90%;
  }
  @include breakpoint($tablet) {
    width: 80%;
    &:not(.man-case) {
      .info-holder {
        padding-left: 105px;
      }
    }
  }
  @include breakpoint($tablet 1199px) {
    &:not(.man-case) {
      .info-holder {
        padding-top: 40px;
      }

      .button-holder {
        .btn {
          min-width: 100px;
          padding: 12px 27px;
        }
      }
    }
  }
  @include breakpoint($desktop) {
    padding: 20px 30px;
  }
  @include breakpoint (1800px) {
    width: 1140px;
    padding: 4px 0 5px 65px;
    &.man-case {
      padding: 12px 68px 15px 53px;
    }
  }
  .info-holder, .img-holder {
    float: left;
    @include breakpoint(0 767px) {
      width: 100%;
    }
    @include breakpoint($tablet 1799px) {
      width: 50%;
    }
  }
  .img-holder {
    padding: 20px 0;
    @include breakpoint(0 500px) {
      padding: 50px 0 10px;
    }
  }
  .holder {
    text-align: center;
  }
  .title {
    text-align: center;
    color: white;
    margin-bottom: 30px;
  }
  .close-button {
    cursor: pointer;
  }
  .close-absolute {
    position: absolute;
    right: 20px;
    top: 10px;
    color: white;
    font-size: 24px;
  }
  .finish {
    .button-holder {
      margin-bottom: 15px;
      overflow: hidden;
      a {
        @include breakpoint($desktop) {
          padding: 12px 25px;
          &:first-child {
            margin-right: 10px !important;
          }
        }
      }
    }
    @include breakpoint($tablet) {
      margin-top: 30px;
    }
    * {
      color: white;
      font-family: $font-family-cond;
    }
    img {
      @include breakpoint(0 767px) {
        width: 80px;
        margin: 0 auto;
      }
    }
    h2 {
      text-align: center;
      @include breakpoint($desktop) {
        text-align: left;
      }
      @include breakpoint($desktop-large) {
        padding-top: 40px;
        font-size: 50px;
      }
      @include breakpoint(1600px) {
        padding-top: 77px;
        font-size: 70px;
        margin-bottom: 0;
      }
    }
    h3 {
      text-align: center;
      margin-bottom: 20px;
      @include breakpoint($desktop) {
        text-align: left;
      }
      @include breakpoint($desktop-large) {
        font-size: 30px;
        margin-bottom: 25px;
      }
      @include breakpoint(1600px) {
        font-size: 36px;
        margin-bottom: 36px;
      }
    }
    a.btn:not(.btn-publish-profil) {
      display: table;
      margin: 0 auto 10px;
      @include breakpoint($desktop) {
        float: left;
        &:not(:only-of-type) {
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
    .quiz-info-holder {
      margin-bottom: 53px;
      font-size: 20px;
      @include breakpoint(0 991px) {
        text-align: center;
      }
      @include breakpoint(0 1088px) {
        margin-bottom: 20px;
      }
      @include breakpoint(1600px) {
        font-size: 26px;
      }
      p {
        @include breakpoint(0 991px) {
          text-align: center;
        }
        @include breakpoint($desktop-large) {
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }
    .quiz-score {
      font-size: 22px;
      font-weight: bold;
      width: 60px;
      height: 60px;
      display: inline-block;
      text-align: center;
      line-height: 60px;
      background-color: #FAC336;
      &:first-of-type {
        @include breakpoint($desktop-large) {
          margin-left: 25px;
          margin-right: 10px;
        }
      }
    }
  }
  .social-net {
    .share-info {
      clear: both;
      display: block;
      color: white;
      @include breakpoint(0 767px) {
        margin-top: 20px;
      }
    }
    p {
      @include breakpoint(0 767px) {
        margin-bottom: 20px !important;
        float: none !important;
      }
    }
    @include breakpoint(0 767px) {
      display: table;
      margin: auto;
    }
    @include breakpoint($tablet) {
      float: right;
    }
    @include breakpoint($desktop) {
      position: absolute;
      bottom: 20px;
    }
    @extend .clearfix;
    p {
      float: right;
      color: white;
      text-transform: uppercase;
      font-family: $font-family-cond;
      font-size: 16px;
      position: relative;
      top: 5px;
      font-weight: bold;
      margin: 0;
      text-align: center;
    }
    ul {
      @extend .list-unstyled;
      @extend .clearfix;
      display: table;
      margin: 0 auto 5px;
      @include breakpoint($desktop) {
        margin: 0;
        float: right;
        margin-left: 20px;
      }
      li {
        float: left;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        a {
          width: 32px;
          height: 32px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          i {
            color: white;
          }
          &.fb {
            background-color: $facebook;
          }
          &.tw {
            background-color: $twitter;
          }
          &.in {
            background-color: $linkedin;
          }
        }
      }
    }
  }
  &:not(.man-case) {
    .social-net {
      @include breakpoint($tablet) {
        .share-info {
          position: relative;
          left: 55px;
        }
      }

      @include breakpoint($tablet 990px) {
        padding-right: 50px;
        padding-bottom: 20px;
      }
      @include breakpoint($desktop) {
        right: 70px;
      }
      @include breakpoint($desktop-large) {
        right: 12%;
      }
      @include breakpoint(1400px) {
        right: 15%;
      }
      @include breakpoint(1600px) {
        right: 18%;
      }
      @include breakpoint(1700px) {
        right: 20%;
      }
    }
  }
  &.man-case {
    .finish {
      @include breakpoint($tablet) {
        margin-top: 0;
        .img-holder {
          float: right;
        }
      }
    }
    .social-net {
      @include breakpoint($tablet) {
        float: left;
        ul {
          display: block;
          margin: 0 20px 0 0;
          float: left;
        }
      }
      .share-info {
        @include breakpoint($tablet) {
          position: relative;
          left: 56px;
        }
      }
    }
  }
  &:not(.man-case) {
    .finish {
      @include breakpoint($tablet) {
        margin-top: 0;
      }
    }

    .social-net {
      @include breakpoint($tablet) {
        float: right;
        ul {
          display: block;
          margin: 0 20px 0 0;
          float: left;
        }
      }
      @include breakpoint($desktop) {

      }
    }
  }

  .dont-show {
    position: absolute;
    right: 60px;
    top: 12px;
  }

  @media (max-width: 767px) and (max-height: 695px) {
    .finish {
      h3 {
        margin-bottom: 10px;
      }
      .quiz-info-holder {
        margin-bottom: 25px;
      }
      .quiz-score {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      a.btn {
        margin: 0 auto 10px;
      }
    }
  }
  @media (max-width: 543px) and (max-height: 510px) {
    .social-net {
      p {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 767px) and (max-height: 570px) {
    .img-holder {
      padding: 30px 0 0;
    }
    .finish {
      .quiz-info-holder {
        margin-bottom: 15px;
        p {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
  @media (max-width: 767px) and (max-height: 480px) {
    .finish img {
      display: none;
    }
  }
  @media (max-width: 767px) and (max-height: 460px) {
    .finish {
      .button-holder {
        text-align: center;
        a.btn {
          min-width: auto;
          height: 40px;
          margin: 0;
          display: inline-block;
          font-size: 16px;
          padding: 0 20px;
          line-height: 40px;
        }
      }
      .social-net {
        p {
          margin-bottom: 0;
        }
        .share-info {
          margin-top: 5px;
        }
      }
    }
  }
  @media (max-width: 767px) and (max-height: 330px) {
    .info-holder {
      >h3 {
        display: none;
      }
    }
    .finish {
      .button-holder {
        display: none;
      }
    }
  }
}

.popup-bgd {
  &:after {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparentize(black, .2);
    top: 0;
    left: 0;
    z-index: 400;
  }
}
