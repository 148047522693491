#classroom {
    .quiz-section {
        padding: 0;
        padding-bottom: 30px;
        margin: 130px auto;
        .quiz-section-child {
            background-color: white;
            //min-height: 100%;
            h1 {
                background-color: $aqua-light;
                color: white;
                text-transform: uppercase;
                padding: 18px 20px 18px 39px;
                letter-spacing: 6.8px;
                margin-bottom: 25px;
            }
            .lead {
                padding: 0 42px;
                color: $gray;
            }
            .answers {
                padding: 0 135px;
                margin-bottom: 25px;
                > * {
                    float: left;
                    clear: both;
                    text-align: left;
                }
                .btn {
                    background-color: transparent;
                    font-size: 18px;
                    margin-bottom: 3px;
                    display: inherit;
                    color: $gray;
                    &.selected {
                        background-color: $blue-light;
                        color: white;
                    }
                    &.correct {
                        background-color: $green;
                        color: white;
                        &:hover {
                            background-color: $green;
                        }
                    }
                    &.wrong {
                        color: white;
                        background-color: $red;
                        &:hover {
                            color: white;
                            background-color: $red;
                        }
                    }
                }
                .explanation {
                    margin-bottom: 0;
                    background-color: transparent;
                    color: $green;
                    font-size: 17px;
                }
                & + p {
                    margin-bottom: 0;
                }
            }
            p {
                .btn {
                    display: table;
                    margin: auto;
                    color: white !important;
                }
            }
        }
        .quiz-results {
            .btn {
                color: white;
            }
        }
        .quiz-section-child {
            .result-circle {
                width: 200px;
                height: 200px;
                margin: 50px auto;
                .progressbar-text {
                    letter-spacing: 2px;
                    font-size: 26px;
                    color: $gray-light;
                    text-align: center;
                }
            }
            p {
                color: $blue;
                font-size: 21px;
                margin-bottom: 30px;
            }
        }
        .btn-answer {
            width: 100%;
        }
    }
}

.quiz-home-content {
    height: 100%;
    padding: 55px 174px;
    h2 {
        font-size: 18px;
        text-transform: uppercase;
        color: $blue-light;
        margin-top: 0;
        letter-spacing: 9px;
        padding-bottom: 20px;
        border-bottom: 1px solid $blue-light;
        margin-bottom: 20px;
        text-align: center;
    }
    h1 {
        font-size: 30px;
        text-transform: uppercase;
        margin-top: 0;
        font-weight: bold;
        color: $blue-dark;
        text-align: center;
        margin-bottom: 2px;
    }
    p {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin-bottom: 33px;
    }
    .btn {
        display: table;
        margin: 0 auto;
        color: #fff !important;
    }
}

/*Kviz index end*/

/*Quiz results start*/
.quiz-results {
    #graph {
        margin: 0 auto;
        svg {
            text {
                tspan {
                    fill: $blue-light;
                }
            }
        }
    }
    > p {
        font-size: 20px;
        text-align: center;
    }
    > .btn {
        display: table;
        margin: 0 auto;
    }
}


/*Quiz results end*/

@media (max-height: 880px) {
    #classroom {
        .quiz-section {
            margin: 120px auto;
        }
    }
}

@media (max-height: 820px) {
    #classroom {
        .quiz-section {
            margin: 50px auto;
        }
    }
}

@media (max-height: 768px) {
    #classroom {
        .quiz-section {
            .quiz-section-child {
                .answers {
                    padding: 0 50px;
                }
            }
        }
    }
}

@media (max-height: 680px) {
    #classroom {
        overflow-y: auto;
        .quiz-section {
            position: static;
            margin: 55px auto;
        }
    }
}


@media (max-width: 544px) {
    #classroom {
        .quiz-section {
            .quiz-section-child {
                .answers {
                    padding: 0 15px;
                    .btn {
                        font-size: 16px;
                    }
                }
            }
        }
        .quiz-home-content {
            height: 100%;
            padding: 45px 30px;
        }
    }
}


// CSS samo za završen kviz - novi dizajn START

.quiz-article-result {
    margin-left: -30px;
    margin-right: -30px;
    .quiz-results {
        @include breakpoint($tablet) {
            display: flex;
        }
        .circle-holder {
            margin-bottom: 40px;
            @include breakpoint($tablet) {
                margin-right: 40px;
            }
            #quiz-result-circle {
                margin: 0 auto;
            }
        }
        .content-holder {
            display: flex;
            flex-direction: column;
            flex: 1;
            .badges-conquer-title {
                margin-bottom: 40px;
                p {
                    margin-bottom: 0;
                    font-size: 16px;
                    color: #333;

                }
            }
            .wrong-answer {
                h3 {
                    font-family: $font-family-goth;
                    text-transform: uppercase;
                    font-size: 20px;
                    color: #333333;
                    margin-bottom: 25px;
                }
                .quiz-article-question {
                    width: 100%;
                    font-family: $font-family-cond;
                    margin-bottom: 40px;
                    .answers {
                        margin-bottom: 15px;
                    }
                    .quiz-question-title {
                        font-size: 20px;
                        color: $gray-darker;
                        margin-bottom: 10px;
                    }
                    
                    .answer {
                        padding: 10px;
                        font-size: 16px;
                        color: $gray-darker;
                        &.correct {
                            background-color: transparent;
                            padding-left: 14px;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        &.exact {
                            color: $green-new1;
                        }
                    }
                    >a {
                        height: 30px;
                        min-width: 135px;
                        padding: 4px 10px;
                        margin-left: 33px;
                    }
                }
            }
            .button-holder {
                a {
                    @include breakpoint(0 543px) {
                        &:first-child:not(:only-child) {
                            display: table;
                            margin-bottom: 20px;
                        }
                    }
                }
                @include breakpoint($mobile) {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}
.quizzes-section {
    position: relative;
}
.loadingElement {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: loadingRotate 2s infinite linear;
    animation: loadingRotate 2s infinite linear;
    i {
        font-size: 60px;
    }
}
@-webkit-keyframes loadingRotate {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
    }
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
@keyframes loadingRotate {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
    }
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

// CSS samo za završen kviz - novi dizajn END