// Base
@import 'base/fonts';
@import "base/vars";
@import "base/mixins";
@import "base/extenders";
//@import "base/animations";
@import "base/ks-icons";
@import "base/reset";
/*******************************************!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
// Vendors
@import "bootstrap_override";
@import "breakpoint";
// Base
@import "base/global";
@import "base/helpers";
@import "base/layout";
@import "base/text";
@import "base/user-panel";
@import "base/classroom";
// Components
@import "components/header";
@import "components/footer";
@import "components/forms";
@import "components/buttons";
@import "components/dropdowns";
@import "components/box";
@import "components/block";
@import "components/alerts";
@import "components/sub-header";
@import "components/toptitle";
@import "components/modals";
@import "components/badges";
@import "components/icheck";
@import "components/two-columns";
@import "components/select2";
@import "components/video-player";
@import "components/categories";
@import "components/profile-breadcrumbs";
@import "components/courses-alerts";
@import "components/large-course";
@import "components/featured-portfolio";
@import "components/students-recommendations";
@import "components/baner-gallery";
@import "components/badge";
@import "components/progress-bar";
@import "components/training-courses";
@import "components/browse-buttons";
@import "components/table";
@import "components/popup";
@import "components/footer-bottom-cards";
@import "components/custom_checkbox";
@import "components/cookies-popup";
@import "components/message-info-box";

@import "components/instructor";
@import "components/arrow-separator";
@import "components/pdf";
@import "components/pagination";
@import "components/feedback";
@import "components/user-notification";
@import "components/aside-toggle";

@import "components/homepageSlider";

@import "components/wishlist";
@import "components/popup-userBadges";
@import "components/calendar";
@import "components/switch";
@import "components/rating-stars";
@import "components/action-countdown";
@import "components/action-sticker";
// Pages
@import "pages/contact";
@import "pages/courses";
@import "pages/login";
@import "pages/enrollment";
@import "pages/user";
@import "pages/user-course";
@import "pages/quiz";
@import "pages/my-courses";
@import "pages/home";
@import "pages/impressions";
@import "pages/students";
@import "pages/showcase";
@import "pages/gallery";
@import "pages/student";
@import "pages/info";
@import "pages/user-profile";
@import "pages/user-payment";
@import "pages/about";
@import "pages/user-activation";
@import "pages/consultation";
@import "pages/404";
@import "pages/top-list";
@import "pages/user-badges";
@import "pages/calendar-activity";
@import "pages/typing-game";
@import "pages/quiz-test";
@import "pages/external-lecture";
@import "pages/company-team";
@import "pages/landing-page";
// Plugins
@import "plugins/magnific-popup";
@import "plugins/masterslider";
@import "plugins/simplemde";
@import "plugins/bootstrap-tour";
@import "plugins/master-black-skins";
@import "plugins/OwlCarousel/owl-carousel";
@import "plugins/ladda-themeless";
// CKE
@import "cke";
// Mihailo gazi jer mi se može
@import "components/mihailo";