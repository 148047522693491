.action-sticker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FEC024;
  text-align: center;
  line-height: 50px;
  font-family: $font-family-cond;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  z-index: 10;
  position: absolute;
  top: -10px;
  left: -10px;
}