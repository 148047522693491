@mixin generate-block($bg-color, $color: white) {
    width: 100%;
    padding: 30px 0;
    background-color: $bg-color;
    color: $color;
    .title {
        color: $color;
        margin-top: 0;
    }
    @include breakpoint($desktop) {
        padding: 80px 0;
    }
}

.block-white {
    @include generate-block(white, $gray-darker)
}

.block-green {
    @include generate-block($green)
}


.block-transparent {
    @include generate-block(transparent, $gray-dark)
}

@mixin generate-block-40($bg-color) {
    width: 100%;
    background-color: $bg-color;
    @include breakpoint($mobile) {
        padding: 10px;
    }
    @include breakpoint($tablet) {
        padding: 20px;
    }
    @include breakpoint($desktop-large) {
        padding: 40px;
    }
}

.block-white-40 {
    @include generate-block-40(white)
}