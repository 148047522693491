.reset-pb {
  padding-bottom: 0 !important;
}
.reset-pt {
  padding-top: 0 !important;
}
.reset-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt30 {
  padding-top: 30px !important;
}

.reset-list {
  ul {
    @extend .list-unstyled;
  }
}