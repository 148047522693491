.login-page {
  .lead {
    margin-bottom: 50px;
    font-size: 16px;
  }

  .forgot-password-link {
    display: inline-block;
    margin-top: 15px;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px $green-dark inset;
    -webkit-text-fill-color: white !important;
  }

  .facebook-login {
    padding: 6px 15px;
    color: white;
    background-color: $facebook;
    text-transform: none;
    .fb-logo {
      padding-right: 9px;
      margin-left: -5px;
      font-size: 24px;
      border-right: solid 1px white;

    }
    .fb-text {
      padding-left: 15px;
      padding-right: 5px;
      font-size: 16px;
      position: relative;
      top: -2px;
    }
    .visibleSocials {
      @include breakpoint(0 1199px) {
        display: none;
      }
    }
  }
}

.password-reset-page {
  .lead {
    margin-bottom: 20px;
    font-size: 16px;
  }
  h2.title {
    @include breakpoint($tablet) {
      font-size: 50px;
    }
  }
  .user-password-content {
    padding-top: 40px;
    padding-left: 20px;
    p {
      font-size: 16px;
    }
  }
}

#google-login {
  padding: 7px 26px 2px 11px;
  background: #dd4b39;
  i {
    background: url("../images/gplus.png") no-repeat;
    width: 30px;
    height: 30px;
    display: inline-block;
    background-size: contain;
    border-right: solid 1px white;
    padding-right: 35px;
    margin-right: 2px;
  }
  > span {
    vertical-align: unset;
    position: relative;
    top: -9px;
  }
}

.registration {
  #facebook-login {
    @include breakpoint(0 767px) {
      display: table;
    }
  }
  #google-login {
    @include breakpoint($desktop-large) {
      float: right;
    }
  }
  .green-alt {
    @include breakpoint($mobile 560px) {
      padding: 12px 20px 11px;
    }
  }
  .socialsHolder {
    &.largeDevices {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      #facebook-login {
        float: left;
        margin-bottom: 0;
      }
      #google-login {
        float: right;
        clear: none !important;
      }
    }
  }
}

.login-page {
  .registration {
    .facebook-login {
      .fb-logo {
        padding-right: 5px;
      }
      .fb-text {
        padding-right: 5px;
        font-size: 14px;
        padding-left: 6px;
      }
    }
    #google-login {
      padding: 7px 26px 2px 6px;
    }
  }
  .login, .registration {
    .socialsHolder {
      float: right;
      #facebook-login {
        margin-bottom: 15px;
        @include breakpoint($mobile) {
          margin: 0 10px 0 0;
        }
        @include breakpoint($desktop) {
          margin: 0 0 15px 0;
        }
      }
      #google-login {
        clear: both;
        display: table;
        @include breakpoint($mobile) {
          clear: none;
        }
        @include breakpoint($desktop) {
          clear: both;
        }
      }
      .facebook-login {
        @include breakpoint($mobile) {
          float: left;
        }
        @include breakpoint($desktop-large) {
          float: right;
        }
      }
    }
  }
  .login {
    .green-alt {
      @include breakpoint(0 340px) {
        padding: 12px 20px 11px;
      }
    }
  }
  .registration {
    .green-alt {
      @include breakpoint(0 370px) {
        padding: 8px 10px 8px;
        font-size: 16px;
      }
    }
  }
}