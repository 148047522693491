html#html_pdf {
    padding: 0;
}

#pdf {
    position: relative;
    _width: 21cm;
    _height: 29.7cm;
    margin: 0 auto;
    color: $gray-dark !important;
    _background: white;
    font-size: 14px;
    font-family: 'DejaVu Sans', sans-serif !important;
    background-color: transparent;
    _color: #58585A !important;

    a {
        color: #38b4d6 !important;
        text-decoration: none !important;
    }

    .logo {
        text-align: left;
        img {
            height: 50px;
        }
    }

    .company {
        text-align: right;
        .street {
            font-size: 12px;
        }
    }

    .details {
        margin-bottom: 50px;
    }

    .client {
        padding-left: 6px;
        border-left: 6px solid $blue;
        text-align: left;
        font-size: 16px;
        vertical-align: middle;
        .to {
            color: $gray-darker;
        }
    }

    .name {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
    }

    .invoice {
        text-align: right;
        vertical-align: middle;
        h1 {
            color: $gray-darker;
            font-size: 2.4em;
            line-height: 1em;
            font-weight: normal;
            margin: 0 0 10px 0;
        }
        .date {
            font-size: 1.1em;
            color: $gray-darker;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 20px;
        th, td {
            padding: 20px;
        }
        th {
            text-align: center;
            white-space: nowrap;
            font-weight: normal;
        }
        td {
            text-align: right;
            h3 {
                color: $green;
                font-size: 14px; //1.2em;
                font-weight: normal;
                margin: 0 0 0.2em 0;
            }
        }
        .desc {
            text-align: left;
            em {
                font-size: 12px;
            }
        }
        .total {
            color: white;
        }
        tfoot {
            td {
                padding: 10px 20px;
                background: #FFFFFF;
                border-bottom: none;
                font-size: 14px; //1.2em;
                white-space: nowrap;
            }
            tr {

                &:first-child td {
                    border-top: none;
                }
                &:last-child td {
                    font-size: 16px; //1.4em;
                }
                &:last-child td {
                    border: none;
                }
                td:first-child {
                    border: none;
                }
            }
            .foot-wrapper {
                width: 21cm;
                .loc-time, .stamp, .signature {
                    float: left;
                }
                .loc-time {
                    position: relative;
                    top: 70px;
                    width: 30%;
                }
                .stamp {
                    width: 25%;
                    img {
                        width: 200px;
                    }
                }
                .signature {
                    width: 30%;
                    position: relative;
                    top: 70px;
                }
            }
        }
    }

    .thanks {
        font-size: 2em;
        margin-bottom: 50px;
    }

    .notices {
        padding-left: 6px;
        border-left: 6px solid $blue;
    }

    .notices .notice {
        font-size: 1.2em;
    }

    footer {
        color: $gray-darker;
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        border-top: 1px solid $gray;
        padding: 8px 0;
        text-align: center;
    }

    #info-table, #certificate-table {
        margin-bottom: 20px;
        th, td {
            vertical-align: top;
            padding: 10px 0 !important;
            background: transparent;
        }
        .number {
            font-size: 16px;
            b {
                font-size: 16px;

            }
        }
    }
}

#info-table {
    .company {
        font-size: 12px !important;
        div, a {
            font-size: 12px !important;
        }
    }
    .invoice {
        .number, .date {
            font-size: 12px !important;
        }
    }
}

#invoice-table {
    th {
        border-right: 1px solid $gray;
        border-bottom: 1px solid $gray;
        &:not(:last-child) {
            border-right: 1px solid $gray;
        }
    }
    td:not(:last-child) {
        border-right: 1px solid $gray;
    }
    td {
        border-right: 1px solid $gray;
    }
    .clean-row {
        td {
            height: 20px;
            border: none !important;
        }
    }
    .sum-price {
        border-top: 1px solid $gray;
    }
    tfoot {
        td{
            border-right: none;
        }
    }
}
//table .no {
//    color: #FFFFFF;
//    font-size: 1.6em;
//    background: #57B223;
//}

//#pdf {
//    background-color: transparent;
//    .table-clear {
//        tbody > tr {
//            background-color: transparent;
//            > td, > th {
//                padding: 0;
//                border-color: transparent;
//            }
//        }
//    }
//}

#pdf {
    //width: 210mm;
    //height: 297mm;
    //outline: 1px solid red;
}

#certificate-table {
    font-family: $font-family-goth-book !important;
    td {
        text-align: left !important;
    }
    .logo {
        margin-bottom: 47px;
        img {
            height: 60px;
        }
    }
    .heading {
        font-size: 26px;
        color: #63647A;
        margin-bottom: 10px;
    }
    .certificate {
        z-index: 1;
        position: relative;
        left: -50px;
        margin-bottom: 0;
    }
    .finalCourse {
        font-size: 20px;
        padding-right: 108px;
        margin-bottom: 22px;
        line-height: 1.2;
    }
    .studentName {
        font-size: 46px; //51px;
        margin-bottom: 25px;
        position: relative;
        left: -47px;
        line-height: 1;
        img {
            padding-top: 20px;
        }
        .leftA {
            padding-right: 20px;
        }
        .rightA {
            padding-left: 15px;
        }
    }
    .trained {
        //font-size: 21px;
        font-size: 18px;
        padding-right: 150px;
        letter-spacing: -0.5px;
        line-height: 1.2;
        margin-bottom: 65px;
    }
    .lines {
        position: relative;
        left: -30px;
        margin-bottom: 40px;
    }
    .loc-time {
        position: relative;
        font-family: $font-family-goth-thin;
    }
    &.english {
        .logo {
            margin-bottom: 50px;
        }
    }

}
