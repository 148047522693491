@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

$land-pink: #DFB1C5;
$land-pink2: #DF6F87;
$black: #000;




.landing-page {
  font-family: 'Raleway', sans-serif;
  background-color: white;

  * {
    font-family: 'Raleway', sans-serif;
  }

  ul,ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: white;
    outline: none;
    box-shadow: none;
    display: inline-block;
  }

  .land-btn {
    font-size: 14px;
    padding: 9px 20px;
    border-radius: 30px;
    transition: all .3s;
    font-weight: bold;

    @media (min-width: 1200px) {
      padding: 11px 36px;
    }

    &.md {
      font-size: 18px;

      @media (min-width: 1200px) {
        font-size: 22px;
        padding: 11px 57px;
      }
    }
    &.lg {
      font-size: 22px;
      padding: 11px 70px;

      @media (min-width: 1200px) {
        padding: 12px 108px;
      }
    }
    &.uppercase {
      text-transform: uppercase;
    }

    &.pink {
      background-color: $land-pink;
      border-bottom: 3px solid $black;

      &:hover {
        background-color: lighten($land-pink, 8%);
      }
    }

    &.black {
      background-color: $black;
      border-bottom: 3px solid #DEB4C7;

      &:hover {
        background-color: lighten($black, 8%);
      }
    }
  }

  .cover-submit {
    border: 1px solid #D3E7F0;
    font-size: 18px;

    @media (min-width: 1200px) {
      padding: 14px 28px;
    }

    &:hover {
      background-color: transparentize(white, .9);
    }
  }

  footer {
    padding-bottom: 45px;
    background: url('../images/landing-footer.png') no-repeat top center;
    height: 250px;
    display: flex;
    align-items: flex-end;

    @media (min-width: 992px) {
      height: 314px;
    }

    .signature {
      display: flex;
      flex-direction: column;

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
    h4 {
      color: white;
      font-size: 16px;
    }
    small {
      color: white;
    }
    .left {
      margin-bottom: 20px;
      @media (min-width: 992px) {
        margin-bottom: 0;
      }
      small {
        font-size: 16px;
      }
    }
  }


  .modal-dialog {
    width: 97%;

    @media (min-width: 1200px) {
      width: 1020px;
    }
  }

  .modal-body {
    padding: 50px 70px 50px 60px;

    @media (min-width: 1200px) {
      padding: 50px 90px 70px 100px;
    }

    .close {
      color: $black;
      font-size: 30px;
      position: absolute;
      right: 30px;
      top: 20px;
      padding: 0 10px;
    }

    .form-group {
      margin-bottom: 20px;

      label {
        margin-left: 10px;
      }
    }

    .modal-title {
      text-transform: none;
      font-size: 28px;
      margin-bottom: 50px;

      @include breakpoint($desktop-large) {
        font-size: 36px;
      }
    }

    .modal-subtitle {
      display: block;
      font-size: 16px;
    }

    .discount-notes {
      margin-bottom: 30px;
      span {
        display: block;
        margin-bottom: 5px;
      }
    }

    .user-choice {
      margin-bottom: 50px;
      .title {
        font-size: 30px;
        margin-bottom: 10px;
      }
      span {
        display: block;
        margin-bottom: 5px;
      }
    }

    .price-wrapper {
      margin-bottom: 60px;
      .title {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .submit-wrap {
      text-align: center;

      button {
        text-transform: none;
      }
    }
  }

  .col-md-6 {
    @media (min-width: 992px) and (max-width: 1199px) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }
  .col-lg-4 {
    @media (min-width: 1200px) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }

  .alert {
    width: 80%;
    padding: 20px 30px;
    position: absolute;
    z-index: 99;
    top: 3%;
    right: 5%;

    @include breakpoint($tablet) {
      max-width: 554px;
      width: auto;
      padding: 30px 50px;
    }

    h2 {
      color: $black;
      font-weight: bold;
      font-size: 20px;
    }
    p {
      font-size: 20px;
      color: white;
      margin-bottom: 0;
    }
  }

  .select2 {
    &.select2-container {
      width: 100% !important;
    }
    .select2-search__field {
      height: 42px;
      margin: 0;
    }
  }
  .select2-container--default .select2-selection--multiple {
    border-color: #eee;

    .select2-selection__rendered {
      padding: 0 27px 0 5px;
    }
  }

  .custom-container {
    @include breakpoint($desktop-large) {
      width: 960px;
    }
  }
  .form-wrap.modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .title-landing2 {
    font-size: 28px;
    margin-bottom: 50px;
    padding-top: 50px;

    @include breakpoint($desktop-large) {
      font-size: 30px;
      padding-top: 80px;
    }
    .modal-subtitle {
      display: block;
      font-size: 16px;
    }
  }
}


.landing-page-main {

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    position: relative;
    z-index: 1;

    @media (max-width: 380px) {
      flex-direction: column;
      align-items: flex-start;
    }


    .logo {
      width: 140px;

      @media (min-width: 544px) {
        width: 160px;
      }

      @media (min-width: 1200px) {
        width: 231px;
      }

      a {
        display: block;
        width: 100%;
        height: 45px;
        background: url('../images/krojaceva-skola-logo-white.png') no-repeat;
        background-size: contain;
        text-indent: -9999px;
      }
    }

    .land-nav {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media (min-width: 544px) {
        flex-direction: row;
      }

      .login {
        margin-bottom: 10px;

        @media (min-width: 544px) {
          margin-right: 20px;
          margin-bottom: 0;
        }

      }
    }
  }
  .cover {
    border-bottom: 5px solid $land-pink2;
    position: relative;
    height: 80vh;

    @media (max-width: 1199px) {

      position: relative;
      background-image: url('../images/landing-cover.jpg');
      background-repeat: no-repeat;
      background-position: top center;
      background-attachment: fixed;
      background-size: cover;
    }

    @media (min-width: 1200px) {
      height: 70vh;
    }

    @media (min-width: 1600px) {
      height: 750px;
    }

    .video-wrap {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;

      @media (min-width: 1200px) {
        display: block;
      }
      video {
        max-width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        min-width: 100%;
      }
    }

    &:after {
      content: "";
      background-image: url("../images/land-cover-bg-shape2.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 100%;
      //bottom: calc(-100% + 80px);
      bottom: -100%;
      left: 0;
      z-index: -1;

      @media (min-width: 1920px) {
        background-size: 100%;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparentize($black, .4);
      z-index: 1;
    }

    .title-wrap {
      position: relative;
      z-index: 1;
      margin-top: 8%;
      margin-left: 6%;

      @media (min-width: 1200px) {
        margin-top: 3%;
      }

      @media (min-width: 1600px) {
        margin-top: 13%;
      }

      .title {
        color: white;
        font-size: 24px;
        margin-bottom: 20px;

        @media (min-width: 1600px) {
          font-size: 46px;
        }
      }
      p {
        color: white;
        font-size: 14px;
        margin-bottom: 30px;
        width: 90%;

        @media (min-width: 1200px) {
          width: 70%;
          margin-bottom: 40px;
        }

        @media (min-width: 1600px) {
          font-size: 16px;
        }
      }
    }
  }

  .content {
    position: relative;
    z-index: 2;
    margin-top: -10%;

    @media (min-width: 1200px) {
      margin-top: -2%;
    }

    @media (min-width: 1600px) {
      margin-top: -8%;
    }
  }


  .product-courses-wrap {

    margin-bottom: 110px;

    @media (min-width: 1200px) {
      margin-bottom: 180px;
    }

    .courses-box {
      color: $black;

      margin-bottom: 30px;

      .img-wrap {
        position: relative;
        cursor: pointer;
        text-align: center;
        margin-bottom: 20px;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          transition: all .3s;
          opacity: 0;
          background: rgba(250,250,250,.3);
          z-index: 5;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }

        img {
          margin: 0 auto;
        }

        .promo-badge {
          min-width: 84px;
          height: 26px;
          background-color: #FF4D4D;
          position: absolute;
          display: flex;
          align-items: center;
          padding-left: 10px;
          right: -10px;
          top: 20px;
          z-index: 99;

          &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 0;
            border-color: #E10000 transparent transparent transparent;
            position: absolute;
            bottom: -10px;
            right: 0;
          }

          span {
            text-transform: uppercase;
            color: white;
          }
        }
      }

      .price-wrap {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: $black;

        .holder {
          display: flex;
          align-items: flex-end;
          margin-bottom: -8px;
        }

        .from {
          margin-bottom: 15px;
          margin-right: 10px;
        }

        .price {font-size: 32px;}
        .currency {font-size: 22px;}
        .monthly {
          display: block;
          font-size: 26px;
        }
      }

      .checkbox-wrap {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 10;
      }

      .styled-checkbox {
        position: absolute;
        opacity: 0;

        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          outline: none !important;
        }

        & + label:before {
          content: '';
          display: inline-block;
          vertical-align: text-top;
          width: 16px;
          height: 16px;
          background: white;
        }

        &:checked + label:before {
          background: $pink;
        }

        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        &:checked + label:after {
          content: '';
          position: absolute;
          left: 3px;
          top: 8px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow:
                  2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white;
          transform: rotate(45deg);
        }
      }

      .title {
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 10px;
        text-align: center;
        color: $black;
      }
      p {
        @media (min-width: 992px) {
          padding: 0 10px;
        }
        @media (min-width: 1200px) {
          padding: 0 20px;
        }

        a {
          color: #37779C;
        }
      }

    }

    .button-wrap {
      text-align: center;
      margin-top: 30px;

      @media (min-width: 1200px) {
        margin-top: 60px;
      }

      .land-btn {
        @media (min-width: 1200px) {
          width: 284px;
          height: 58px;
          transform: translateX(-20px);
        }
      }
    }
  }

  .student-recommendation-wrap {
    margin-bottom: 60px;
    margin-top: 20px;

    @media (min-width: 1200px) {
      margin-bottom: 110px;
    }

    .st-title {
      font-size: 36px;
      margin-bottom: 20px;
    }

    .st-desc {
      font-size: 16px;
      color: #202020;
      margin-bottom: 40px;
    }

    .student-recommendation {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      @media (min-width: 1200px) {
        flex-direction: row;
      }

      .img-wrap {
        margin-bottom: 50px;
        padding-top: 15px;

        @media (min-width: 1200px) {
          margin-bottom: 0;
          margin-right: 30px;
        }

        .bordered-wrap {
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 164px;
            height: 164px;
            background-color: #EBEEF7;
            border-radius: 50%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
          }
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
        }
      }

      .content-wrap {
        p {
          margin-bottom: 20px;
        }
        .title {
          font-size: 16px;
          margin-bottom: 5px;
          font-weight: bold;
        }
        .course-name {
          color: #222222;
        }
      }
    }

    .button-wrap {
      text-align: center;
    }

    .carousel-indicators {
      left: 0;
      width: 100%;
      top: 180px;

      @media (min-width: 1200px) {
        bottom: -15px;
        top: auto;
        width: 150px;
      }

      li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #EBEEF7;
        border: none;
        margin-right: 3px;

        &.active {
          width: 10px;
          height: 10px;
          background-color: #333;
        }
      }
    }
  }

  .company-privileges-wrap {
    .cp-title {
      font-size: 36px;
      margin-bottom: 10px;
      color: $black;
    }
    .cp-desc {
      font-size: 16px;
      margin-bottom: 60px;
      color: $black;
    }

    .company-privileges-box {
      margin-bottom: 30px;
      padding-top: 20px;
      border-top: 3px solid #CDD6E2;
      padding-bottom: 40px;

      .mini-logo {
        margin-bottom: 22px;
      }

      .title {
        font-size: 20px;
        margin-bottom: 20px;
        color: $black;
      }
      p {
        margin-bottom: 0;
        color: $black;
      }
    }
  }
}

