.categories-box {
    @extend .box25;
}

.cat-title {
    font-size: 26px;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase;
}

.filter-title {
    @extend .cat-title;
    color: $aqua;
}

.bordered-list {
    @extend .list-unstyled;
    text-transform: uppercase;
    font-family: $font-family-cond;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 10px;
    }
    li > a, label {
        display: block;
        padding: 12px 0 11px;
        font-size: 16px;
        position: relative;
        border-bottom: 1px solid $gray-light;
    }
    li > a {
        color: $gray-darker;
        &:hover {
            color: lighten($gray-darker, 30);
        }
        &:after {
            content:"\00bb";
            position: absolute;
            right: 0;
            font-size: 18px;
            top: 50%;
            -ms-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    label {
        color: $gray;
    }
    li {
        &.active {
            a {
                &:hover {
                    color: $gray-darker;
                }
            }
        }
        &:last-child {
            > a {
                border-bottom: none;
            }
        }
    }
    label {
        &:last-child {
            border-bottom: none;
        }
    }
    li {
        &.active {
            font-weight: bold;
        }
    }
}

.courses-subcategory {
    @extend .list-unstyled;
    li {
        a {
            display: block;
            padding: 5px 3px 5px 10px;
            font-size: 16px;
            border-bottom: 1px solid $gray-lighter;
            color: $gray;
            text-transform: none;
            @extend .clearfix;
            &:after {
                display: none;
            }
            .counter-num-courses {
                float: right;
            }
        }
        &:last-child {
            a {
                border-bottom: none;
            }
        }
    }
}