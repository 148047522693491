.courses-page {
  aside {
    margin-bottom: 50px;
  }

  .modal-body {
    .program-recaptcha {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      .g-recaptcha {
        display: inline-block;
        margin-bottom: 25px;
      }
      .btn {
        margin-bottom: 25px;
        display: inline-block;
      }
    }
    .block-courses {
      padding: 0;
    }
  }
}

.cover {
  position: relative;
  @include breakpoint(0 543px) {
    overflow: hidden;
  }
  >img {
    @include breakpoint(0 543px) {
      height: 200px;
      max-width: none;
    }
  }
  .icon-play-holder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    i {
      font-size: 30px;
      color: white;
      line-height: 1.2;
      @include breakpoint(0 767px) {
        position: relative;
        top: -15px;
        font-size: 30px;
      }
      @include breakpoint($desktop) {
        font-size: 60px;
      }
    }
    .wishlist {
      display: table;
      margin: 0 auto 5px !important;
      @include breakpoint($tablet) {
        width: 36px;
        height: 36px;
        svg {
          width: 20px;
          height: 18px;
        }
      }
    }
    .rating-students {
      font-family: $font-family-cond;
      font-size: 16px !important;
      font-weight: bold;
      color: white;
    }
    .ratings-sprite-holder {
      margin: 2px auto 7px;
    }
    //.stars-ratings {
    //  @include breakpoint(0 991px) {
    //    left: -7px;
    //  }
    //}
  }
}

.play-video-responsive {
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-blue;
  display: none;
  margin: 30px 0 20px;
}

@media (max-width: 543px) {
  .play-video-responsive {
    display: block;
  }
}

// Archive
.course-archive {
  margin-bottom: 50px;

  //@include breakpoint(0 1024px) {
  //  .escape {
  //    overflow-x: hidden;
  //  }
  //}

  // thumb image
  .holder {
    transition: all 400ms;
    position: relative;
    display: table;
    margin: 0 auto;
    @include breakpoint($desktop) {
      display: block;
      margin: 0;
    }
    .hover {
      @extend .standard-hover-box;
      background-color: transparentize($navy, .3);

      @include breakpoint($desktop) {
        background-color: transparentize($navy, .1);
      }
      &.opt11 {
        .onl, .clsr {
          width: 50%;
        }
      }
      .detail-holder {
        position: absolute;
        left: 50%;
        top: 20%;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        @include breakpoint($desktop-large) {
          top: 32%;
        }
        .btn {
          display: block;
        }
        .ratings-sprite-holder {
          margin: 12px auto 3px;
        }
      }
      .onl, .clsr {
        height: 100%;
        width: 100%;
        float: left;
        text-align: center;
        position: relative;
        span {
          font-size: 18px;
          color: white;
          margin-bottom: 5px;
        }
      }
      .onl {
        position: relative;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 95px;
          background: $blue-dark;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          @include breakpoint($desktop-large) {
            height: 130px;
          }
        }
      }
    }
    // Overflow ima svrhu samo uz free-course-badge
    overflow: hidden;
    .free-course-badge {
      background-color: #F24295;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      width: 160px;
      top: 30px;
      right: -40px;
      z-index: 10;
      h4 {
        margin: 0;
        font-family: $font-family-goth;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: white;
      }
    }
  }
  .hover {
    opacity: 1;
    @include breakpoint(1025px) {
      opacity: 0;
    }

    // Ugasen hover efekat
    display: none;
    //&:not(.frontend-developer, .web-dizajn) {
    //  display: none;
    //}
    // Ugasen hover efekat END

  }
  @include breakpoint(1025px) {
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }
  .text {
    font-size: 14px;
    color: $gray-dark;
  }
  &.large-box {
    .holder {
      .hover {
        .detail-holder {
          top: 20%;
          @include breakpoint($mobile) {
            top: 29%;
          }
          @include breakpoint(370px 543px) {
            top: 40%;
          }
          @include breakpoint($tablet) {
            top: 33%;
          }
          @include breakpoint($desktop-large) {
            top: 36%;
          }
        }
      }
    }
    footer {
      .resets {
        line-height: 48px;
        margin: 0;
      }
    }
  }
  footer {
    .border {
      border-right: 1px solid $gray-light;
    }
    .footer-price {
      line-height: 37px;
      margin-bottom: 0;
      font-size: 22px;
      color: $gray-darker;
      a {
        color: $gray-darker;
      }
    }
  }
}
.old-price {
  position: relative;
  color: $gray !important;
  font-size: 16px !important;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $gray;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}
.new-price {
  &.red {
    color: #F25262;
  }
}

.course-archive:not(.large-box) {
  .text {
    height: 60px;
    overflow-y: hidden;
    margin-bottom: 0;
  }
}

.course-box, .course-archive {
  .title {
    font-size: 20px;
    margin-bottom: 5px;
    @include breakpoint($tablet 1199px) {
      min-height: 44px;
    }
    a {
      color: $gray-darker;
      &:hover {
        color: black;
      }
    }
  }
  .escape {
    .holder {
      a {
        display: block;
        img {
          transition: all .3s;
        }
        &:hover {
          img {
            filter: grayscale(.7);
          }
        }
      }
    }
  }
}

.courses-page {
  // Entry
  .course-entry {
    // Entry global
    .badge-circle {
      width: 100px;
      height: 100px;
      text-align: center;
      display: inline-block;
      @include border-radius(50%);
      line-height: 100px;
      margin-bottom: 15px;

    }
    .blue {
      background-color: $blue;
    }
    .red:not(.new-price) {
      background-color: $red;
    }
    // Entry global end
    header {
      background-color: $gray-lighter;
      .container {
        @include breakpoint($tablet 1199px) {
          padding-bottom: 15px;
        }
        @include breakpoint($desktop-large) {
          padding-bottom: 30px;
        }
        .course-nav {
          margin-top: -30px;
          margin-bottom: 30px;
          text-align: center;
          min-height: 30px;
          position: relative;
          @include breakpoint($mobile) {
            text-align: left;
          }
          @include breakpoint($desktop) {
            margin-top: -40px;
          }
          a {
            width: 180px;
            height: 30px;
            display: inline-block;
            text-align: center;
            font-family: $font-family-cond;
            text-transform: uppercase;
            font-size: 16px;
            color: white;
            @include border-top-radius(5px);
            line-height: 30px;
            @include breakpoint($desktop) {
              width: 250px;
              height: 40px;
              line-height: 40px;
            }
            &.active {
              height: 40px;
              color: $gray-dark;
              line-height: 40px;
              margin-top: -10px;
              display: none;
              @include breakpoint($mobile) {
                display: inline-block;
              }
              @include breakpoint($desktop) {
                height: 50px;
                line-height: 50px;
              }
            }
          }
        }
      }
      .badge {
        text-align: center;
        @include breakpoint($tablet) {
          text-align: left;
        }
        [class^="ksi-"] {
          font-size: 40px;
          color: white;
        }
        .badge-info {
          color: $gray-dark;
          font-family: $font-family-cond;
          text-align: center;
        }
      }
      .title {
        text-align: left;
        p {
          font-size: 14px;
        }
        h1 {
          margin: 0 0 5px;
          float: left;
        }
        .enrollmentAlert {
          float: left;
          background-color: #CC2746;
          border-radius: 4px;
          height: 26px;
          line-height: 26px;
          padding-left: 7px;
          padding-right: 7px;
          margin-left: 20px;
          margin-top: 11px;
          @include breakpoint(0 1199px) {
            width: 280px;
            clear: both;
            margin: 5px 0 20px;
          }
          img {
            width: 14px;
            position: relative;
            top: -2px;
          }
          span {
            margin-left: 3px;
            color: white;
            font-family: $font-family-cond;
          }
        }
        .lead {
          color: $gray-dark;
          margin-bottom: 20px;
        }
      }
      div.info {
        //stari kod kada info nije postojao na malom rezolucijama
        //text-align: center;
        //margin-bottom: 0;
        //display: none;
        //@include breakpoint($tablet) {
        //    text-align: left;
        //    display: block;
        //}
        @include breakpoint($tablet) {
          text-align: left;
        }
        ul {
          @extend .list-inline;
          @extend .list-unstyled;
          font-family: $font-family-cond;
          margin-bottom: 0;
          li {
            border-right: 1px solid #e6e6e6;
            padding-right: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
            @include breakpoint($tablet) {
              margin-right: 5px;
              padding-right: 5px;
            }
            @include breakpoint($desktop) {
              padding-right: 5px;
              margin-right: 0;
            }
            @include breakpoint($desktop-large) {
              padding-right: 10px;
              margin-right: 10px;
            }
            &:last-child {
              border-right: none;
              margin-right: 0;
            }
            .info-title {
              font-size: 16px;
              margin-top: 5px;
              margin-bottom: 6px;
              @include breakpoint($tablet 1199px) {
                font-size: 14px;
              }
            }
            .info-subtitle {
              font-size: 20px;
              color: $orange-light;
              line-height: 1.25;
              @include breakpoint($tablet 990px) {
                font-size: 14px;
              }
              @include breakpoint($desktop 1199px) {
                font-size: 16px;
              }
            }
            .info-desc {
              color: $gray-darker;
            }
          }
        }
        @include breakpoint(0 767px) {
          ul {
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            li {
              padding: 10px;
              display: block;
              border: none;
              margin: 0;
              .info-subtitle {
                font-size: 16px;
              }
            }
          }
        }
        @include breakpoint(0 399px){
          ul {
            li {
              width: 50%;
            }
          }
        }
        @include breakpoint(400px 767px){
          ul {
            li {
              width: 33.3%;
            }
          }
        }
      }
      .enrollment-info {
        @extend .clearfix;
        //@include breakpoint(0 543px) {
        //  text-align: center;
        //}
        //@include breakpoint($mobile 767px) {
        //  display: table;
        //  margin: auto;
        //  width: calc(210px + 130px);
        //  position: relative;
        //}
        @include breakpoint($tablet 991px) {
          padding-top: 8px;
        }
        .price-box {
          font-family: $font-family-cond;
          //@include breakpoint($mobile) {
          //  text-align: right;
          //}
          //@include breakpoint($mobile 767px) {
          //  position: absolute;
          //  left: 0;
          //  top: 50%;
          //  -ms-transform: translateY(-50%);
          //  -webkit-transform: translateY(-50%);
          //  transform: translateY(-50%);
          //}
          @include breakpoint($tablet 1199px) {
            float: left;
          }
          .price {
            text-transform: uppercase;
            font-size: 22px;
            color: $gray-darker;
            line-height: 1;
            margin-bottom: 0;
            &[content="0"] {
              position: relative;
              top: 1px;
            }
            @include breakpoint($tablet 991px) {
              &[content="0"] {
                font-size: 18px;
                line-height: 40px;
              }
            }
            @include breakpoint($desktop 1199px) {
              &[content="0"] {
                line-height: 50px;
              }
            }
          }

          .info {
            margin: 0;
            @include breakpoint($tablet 991px) {
              font-size: 12px;
            }
          }
          &.no-group {
            .info {
              @include breakpoint($tablet 991px) {
                font-size: 13px;
                padding-top: 3px;
              }
            }
          }
        }
        a {
          //@include breakpoint($mobile 767px) {
          //  float: right;
          //}
          @include breakpoint($tablet 991px) {
            min-width: 110px;
            height: 40px;
            padding: 9px 10px;
            font-size: 16px;
          }
          @include breakpoint($desktop 1199px) {
            min-width: 160px;
          }
          @include breakpoint($tablet 1199px) {
            float: right;
          }
          @include breakpoint($desktop-large) {
            float: right;
            height: 60px;
            min-width: 220px;
            padding: 17px 37px;
          }
        }

        /* NEW CSS START */
        @include breakpoint(0 767px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .rate-course {
          margin: auto auto auto 0;
          @include breakpoint($tablet) {
            display: none;
          }
          .ratings-sprite-holder {
            margin: 0 0 5px;
          }
        }
        .ei-bp-holder {
          margin: auto 0 auto auto;
          @include breakpoint(0 543px) {
            .btn {
              min-width: 150px;
              height: 45px;
              padding: 10px 20px;
            }
          }
        }
        @include breakpoint(0 767px) {
          .ei-bp-holder {
            display: flex;
            align-items: center;
            .btn {
              order: 2;
              margin: 0 !important;
            }
            .price-box {
              order: 1;
              margin-right: 20px;
              text-align: right;
            }
          }
        }
        /* NEW CSS END */
      }
    }
/* Mobile sticky bottom start */
    @include breakpoint(0 767px) {
      .mobile-fixed {
        position: fixed;
        padding: 30px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: white;
        z-index: 50;
        -webkit-box-shadow: 0 1px 5px 0 rgba(219, 219, 219, 0.75);
        -moz-box-shadow: 0 1px 5px 0 rgba(219, 219, 219, 0.75);
        box-shadow: 0 1px 5px 0 rgba(219, 219, 219, 0.75);
      }
    }
    @include breakpoint(0 543px) {
      .mobile-fixed {
        padding: 20px;
      }
    }
/* Mobile sticky bottom end */
    #sticky:not(.stick) {
      @include breakpoint($desktop-large) {
        position: relative;
      }
      a {
        @include breakpoint(0 543px) {
          margin-bottom: 15px;
        }
        @include breakpoint(0 380px) {
          min-width: 80px;
          padding: 7px 10px;
          height: 32px;
          font-size: 14px;
        }
      }
      .price-box {
        @include breakpoint($desktop-large) {
          padding: 0;
          position: absolute;
          left: 30px;
          top: 50%;
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        &.no-group {
          @include breakpoint($desktop-large) {
            left: 30px;
          }
        }
      }
    }

    .content {
      text-align: center;
      .container {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .details, .program, .benefits {
        ul {
          @extend .list-unstyled;
          margin-bottom: 0;
        }
      }

      .bordered {
        border-bottom: 1px solid $gray-light;
        margin-top: 0;
        padding-bottom: 10px;
      }

      .details {
        margin-bottom: 40px;
        .border {
          border-bottom: 1px solid $gray-light;
          margin-bottom: 20px;
          padding-bottom: 20px;
        }
        li {
          margin-bottom: 5px;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .one-column {
          li {
            &:last-child {
              margin-top: 20px;
            }
          }
        }
      }

      .benefits {
        .cke-editable {
          margin-bottom: 20px;
        }
        ul {
          margin-bottom: 20px;
        }
      }

      .program {
        ul {
          li {
            @extend .clearfix;
            padding: 10px 0;
            border-bottom: 1px solid $gray-light;
            color: $gray-dark;
            line-height: 25px;
            &:last-child {
              border-bottom: none;
              margin-bottom: 20px;
            }
            @include breakpoint($mobile) {
              font-size: 14px;
            }
            .title {
              margin: 0;
              margin-right: 10px;
              padding: 0;
              color: $navy;
              border-bottom: none;
              font-weight: normal;
              font-size: 16px;
              text-transform: none;
              float: left;
              line-height: 25px;

            }
            .total-time {
              display: none;
              @include breakpoint($mobile) {
                display: block;
                float: right;
              }
            }
            a.btn {
              display: none;
              @include breakpoint($mobile) {
                display: inline-block;
                float: right;
                margin-right: 10px;
              }
            }
          }
        }
        .col-sm-3 {
          @include breakpoint($tablet) {
            &:nth-child(4n+1) {
              clear: both;
            }
          }
        }
        .course-archive {
          margin-bottom: 30px;
          .title {
            margin-bottom: 5px;
            font-size: 16px;
          }
          .text {
            font-size: 12px;
            margin-bottom: 0;
          }
        }
        .about {
          margin-bottom: 30px;
          .cke-editable {
            margin-bottom: 30px;
          }
        }
      }
      .section-info {
        @extend .block-white;
        padding: 0;
        .info {
          margin-bottom: 30px;
        }
      }
    }
    .wishlist {
      display: inline-block;
      margin-left: 5px;
      position: relative;
      top: 5px;
      &.case-three {
        &:hover {
          border-color: #ccc;
          transition: all .3s;
          svg {
            path {
              fill: #ccc;
              transition: all .3s;
            }
          }
        }
      }
    }
  }
  .course-box, .course-archive {
    .escape {
      .holder {
        a {
          img {
            @include breakpoint($desktop) {
              height: 196px;
            }

          }
        }
      }
    }
  }
}


.section-instructor {
  @extend .block-white;
  padding: 0;
  article {
    text-align: center;
    > a {
      &:hover {
        opacity: .8;
      }
    }
    .user-avatar {
      @include avatar-img (115px);
      margin: 0 auto 10px;
      [class^='ksi-'] {
        font-size: 80px;
        @include breakpoint($tablet) {

        }
      }
      @include breakpoint($mobile) {
        @include avatar-img (165px);
      }
    }
    .title {
      font-size: 16px;
      font-family: $font-family-base;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .sub-title {
      font-size: 12px;
      font-family: $font-family-base;
      margin-top: 0;
    }
  }
  .instructor-single {
    display: inline-block;
    width: 49%;
    margin-bottom: 30px;
    @include breakpoint($tablet) {
      width: 33%;
    }
    @include breakpoint($desktop) {
      width: 20%;
    }
  }
}

.student-impression-new {
  text-align: left;
  .user-avatar {
    width: 75px;
    height: 75px;
    @include breakpoint(0 767px) {
      margin: 0 auto 15px;
    }
    .student-icons.w100 {
      position: relative;
      width: 75px;
      height: 75px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
      }
    }
  }
  article {
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
  }
  .name {
    margin-top: 0;
    text-transform: none;
    font-size: 16px;
    color: #333;
    @include breakpoint(0 767px) {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  q {
    display: inline-block;
    margin-bottom: 15px;
    min-height: 30px;
    &:before, &:after {
      content: "";
    }
  }
  .impressionArticle {
    display: none;
  }
}


.section-video {
  font-family: $font-family-cond;
  .popup-video {
    position: relative;
    display: block;
  }
}

.block-suggestions {
  background-color: white;
  text-align: left;
}

// Course nav background color

.online-page {
  .course-nav {
    a {
      background-color: $red-lighter;
    }
  }
  .btn-red-lighter {
    @extend .btn-blue;
    &[disabled] {
      @extend %disable-link;
    }
  }
  .course-archive {
    .holder {
      cursor: pointer;
      @include breakpoint($desktop) {
        .hover-holder-element {
          opacity: 0;
          transition: all .3s;
        }
        &:hover {
          .hover-holder-element {
            opacity: 1;
            transition: all .3s;
          }
        }
      }
    }
  }
  .hover-holder-element {
    background-color: transparentize($navy, .3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .content-holder {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: left;
      width: 185px;
      a {
        display: block;
        margin-bottom: 9px;
        h3 {
          color: white;
          font-family: $font-family-goth;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 1.1px;
          margin: 0;
          transition: all .3s;
        }
      }
      svg {
        width: 22px;
        height: 22px;
        position: relative;
        top: 6px;
        margin-right: 10px;
        path {
          fill: white;
          transition: all .3s;
        }
      }
      a:hover {
        h3 {
          color: $blue;
          transition: all .3s;
          svg {
            path {
              fill: $blue;
              transition: all .3s;
            }
          }
        }
      }
    }
  }
  .cover {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparentize(black, .3);
      top: 0;
      left: 0;
    }
  }
}

.classroom-page {
  .course-nav {
    a {
      background-color: $blue;
    }
  }
  .hover-holder-element {
    display: none;
  }
}

.courses-page {

  .course-nav {
    a {
      &.active {
        background-color: $gray-lighter;
      }
    }
  }
  .enrollment-info {
    a {
      color: white;
    }
  }

  .course-archive {
    box-shadow: 1px 1px 6px #ccc;
  }

}

.block-scroll {
  min-height: 94px;
  .hidden-xs {
    p {
      font-family: $font-family-cond;
    }
  }
}

#sticky {
  &.stick {
    position: fixed;
    top: 0;
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    right: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
    -webkit-box-shadow: 0 1px 5px 0 rgba(219, 219, 219, 0.75);
    -moz-box-shadow: 0 1px 5px 0 rgba(219, 219, 219, 0.75);
    box-shadow: 0 1px 5px 0 rgba(219, 219, 219, 0.75);
    .container {
      padding-top: 0;
      padding-bottom: 0;
      @extend .clearfix;
    }
    .info {
      float: left;
    }
    .enrollment-info {
      float: right;
      margin-top: 6px;
      position: relative;
      width: 100%;
      .btn {
        height: 47px;
        font-size: 16px;
        padding: 11px 35px;
        min-width: 40px;
      }
      .price-box {
        padding-top: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: 0;
        right: 160px;
        .price {
          font-size: 18px;
        }
      }
    }
  }
}

#sticky-anchor {
  height: auto !important;
}

// Temp css
.course-likes {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #F0E8E8;
  text-align: center;
  line-height: 30px;
  @include border-radius(50%);
  color: $red-light;
  &:hover {
    color: $red-light;
  }
}

.section-groups-available {
  margin-bottom: 20px;
  .calendar-img-holder {
    @include avatar-img(100px);
    background-color: $yellow;
    font-size: 50px;
    color: $gray-lighter;
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      display: table;
      margin: 0 auto 20px;
    }
  }
  .groups-available {
    .available-single {
      @include breakpoint(0 767px) {
        &:first-of-type {
          padding-bottom: 19px;
          margin-bottom: 20px;
          border-bottom: 1px solid $gray-light;
        }
      }
      @include breakpoint($tablet) {
        width: 49%;
        margin: auto;
        display: inline-block;
        vertical-align: top;
      }
    }
    .info {
      .title {
        margin-top: 0;
        margin-bottom: 20px;
      }
      p {
        color: $gray-dark;
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }
    .desc {
      margin-bottom: 40px;
    }
  }
}

// Redizajn entry-ja

#entry-info {
  text-align: left;
  .modal-dialog {
    @include breakpoint(0 1199px) {
      width: 90%;
      margin: 50px auto;
    }
    @include breakpoint($desktop-large) {
      width: 980px;
    }
  }
  .modal-content {
    padding: 20px;
    @include breakpoint($desktop) {
      padding: 50px;
    }
    #info-tabs {
      @extend .list-unstyled;
      li {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          text-align: center;
          display: block;
          font-family: $font-family-cond;
          font-weight: bold;
          svg {
            display: block;
            margin: 0 auto 10px;
            path {
              opacity: .3;
              transition: all .4s;
            }
          }
          color: $aqua;
        }
        &:hover {
          a {
            svg {
              path {
                opacity: 1;
              }
            }
            color: $aqua-dark;
            transition: all .4s;
          }
        }
        &.active {
          a {
            svg {
              path {
                opacity: 1;
              }
            }
            color: $aqua-dark;
          }
        }
      }
      @include breakpoint(0 990px) {
        @include clearfix;
        li {
          float: left;
          width: 33.3%;
          margin-bottom: 0;
          a {
            svg {
              width: 90px;
              height: auto;
            }
          }
        }
      }
      @include breakpoint(0 543px) {
        li {
          a {
            svg {
              width: 60px;
            }
          }
        }
      }
    }
    .info-content {
      padding-top: 5px;
      h2,h3,h4,h5 {
        margin-top: 20px;
        margin-bottom: 5px;
      }
      a {
        &:last-child {
          margin-top: 35px;
        }
      }
    }
  }
}

.block-info {
  @extend .block-white;
  @include breakpoint(0 767px) {
    padding: 0;
  }
  @include breakpoint($desktop) {
    padding: 40px 0 60px;
  }
  .desc {
    @include breakpoint($desktop) {
      width: 80%;
      margin: 0 auto 30px;
    }
    >h2, >p {
      text-align: left;
      @include breakpoint($desktop) {
        text-align: center;
      }
    }
  }
  .info-links {
    a {
      padding: 15px 5px 20px;
      @include breakpoint($desktop-large) {
        padding: 25px 15px 20px;
      }
      display: block;
      &:hover {
        background: transparentize($gray-light, .5);
      }
    }
    svg {
      height: 124px;
      margin-bottom: 5px;
    }
    p {
      color: $gray-darker;
    }
    .moreLink {
      text-transform: uppercase;
      color: $blue;
      font-size: 15px;
      font-family: $font-family-cond;
    }
    > div {
      margin-bottom: 50px;
      @include breakpoint($desktop) {
        margin-bottom: 0;
      }
    }
  }
}

.escape {
  position: relative;
  .action-price {
    position: absolute;
    right: -8px;
    top: 50px;
    z-index: 10;
    p {
      background-color: #FFCC00;
      color: $gray-darker;
      font-weight: bold;
      font-family: $font-family-cond;
      display: table;
      margin: auto 0 2px auto;
      text-transform: uppercase;
      padding: 2px 5px;
    }
    &.left {
      right: auto;
      left: -8px;
      p {
        margin: auto auto 2px 0;
      }
    }
  }
}

.block-dates {
  margin-bottom: 40px;
  .desc {
    @include breakpoint($desktop) {
      width: 80%;
      margin: auto;
    }
  }
  &.course-reset {
    margin-bottom: 0;
    > .container.reset-pt {
      padding-bottom: 0;
    }
    & + .block-courses {
      padding-top: 40px;
      @include breakpoint(0 990px) {
        padding-bottom: 70px;
      }
    }
  }
}

.block-courses {
  > .container {
    @include breakpoint($desktop) {
      padding-bottom: 50px !important;
    }
  }
  @include breakpoint(0 991px) {
    padding-bottom: 60px;
  }
  @extend .block-white;
  .about {
    margin-bottom: 60px;
  }
  .program-days {
    article {
      @extend .clearfix;
      padding: 10px;
      &:nth-child(even) {
        background-color: $gray-lighter;
      }
    > *:not [ a ] {
      line-height: 25px;
    }
      .courseProgramInfo {
        @extend .clearfix;
        margin-bottom: 10px;
      }
      i {
        float: left;
        color: $blue-light;
        font-size: 16px;
        margin-right: 10px;
        display: none;
        margin-top: 2px;
        @include breakpoint($tablet) {
          display: block;
        }
      }
      .title {
        float: left;
        font-size: 14px;
        margin-bottom: 0;
        text-align: left;
        font-weight: bold;
        @extend .clearfix;
        @include breakpoint(0 543px) {
          width: 100%;
        }
        @include breakpoint($mobile 767px) {
          text-overflow: ellipsis;
          width: 64%;
          white-space: nowrap;
          overflow: hidden;
          height: 28px;
        }
        @include breakpoint($tablet) {
          font-size: 16px;
        }
        .preview-btn {
          .f-word {
            display: none;
          }
          @include breakpoint(0 543px) {
            float: right;
          }
          @include breakpoint($desktop) {
            margin-left: 20px;
            .f-word {
              display: inline-block;
            }
          }
        }
      }
      .num-lection {
        float: right;
        font-size: 12px;
        margin: 0;
        padding-right: 10px;
        @include breakpoint($tablet) {
          font-size: 16px;
        }
        &:nth-child(4) {
          border-right: 1px solid $gray;
        }
        @include breakpoint(0 543px) {
          display: none;
        }
      }
      .time {
        float: right;
        text-align: right;
        font-size: 14px;
        padding-left: 10px;
        min-width: 95px;
        margin: 0;
        font-family: $font-family-cond;
        display: none;
        @include breakpoint($mobile) {
          display: block;
        }
        @include breakpoint($tablet) {
          font-size: 20px;
        }
      }
      > a {
        float: left;
        margin-top: 10px;
        margin-right: 5px;
        @include breakpoint($tablet) {
          float: right;
          margin-top: 0;
        }
      }
      .courseProgram {
        @extend .list-unstyled;
        padding-left: 10px;
        text-align: left;
        li {
          clear: both;
          i {
            font-size: 14px;
          }
        }
      }
    }
    &.pkpd {
      text-align: left;
      @include breakpoint($desktop-large) {
        width: 70%;
        margin: 0 auto;
      }
      .pcourses {
        padding: 0;
        padding-left: 30px;
        margin-left: 20px;
        border-left: 2px solid $yellow;
        @include breakpoint($desktop) {
          padding-left: 50px;
          margin-left: 25px;
        }
        &.last {
          border-color: transparent;
          .number {
            line-height: 28px;
            background-color: $blue;
            @include breakpoint($desktop) {
              line-height: 44px;
            }

          }
          .courseProgramInfo {
            margin-bottom: 5px;
          }
        }
        &:nth-child(even) {
          background-color: $gray-lighter;
        }
        i {
          font-size: 14px;
        }
        strong {
          color: $green-new1;
          font-weight: normal;
          font-family: $font-family-cond;
          font-size: 16px;
          &:first-child {
            @include breakpoint($tablet) {
              margin-left: 34px;
            }
          }
        }
        ul {
          margin-bottom: 25px;
        }
      }
      ul {
        @extend .list-unstyled;
        padding-left: 10px;
        li {
          @extend .clearfix;
          line-height: 25px;
          font-size: 16px;
          font-family: $font-family-cond;
          a {
            display: inline-block;
            margin-left: 15px;
            text-transform: uppercase;
            color: $blue;
            font-size: 15px;
            font-family: $font-family-cond;
          }
        }
      }
      .courseProgramInfo {
        @extend .clearfix;
        margin-bottom: 10px;
        position: relative;
        .number {
          width: 30px;
          height: 30px;
          display: block;
          background: $yellow;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          position: absolute;
          left: -46px;
          top: -1px;
          font-size: 16px;
          color: white;
          @include breakpoint($desktop) {
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            left: -75px;
            top: -10px;
          }
        }
        .title {
          float: left;
          font-size: 20px;
          font-family: $font-family-cond;
          font-weight: normal;
        }
        .time {
          float: right;
        }
      }
    }
  }
}

.block-impressions {
  .desc {
    margin-bottom: 30px;
    @include breakpoint($desktop-large) {
      margin-bottom: 60px;
    }
  }
  .instructor-single {
    margin-bottom: 60px;
    @include breakpoint($desktop-large) {
      margin-bottom: 130px;
    }
    article {
      > a {
        display: table;
        width: 100px;
        margin: 0 auto 15px;
        &:hover {
          opacity: .8;
        }
      }
      > .user-avatar {
        width: 100px;
        display: table;
        margin: 0 auto 15px;
        i {
          font-size: 60px;
        }
      }
    }
    .title {
      margin-bottom: 15px;
    }
    .desc {
      margin-bottom: 20px;
      @include breakpoint($desktop) {
        width: 80%;
        margin: 0 auto 30px;
      }
    }
  }
}
.courses-page {
  &.online-page {
    .content .desc {
      h5 {
        background-color: $blue;
        padding: 10px;
        display: inline-block;
        margin-top: 20px;
        color: white;
        font-size: 22px;
      }
    }
  }
  &.classroom-page {
    .content .desc {
      h5 {
        background-color: $red-lighter;
        padding: 10px;
        display: inline-block;
        margin-top: 20px;
        color: white;
        font-size: 22px;
      }
    }
  }
}

.online-course-duration {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 20px;
  color: black;
  font-family:  $font-family-cond;
}