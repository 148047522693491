.browse-group {
  line-height: 43px;
  >* {
    float: left;
  }
  >p {
    margin: 0;
    font-size: 18px;
    color: $gray-dark;
    font-family: $font-family-cond;
    margin-right: 15px;
  }
  .fake-path {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}