// Header active-hover class

@mixin header-active-hover() {
  &:before {
    width: 0;
  }
  @include breakpoint($desktop) {
    position: relative;
    &:before {
      content: " ";
      display: inline-block;
      height: 6px;
      width: 0;
      margin-left: 0;
      background-color: $green-light;
      border-radius: 3px;
      position: absolute;
      top: -6px;
      left: 50%;
      transition: all .1s;
    }
    &:hover, &:focus {
      text-decoration: none;
      &:before {
        width: 20px;
        margin-left: -10px;
      }
    }
  }
}

// Header active-hover class

@mixin rm-button() {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 991;
  width: 50px;
  padding-left: 0;
  padding-right: 0;
}

// Triangle for dd menu header
%dd-menu-arr-up {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 25px;
  width: 1px;
  height: 1px;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
}

// Triangle for dd menu header end

.main-header {
  height: $header-height-mobile;
  position: absolute;
  z-index: 51;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all .2s;
  @include box-shadow (0px 1px 5px 0px rgba(102, 102, 102, 0.50));
  .logo {
    width: 210px;
    margin: 5px 0 0;
    float: left;
    transition: all .2s;
    @include breakpoint($mobile) {
      width: 240px;
      margin: 0 auto 10px 20px;
    }
    > a {
      display: block;
      width: 100%;
      height: 45px;
      background: url("../images/svg/krojaceva-skola-logo.svg") no-repeat;
      background-size: contain;
      text-indent: -9999px;
      @include breakpoint($mobile) {
        height: 50px;
      }
    }
  }
  .header-right {
    @include clearfix;
    float: right;
    transition: all .2s;

    font-family: $font-family-cond;
    color: $gray;
    text-transform: uppercase;
    @media (min-width: 1300px) {
      font-size: 16px;
    }

    .rm-button {
      @include rm-button;
      @include reset-shadow;
    }

    .menu {
      display: none;
      position: fixed;
      z-index: 990;
      top: 0;
      left: -100%;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .7);

      @include breakpoint($desktop) {
        display: block;
      }

      &.open {
        display: block;
        left: 0;
      }

      nav {
        position: absolute;
        top: $header-height-mobile;
        width: 100%;
        left: 0;
        ol {
          background-color: white;
          @include list-unstyled;
          @include clearfix;

          > li {
            @media (max-width: 991px) {
              &.active {
                background-color: $green-light;
                > a {
                  color: white;
                }
              }
            }
            > a {
              display: block;
              line-height: 50px;
              height: 50px;
              padding: 0 30px;
              color: $gray-dark;
            }
          }
        }
      }

      .search-btn {
        @include rm-button;
        padding-top: 12px;
        position: absolute;
        top: 10px;
        left: 15px;
        @include breakpoint($desktop) {
          padding-top: 4px;
        }
      }

      .search-form {
        display: none;
      }
      .header-user {
        display: block;

        @include breakpoint(0 991px) {
          > a.log-in-link {
            line-height: 50px;
            padding: 0 30px;
            border-radius: 3px;
            background-color: white;
            position: fixed;
            z-index: 996;
            top: 10px;
            right: 75px;
          }
        }
        @include breakpoint($desktop) {
          > a.log-in-link {
            @include header-active-hover;
            color: $gray-darker;
            line-height: 36px;
            margin-top: 7px;
            margin-right: 15px;
            display: inline-block;
          }
        }

        .user-profile {
          position: fixed;
          z-index: 995;
          top: 10px;
          right: 75px;
          float: left;
          .user-menu-link {
            @include breakpoint(0 991px) {
              > i {
                display: none;
              }
            }
            &.open {
              @include breakpoint($desktop) {
                .user-menu {
                  transition: padding-top .3s;
                  padding-top: 40px;
                  right: -10px;
                  &:before {
                    transition: top .3s;
                    top: 20px;
                  }
                }
              }
            }
          }
          //li {
          //  a {
          //    > i {
          //      float: left;
          //      margin-right: 20px;
          //    }
          //  }
          //}
          .user-avatar-holder {

            float: left;
          }
          .user-avatar {
            [class^='ksi-'] {
              line-height: 50px;
              display: block;
              font-size: 32px;
              color: $orange;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: white;
              border: 2px solid $gray-light;
              @include breakpoint($desktop) {
                line-height: 40px;
                font-size: 26px;
                width: 40px;
                height: 40px;
              }
              @include breakpoint($desktop-large) {
                line-height: 50px;
                font-size: 32px;
                width: 50px;
                height: 50px;
              }
            }
            > img {
              border-radius: 50%;
              width: 50px;
              height: 50px;
              line-height: 50px;
            }
          }
          .user-name {
            display: none;
            a {
              color: $gray-darker;
            }
          }
          .user-menu {
            li {
              > a {
                font-size: 14px;
                display: block;
                line-height: 50px;
                height: 50px;
                padding: 0 20px;
                border-bottom: 1px solid #f1f1f1;
                color: $gray-darker;
                font-weight: normal;
                &:last-child {
                  border-bottom: 0;
                }
              }
              &.active {
                > a {
                  color: white;
                  background-color: $orange;
                }
              }
            }
            .second {
              background-color: $gray-lighter;
              .last-view {
                a {
                  color: #44B4D5
                }
              }
              .help {
                a {
                  color: #308993;
                }
              }
              .log-out {
                a {
                  color: #F0522E;
                }
              }
            }
            .um-list {
              &:not(.second) {
                background-color: white;
                //-webkit-clip-path: inset(0 0 0px 0);
                //clip-path: inset(0 0 0px 0);
              }
              .admin {
                border-bottom: 1px solid $gray-lighter;
                a {
                  font-family: $font-family-goth;
                  color: $gray-darker;
                }
              }
              .typing {
                display: none;
                a {
                  &:after {
                    content: "Novo";
                    position: relative;
                    font-size: 10px;
                    color: $green-new1;
                    left: 5px;
                    top: -6px;
                  }
                  &:hover {
                    &:after {
                      color: $gray-darker;
                    }
                  }
                }
                @include breakpoint($desktop-large) {
                  display: block;
                }
              }
              li {
                a {
                  &:hover, &:focus {
                    color: white;
                    background-color: $orange-lighter;
                  }
                }
              }
            }
          }
          @include breakpoint(0 991px) {
            .user-menu {
              display: none;
              background-color: white;
              @include list-unstyled;
              @include clearfix;
              padding: 10px 0;
              &.open {
                display: block;
                position: fixed;
                top: $header-height-mobile;
                left: 0;
                right: 0;
                padding-top: 0;
              }

            }
          }
          .user-menu {
            .um-list {
              padding-left: 0;
              list-style: none;
              margin-bottom: 0;
            }
          }
          @include breakpoint($desktop) {
            float: left;
            top: 0;
            right: 0;
            position: relative;
            .user-avatar-holder {
              margin-right: 10px;
            }
            .user-menu {
              position: absolute;
              padding-top: 20px;
              right: 9999px;
              clear: both;
              width: 250px;
              transition: padding-top .3s;
              text-align: left;
              &:before {
                content: " ";
                display: block;
                position: absolute;
                top: 0;
                right: 25px;
                width: 1px;
                height: 1px;
                border: 10px solid transparent;
                border-bottom: 10px solid white;
              }
              &:hover {
                padding-top: 80px;
              }
              .um-list {
                @include breakpoint($desktop) {
                  @include box-shadow(0px 1px 2px transparentize(#595959, 0.35%));
                }
              }
            }
            .user-menu-link {
              line-height: 36px;
              border-left: 1px solid #ccc;
              text-align: center;
              display: block;
              width: 36px;
              height: 36px;
              float: left;
              font-size: 23px;
              margin: 7px 7px 0 13px;
              cursor: pointer;
            }
          }
          @include breakpoint($desktop-large) {
            .user-name {
              display: block;
              float: left;
              line-height: 50px;
            }
          }
        }

      }

      &.open-search {
        .search-form {
          display: block;
          position: absolute;
          top: $header-height-mobile;
          height: 50px;
          width: 100%;
          padding-right: 10px;
          padding-left: 10px;
          input {
            width: 100%;
            float: left;
            padding-left: 15px;
            padding-right: 80px;
            z-index: 99999;
            color: $gray-dark;
            @include placeholder($gray);
            &:focus {
              border-color: white;
            }
          }
          .close {
            background-color: $red-lighter;
            @include border-radius(3px);
            text-align: center;
            padding-top: 2px;
            cursor: pointer;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 30px;
            left: auto;
            i {
              color: white;
              font-size: 24px;
            }
          }
        }
        nav, .dropmenu.dropmenu {
          top: $header-height-mobile + 60px;
        }
      }
    }
  }

  &.header-shrink {
    .header-right {
      .menu.open-search {
        .search-form {
          top: 62px;
        }
      }
    }
  }
  // Desktop
  @include breakpoint($desktop) {
    height: $header-height;
    padding: 16px 0;

    .header-right {
      .rm-button {
        display: none;
      }
      .menu {
        position: static;
        background-color: transparent;
        height: 50px;

        nav {
          float: left;
          position: static;
          padding: 7px 0;
          width: auto;
          > ol {
            margin: 0;
            padding: 0;
            > li {
              float: left;
              margin-right: 20px;
              > a {
                display: inline-block;
                padding: 0;
                line-height: 36px;
                height: 36px;
                position: relative;
                @include header-active-hover;
              }
              &.active {
                > a {
                  @include header-active-hover;
                  &:before {
                    width: 20px;
                    margin-left: -10px;
                  }
                }
              }
            }
          }
        }

        .search-btn {
          display: inline-block;
          position: static;
          float: left;
          width: 36px;
          height: 36px;
          margin-top: 7px;
          @include reset-shadow;
        }
        &.open-search {
          .search-form {
            height: auto;
            position: fixed;
            z-index: 400;
            top: $header-height;
            left: 0;
            padding: 50px;
            background-color: rgba(255, 255, 255, .9);
            input {
              background-color: transparent;
              float: none;
              border-color: transparent;
              font-size: 28px;
              margin-bottom: 0;
              padding-right: 50px;
              &:focus {
                border-color: transparent;
              }
            }
          }
        }
        .header-user {
          display: block;
          float: left;
          margin-left: 20px;
        }
      }
    }
  }
  @media (min-width: 1300px) {
    position: fixed;
    &.header-shrink {
      height: 65px;
      padding: 9px 0;
      .logo {
        width: 220px;
      }
    }
  }
}

.header-fix {
  position: fixed;
  height: 40px;
}

.overflow-y {
  overflow: hidden;
  .main-header {
    .header-right {
      .menu {
        nav {
           ol {
            > li {
              > a {
                @media (max-height: 670px) {
                  line-height: 40px;
                  height: 40px;
                }
                //@media (max-height: 550px) {
                //  line-height: 30px;
                //  height: 30px;
                //}
              }
            }
             .myCourses {
               a {
                 @media (min-height: 510px) and (max-height: 669px) {
                   line-height: 50px;
                   height: 50px;
                 }
               }
             }
          }
          > ol {
            > li {
              @media (max-height: 475px) {
                width: 50%;
                float: left;
                border-bottom: none;
              }
            }
            @media (max-height: 475px) {
              @include clearfix;
            }
          }
        }
      }
    }
  }
}

// dropdown main header menu
@include breakpoint(0 991px) {
  .main-header {
    .header-right {
      .menu {
        nav {
          .gallery-dd-item {
            > a {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($desktop) {
  .gallery-dd-item {
    position: relative;
    .gallery-dd-menu {
      position: absolute;
      padding-top: 40px;
      right: 9999px;
      clear: both;
      width: 250px;
      top: 30px;
      transition: padding-top .3s;
      ol {
        background-color: white;
        box-shadow: 0 1px 2px rgba(89, 89, 89, 0.65);
        &:before {
          content: " ";
          display: block;
          position: absolute;
          transition: top .3s;
          top: 22px;
          right: 25px;
          width: 1px;
          height: 1px;
          border: 10px solid transparent;
          border-bottom: 10px solid white;
        }
        li {
          &:hover {
            background-color: $green-lighter;
            a {
              color: white !important;
            }
          }
          &.active {
            background-color: $green-light;
            a {
              color: white !important;
            }
          }
        }
      }
    }
    &:before {
      content: " ";
      display: inline-block;
      height: 6px;
      width: 0;
      margin-left: 0;
      background-color: $green-light;
      border-radius: 3px;
      position: absolute;
      top: -6px;
      left: 50%;
      transition: all .1s;
    }
    &:hover {
      cursor: pointer;
      &:before {
        width: 20px;
        margin-left: -10px;
      }
      .gallery-dd-menu {
        transition: padding-top .3s;
        padding-top: 43px;
        right: -5px;
        ol {
          &:before {
            transition: top .3s;
            top: 23px;
          }
        }
      }
    }
  }
  .header-shrink {
    .gallery-dd-item {
      &:hover {
        .gallery-dd-menu {
          padding-top: 43px;
          ol {
            &:before {
              top: 23px;
            }
          }
        }
      }
    }
  }
  .gallery-dd-item {
    .gallery-dd-menu ol:before {
      right: 16px;
    }
  }
}

// dropdown main header menu

.user-points {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: 12px;
  float: left;
  @include breakpoint($desktop 1199px) {
    top: 14px;
    margin-left: 5px;
    margin-right: 0;
  }
  .up-first {
    font-size: 14px;
    background-color: #FABA71;
    display: inline-block;
    @include border-radius(5px);
    padding: 2.5px 12px 1.5px 7px;
    line-height: 1;
    color: white;
    font-weight: bold;
    @include breakpoint($desktop 1199px) {
      padding: 2.5px 6px 1.5px 7px;
    }
  }
  .up-second {
    position: absolute;
    right: -10px;
    top: -3px;
    @include breakpoint($desktop 1199px) {
      display: none;
    }
    svg {
      width: 19px;
      .cls-3 {
        fill: #ffc021;
      }
      .cls-1, .cls-2 {
        fill: #d43b26;
      }
    }
  }
}

//Media query from - to...

@media (min-width: 992px) and (max-width: 1380px) {
  .main-header .header-right .menu nav > ol > li {
    margin-right: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-header {
    .logo {
      width: 145px;
      margin: 10px 0 0;
      a {
        height: 40px;
      }
    }
    .header-right {
      .menu {
        .header-user {
          margin-left: 10px;
          .user-profile {
            .user-avatar {
              margin-top: 5px;
              > img {
                width: 40px;
                height: 40px;
                line-height: 40px;
              }
              .student-icons {
                &.w50 {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  img {
                    width: 25px;
                  }
                }
              }
            }
            .user-menu-link {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1101px) and (max-width: 1199px) {
  .main-header {
    .logo {
      width: 200px;
      margin: 5px 0 0;
      a {
        height: 40px;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .main-header {
    .logo {
      margin-left: 0;
      width: 190px;
      margin-top: 5px;
      a {
        height: 45px;
      }
    }
    .header-right {
      .menu {
        nav {
          > ol {
            > li {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 540px) {
  .main-header {
    .header-right {
      .menu {
        nav {
          .gallery-dd-menu {
            ol {
              > li {
                > a {
                  line-height: 40px;
                  height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-height: 450px) {
  .main-header {
    .header-right {
      .menu {
        nav {
          .gallery-dd-menu {
            ol {
              > li {
                > a {
                  line-height: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//@media (max-height: 430px) {
//  .main-header {
//    .header-right {
//      .menu {
//        nav {
//          > ol {
//            .gallery-dd-item {
//              float: right !important;
//            }
//          }
//        }
//      }
//    }
//  }
//}

@include breakpoint(0 991px) {
  .main-header {
    .header-right {
      .menu {
        nav {
          &.user-open {
            opacity: 0;
          }
          &.user-close {
            opacity: 1;
          }
        }
      }
    }
  }
}

.courses-offer {
  display: none;
  float: left;
  height: 50px;
  line-height: 50px;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #ccc;
  a {
    color: #373D3F;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline !important;
    }
  }
  &.front {
    @include breakpoint(1600px) {
      display: block;
    }
  }
}

#user-panel {
  .courses-offer {
    @include breakpoint($tablet) {
      display: block;
    }
  }
}

.main-header .header-right .menu nav ol {
  .myCourses {
    border-bottom: 1px solid $gray-lighter;
    @include breakpoint($desktop) {
      display: none;
    }
    a {
      font-weight: bold;
      color: #EA845B;
    }
    &.active {
      background-color: #EA845B;
    }
  }
  @media (max-height: 560px) {
    .impressClass, .galleVideo {
      display: none;
    }
  }
}