// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// mixins sa shadow stari dizajn
//@mixin button-variant($color, $background, $border, $shadows : true) {
//  color: $color;
//  background-color: $background;
//  border-color: $border;
//  @if $shadows != false {
//    box-shadow: 0 2px 0 0 darken($background, 8) ;
//  }
//  &:focus,
//  &.focus {
//    color: $color;
//    background-color: darken($background, 10%);
//    border-color: darken($border, 10%);
//    outline: none;
//  }
//  &:active {
//    @if $shadows != false {
//      box-shadow: 0 2px 0 0 darken($background, 17%) ;
//    }
//  }
//  &:hover {
//    color: $color;
//    background-color: darken($background, 10%);
//    border-color: darken($border, 10%);
//  }
//  &:active,
//  &.active,
//  .open > &.dropdown-toggle {
//    color: $color;
//    background: darken($background, 10%) none;
//    border-color: darken($border, 10%);
//
//    &:hover,
//    &:focus,
//    &.focus {
//      background-color: darken($background, 17%);
//      border-color: transparent;
//    }
//  }
//  &.disabled,
//  &[disabled],
//  fieldset[disabled] & {
//    &:hover,
//    &:focus,
//    &.focus {
//      background-color: $background;
//      border-color: $border;
//    }
//  }
//
//  .badge {
//    color: $background;
//    background-color: $color;
//  }
//}

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 10%);
    outline: none;
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 10%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background: darken($background, 10%) none;
    border-color: darken($border, 10%);

    &:hover,
    &:focus,
    &.focus {
      background-color: darken($background, 17%);
      border-color: transparent;
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }
  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
