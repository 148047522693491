// completed course poruka max visina slici ogranicena

// slider za utiske kod studenata
.student-page {
  .owl-carousel {
    margin: 0;

    .owl-item {
      @include breakpoint($tablet) {
        padding: 0 30px;
      }
    }

    .owl-controls .owl-buttons div {
      display: flex;
      align-items: center;
      position: absolute;
      top: calc(50% - 25px);
      height: 30px;
      width: 30px;
      visibility: hidden;
      @include breakpoint($tablet) {
        visibility: visible;
      }
      i {
        display: flex;
      }
    }

    .owl-prev {
      left: -30px;
      justify-content: center;
    }

    .owl-next {
      right: -30px;
    }
  }
}

// porukama na my-courses treba poseban top i margin
.my-courses {
  .close-element[class*="message-"] {
    top: -20px;
    margin-bottom: 20px;
  }
}

// Settings stil unutar e-ucionice (reset zakline itd)
#classroom {
  .active-settings {
    color: $gray;
    transform: rotate(90deg);
  }
  #classroom-settings {
    cursor: pointer;
    transition: transform 300ms;
    &:hover {
      color: $gray;
      transform: rotate(90deg);
      ~ #classroom-settings-menu {
        display: block;
      }
    }
  }

  .active-settings-menu {
    display: block !important;
  }
  #classroom-settings-menu {
    padding: 5px 0;
    text-align: right;
    position: absolute;
    right: 35px;
    margin-top: -8px;
    display: none;
    &:hover {
      display: block;
    }

    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 0;
      background-color: darken($body-bg, 3);
      box-shadow: 1px 1px 4px darken($body-bg, 10);
      li {
        cursor: pointer;
        padding: 5px 5px 5px 10px;
        width: 100%;
        &:hover {
          background-color: darken($body-bg, 8);
        }
      }
    }
  }
}

// Bojanu u skripti se nije video caption u tabeli (bio je beo)
#classroom {
  #lecture-script {
    table {
      caption {
        color: $gray-dark;
      }
    }
  }
}

// novi student box
.showcase-page {
  .students-box {
    .escape {
      margin-bottom: 20px;
    }
    .holder {
      display: flex;
      align-items: center;

      .user-avatar {
        float: left;
        position: relative;
        top: 0;
        left: 0;
        width: 75px;
        height: 75px;
        margin-right: 20px;
      }
    }
  }
}

// Helper class for fading out on hover
.fade-on-hover:hover {
  opacity: .8 !important;
}

#classroom {
  .quizzes-section {
    .quiz-question-title {
      pre {
        margin-top: 5px;
      }
    }
  }
}

// User activated pge
.user-activated {
  .user-activated-content {
    h2 {
      font-size: 50px;
    }
    h4 {
      font-size: 20px;
    }
    a.btn {
      margin-top: 35px;
    }
    padding-top: 150px;
    p {
      font-size: 16px;
    }
  }
  .main-container {
    @include breakpoint($desktop) {
      position: relative;
      &:before {
        width: 190px;
        height: 190px;
        background-color: #FEAC1C;
        @include border-radius(50%);
        position: absolute;
        right: 0;
        top: -96px;
        z-index: -1;
      }
    }
  }
}