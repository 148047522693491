/*************************************************
///////////HEADER DROPDOWN MENU START/////////////
*************************************************/
.user-notification {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 5px;
  @include breakpoint(1400px) {
    margin-left: 10px;
  }
  .icons-holder {
    position: relative;
    cursor: pointer;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    text-align: center;
    svg {
      width: 19px;
      position: relative;
      top: -5px;
      path {
        fill: #8EC5D8;
      }
    }
    .not-badge {
      display: none;
      width: 20px;
      height: 20px;
      font-size: 12px;
      color: white;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      background-color: #F35958;
      @include border-radius(50%);
      position: absolute;
      top: -5px;
      right: -5px;
      user-select: none;
    }
    &.new-notification {
      .not-badge {
        display: block;
      }
    }
  }
  &.open {
    .icons-holder {
      background: $gray-lighter;
    }
  }
  .dd-menu-holder {
    position: absolute;
    top: 24px;
    right: 9999px;
    padding-top: 30px;
    transition: padding-top .3s;
    .user-dd-menu {
      background-color: white;
      padding-bottom: 25px;
      width: 280px;
      @include box-shadow(0px 1px 2px transparentize(#595959, 0.35%));
      z-index: 110;
      @include breakpoint($tablet) {
        width: 320px;
      }
      &:after {
        @extend %dd-menu-arr-up;
        top: -20px;
        right: 40px;
        transition: top .3s;
      }
      > a.btn {
        width: calc(100% - 50px);
        margin: 0 auto;
      }
    }
    &.open {
      padding-top: 50px;
      transition: padding-top .3s;
      left: -190px;
      .user-dd-menu {
        //box-shadow: 0px 1px 2px rgba(89,89,89,.6);
        position: relative;
        &:after {
          top: -20px;
          transition: top .3s;
          right: 61px;
        }
      }
      @include breakpoint($tablet) {
        left: -230px;
      }
    }
  }
  //&:hover {
  //  .icons-holder {
  //    i {
  //      color: #bdbdbd;
  //    }
  //  }
  //  @include breakpoint($desktop) {
  //    .dd-menu-holder {
  //      padding-top: 50px;
  //      right: -32px;
  //      transition: padding-top .3s;
  //      .user-dd-menu {
  //        &:after {
  //          top: 30px;
  //          transition: top .3s;
  //        }
  //      }
  //    }
  //  }
  //}
}

.user-dd-menu {
  ul {
    @extend .list-unstyled;
    margin-bottom: 15px;
    li {
      > a {
        display: block;
        padding: 25px 25px 0 70px;
        > div {
          padding-bottom: 15px;
          border-bottom: 1px solid #e7e7e7;
        }
        &:hover {
          background-color: $gray-light;
          > div {
            border-color: transparent;
          }
        }
      }
      h3 {
        font-size: 16px;
        color: #000;
        margin-bottom: 7px;
        text-transform: none;
      }
      time {
        font-size: 12px;
        color: $gray;
        margin-bottom: 3px;
        display: block;
      }
      p {
        font-size: 14px;
        color: $gray-darker;
        text-transform: none;
        margin-bottom: 0;
      }
      &:last-child {
        > a {
          > div {
            border-bottom: none;
          }
        }
      }
      &.active {
        a {
          background-color: $gray-light;
        }
      }
    }
  }
  .not-success,
  .not-reminder,
  .not-promo,
  .not-info {
    position: relative;
    &:before {
      width: 37px;
      height: 37px;
      content: "";
      display: block;
      position: absolute;
      top: 25px;
      left: 25px;
    }
  }
  .not-success {
    &:before {
      background: url("../images/not-success.png") no-repeat;
    }
  }
  .not-reminder {
    &:before {
      background: url("../images/not-reminder.png") no-repeat;
    }
  }
  .not-promo {
    &:before {
      background: url("../images/not-promo.png") no-repeat;
    }
  }
  .not-info {
    &:before {
      background: url("../images/not-info.png") no-repeat;
    }
  }
}

//.last-three {
//  li {
//    display: none;
//    &:nth-last-child(-n+3) {
//      display: block;
//    }
//  }
//}

/*************************************************
////////////HEADER DROPDOWN MENU END//////////////
*************************************************/

/*************************************************
///////////////PAGES ALL NOT START////////////////
*************************************************/
//.notification-page {
//  @extend %user-header-title-style;
//  article {
//    > h3 {
//      a {
//        color: #333;
//      }
//    }
//    p {
//      margin-bottom: 0;
//    }
//    .holder {
//      margin-bottom: 30px;
//      font-family: $font-family-cond;
//      span {
//        font-weight: bold;
//        margin-right: 5px;
//      }
//      time {
//        color: #777;
//      }
//    }
//    &.notification-news {
//      padding-bottom: 34px;
//      border-bottom: 1px solid #ddd;
//      margin-bottom: 35px;
//      &:last-child {
//        border-color: transparent;
//      }
//    }
//  }
//  &.single {
//    article {
//      font-size: 16px;
//      &:not(:last-child) {
//        padding-bottom: 25px;
//        margin-bottom: 30px;
//      }
//    }
//  }
//  p {
//    margin: 10px 0;
//  }
//  article {
//    header.header {
//      margin-bottom: 30px;
//
//    }
//  }
//}

.notification-cover {
  margin-bottom: 40px;
}

.notification-page {
  .notificationArticle {
    display: none;
  }
  article {
    .title-not,
    .title-not a {
      font-size: 26px;
      font-family: $font-family-cond;
      color: $gray-darker;
      margin-bottom: 5px;
    }
    .title-not {
      a:hover {
        color: lighten($gray-darker, 30);
      }
    }
    time {
      color: #777;
      font-family: $font-family-cond;
      display: inline-block;
      margin-bottom: 5px;
    }
    p {
      color: $gray-darker;
    }
    .holder span {
      font-weight: bold;
      color: black;
    }
    .icon-holder {
      display: none;
    }
    @include breakpoint($tablet) {
      position: relative;
      padding-left: 80px;
      .icon-holder {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .btn-custom {
      margin-top: 20px;
      min-width: 100px;
    }
    .multiple-buttons {
      a:first-child {
        margin-right: 20px;
      }
    }
  }
  &:not(.single) {
    article {
      padding-bottom: 30px;
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  article {
    &:last-of-type {
      border-bottom: none;
    }
  }
}

/*************************************************
////////////////PAGES ALL NOT END/////////////////
*************************************************/


.load-more-holder {
  @include breakpoint($tablet) {
    width: 36%;
    margin: 0 auto;
  }
  ul {
    @extend .badges-more-bullets;
    margin: 0 auto 20px;
  }
  .btn {
    font-size: 14px;
  }
}
