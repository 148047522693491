.info-message-box {
  display: flex;
  align-items: center;
  background: #FAFAFA;
  border: 1px solid #EBEBEB;
  padding: 15px 30px;
  min-height: 85px;
  text-align: left;
  max-width: 1110px;
  margin: 25px auto 0;
  @include breakpoint($tablet) {
    margin-top: 50px;
  }
  //@include breakpoint($desktop-large) {
  //  margin-bottom: 40px;
  //}
  .img-holder {
    margin-right: 30px;
  }
  p {
    margin-bottom: 0;
    color: #333333;
    font-size: 16px;
  }

}