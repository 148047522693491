.quiz-test {

  h2,h3,h4,h5,h6 {
    font-family: $font-family-goth;
  }

  .wrapper-left {
    span {
      font-family: $font-family-cond;
    }
  }

  .lesson-title {
    position: absolute;
    left: 60%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    margin: 0;
    width: 210px;
    top: 50%;
    @include breakpoint($mobile) {
      left: 50%;
      width: auto;
    }
  }

  .container.cont1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .questions-timer-counter {
    margin-bottom: 30px;
    .question-number {
      color: #D8C5C5;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .ct-holder {
    display: flex;
    align-items: center;
    .counter-holder {
      display: flex;
      width: 100%;
      .timelineItem {
        height: 10px;
        background-color: #E3E2E2;
        margin-right: 1px;
        width: 100%;
        &:last-child {
          margin-right: 0;
        }
        &.correct {
          background-color: $green-new1;
        }
        &.uncorrect {
          background-color: $red-new;
        }
      }
    }
    .timer-holder {
      width: 80px;
      text-align: right;
      span {
        font-size: 16px;
      }
      span, img {
        position: relative;
        top: 1px;
      }
    }
  }

  .quiz-question {
    .qq-title {
      margin-bottom: 30px;
      h3 {
        font-size: 22px;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        color: $gray-darker;
      }
    }
    #preTag {
      padding: 0;
      margin: 0;
      background-color: white;
      border: none;
      font-size: 14px;
    }
    .resolved {
      font-family: $font-family-base;
      font-weight: bold;
    }
    .not-resolved {
      width: 30px;
      height: 22px;
      display: inline-block;
      border: 1px solid #ccc;
      position: relative;
      top: 6px;
    }
  }
  .task {
    &.case-1 {
      .task-question {
        padding: 20px;
        background-color: white;
        margin-bottom: 25px;
      }
      .task-answer {
        ul {
          @extend .list-unstyled;
          li {
            background: white;
            display: inline-block;
            padding: 9px 21px;
            margin-right: 18px;
            border: 1px solid white;
            min-width: 80px;
            cursor: pointer;
            transition: all .2s;
            text-align: center;
            user-select: none;
            &:hover {
              background-color: $gray-darker;
              color: white;
            }
            &:active {
              border-color: $gray-darker;
            }
          }
        }
      }
    }
    &.case-2 {
      .task-question {
        @extend .list-unstyled;
        li {
          background: url("../images/hamburger.png") no-repeat right 10px center;
          background-color: white;
          margin-bottom: 12px;
          cursor: pointer;
          padding-left: 10px;
          user-select: none;
          pre {
            border: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
            color: #333;
            background: transparent;
            line-height: 1px;
            overflow: hidden;
          }
          pre,xmp {
            font-family: $font-family-base;
          }
        }
      }
    }
    &.case-3,
    &.case-4 {
      .task-question {
        [class^="icheck-"] {
          margin-bottom: 30px;
          label {
            display: flex;
            align-items: center;
            xmp {
              margin: 0;
            }
            pre,xmp {
              font-family: $font-family-base;
            }
            pre {
              margin: 0 0 0 10px;
              border: none;
              padding: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.question-notification {
  padding-top: 35px;
  padding-bottom: 35px;
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 70%;
  transition: all .4s;
  @include breakpoint($tablet) {
    height: 50%;
  }
  @include breakpoint(1900px) {
    height: 30%;
  }
  &.correct {
    background-color: $green-new1;
  }
  &.uncorrect {
    background-color: $red-new;
  }
  h4 {
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
    color: white;
  }
  p {
    color: white;
    margin-bottom: 20px;
    @include breakpoint($desktop) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
}

.qn-open-close {
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  right: 30px;
  transition: .5s;
}

.question-notification {
  &.close {
    transition: all .4s;
    .qn-open-close {
      position: fixed;
      bottom: 50px;
      background: url("../images/double-arrow-up-gray.png") no-repeat;
    }
  }
  &.open {
    transition: all .4s;
    bottom: 0;
    .qn-open-close {
      position: absolute;
      top: 20px;
      transform: rotate(180deg);
      background: url("../images/double-arrow-up.png") no-repeat;
    }
  }
}