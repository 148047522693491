//
// Dropdown menus
// --------------------------------------------------

// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: $caret-width-base solid;
  border-right: $caret-width-base solid transparent;
  border-left: $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropdown {
  position: relative;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 90px;
  right: -5px;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  &:before {
    position: absolute;
    content: '';
    width: 260px;
    height: 60px;
    top: -58px;
    z-index: 999;
    cursor: pointer;
  }
}

// The dropdown menu (ul)
.user-menu {
  .dropdown-menu > ul {
    min-width: 250px;
    list-style: none;
    font-size: 14px;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    background-color: $dropdown-bg;
    text-transform: uppercase;
    padding: 10px 0;
    &:before {
      content: " ";
      display: inline-block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
      position: absolute;
      top: -20px;
      right: 21px;
    }
    &.pull-right {
      right: 0;
      left: auto;
    }
    > li {
      > a {
        @include clearfix;
        display: block;
        margin: 0;
        padding: 13px 20px;
        clear: both;
        color: $gray-dark;
        white-space: nowrap;
        line-height: 18px;
        border-bottom: 1px solid $gray-light;
        font-family: $font-family-cond;
        font-weight: bold;
        > span {
          display: inline-block;
          float: right;
        }
      }
      &:last-child {
        > a {
          border-bottom: none;
        }
      }
    }

    // Dividers (basically an hr) within the dropdown
    .divider {
      @include nav-divider($dropdown-divider-bg);
    }
  }
}


.user-menu {
  // Hover/Focus state
  .dropdown-menu ul > li > a {
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $orange-light;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
  .dropdown-menu ul > li > a {
    &:hover,
    &:focus {
      border-bottom: 1px solid transparent;
      color: white;
    }
  }

  // Active state
  .dropdown-menu ul > .active > a {
    &,
    &:hover,
    &:focus {
      color: $dropdown-link-active-color;
      text-decoration: none;
      outline: 0;
      background-color: $dropdown-link-active-bg;
    }
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu ul > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    @include reset-filter;
    cursor: $cursor-disabled;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}

// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: ($zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu ul {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: $caret-width-base solid;
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu ul {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
  }
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: $grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu ul {
      right: 0;
      left: auto;
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      left: 0;
      right: auto;
    }
  }
}
