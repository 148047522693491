.featured-portfolio {
  position: relative;
  @extend .clearfix;
  margin-bottom: 50px;
  .portfolios {
    width: 33.3%;
    float: left;
    a {
      display: block;
      &:hover {
        opacity: .8;
      }
    }
    img {
      margin: auto;
    }
  }
}