#feedback {
  .feedback-modal-btn {
    display: inline-block;
    text-align: center;
    background-color: $blue;
    position: fixed;
    right: -30px;
    color: white;
    font-weight: bold;
    width: 100px;
    height: 40px;
    line-height: 40px;
    z-index: 9999;
    transform: rotate(-90deg);
  }
}

.feedback-modal-btn {
    top: 330px;
}

#user-panel {
  .feedback-modal-btn {
    bottom: 250px;
    @include breakpoint($tablet) {
      bottom: $user-footer-height + 50px;
    }
  }
}


/*********************************************/
/*************Admin edit post*****************/
/*********************************************/

// Izmeni dugme
.change-entry {
  transform: rotate(-90deg);
  position: fixed;
  right: -30px;
  top: 220px;
  width: 100px;
  height: 40px;
  background-color: $aqua-light;
  color: white;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  z-index: 40;
  &:hover {
    color: white;
  }
}