.training-courses {
  .box {
    box-shadow: none;
    padding: 10px 5px;
    @include breakpoint($tablet) {
      padding: 10px;
    }
    @include breakpoint($desktop-large) {
      padding: 20px;
    }
  }
  .escape {
    margin: -10px -5px 10px;
    @include breakpoint($tablet) {
      margin: -10px -10px 20px;
    }
    @include breakpoint($desktop-large) {
      margin: -20px -20px 20px;
    }
  }
  .training, .training-course {
    border: 1px solid lighten($gray-light, 9);
  }
  .training-course {
    position: relative;
    .progress-holder {
      margin-bottom: 15px;
    }
    .holder {
      text-align: center;
      img {
        margin: 0 auto 15px;
      }
      .title {
        margin: 0;
        font-size: 13px;
        color: $gray-dark;
      }
    }
    .hover-holder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparentize(white, .1);
      opacity: 0;
      transition: all .4s;
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:hover {
      .hover-holder {
        transition: all .4s;
        opacity: 1;
      }
    }
    &.finished {
      .holder {
        opacity: .2;
      }
    }
  }
}

// Training
.training {
  margin-bottom: 16px;
  @include breakpoint($desktop) {
    margin-bottom: 0;
  }
  .title {
    font-size: 16px;
    color: $gray-dark;
    margin-bottom: 5px;
  }
  .description {
    color: $gray;
    font-family: $font-family-cond;
    margin-bottom: 18px;
  }
  .progress {
    margin-bottom: 15px;
  }
  footer {
    border: none;
  }
}