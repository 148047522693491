.team-company {
  .company-logo {
    margin-bottom: -5px;
  }
  .table {
    th {
      font-size: 16px !important;
    }
    tbody {
      tr:nth-child(odd) {
        background-color: #f6f6f6;
      }
    }
  }
  .user-name {
    font-size: 16px;
    color: #0B5B84;
  }
  .custom-width {
    .select2-container.select2-container--default {
      width: 100% !important;
    }
  }
}

.table-activity .user-name {
  font-size: 14px;
}

.course-list-wrapper {
  .more-items {
    font-size: 20px;
    text-align: center;
    margin: 0 auto -10px;
    display: none;
    cursor: pointer;
    width: 25px;
  }
}


.team-course-list {
  @extend .list-unstyled;
  .team-course-item {
    display: none;
  }
  .end, .in-progress, .not-viewed {
    text-transform: uppercase;
  }
  .end {
    color: #66CC33;
  }
  .in-progress {
    color: #3399CC;
  }
  .not-viewed {
    color: #FF0000;
  }
}

.loadMoreBtnWrp {
  @include breakpoint(0 767px) {
    margin: 15px 0;
  }
}
