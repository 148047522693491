#classroom {
  background-color: #222222;
  overflow: hidden;
  .video-js {
    background-color: #222222;
    /*Font family new*/
    /*Font family new*/

    /*Font family for old icons*/
    .vjs-resolution-button {
      .vjs-resolution-button-staticlabel { // resolution old button
        font-family: VideoJS;
      }
    }
    .vjs-icon-circle:before, .vjs-mouse-display:before, .vjs-play-progress:before, .vjs-volume-level:before { // circle old button is same
      font-family: VideoJS;
    }
    /*Font family for old icons*/
    * {
      &:before {
        color: $navy-user;
      }
      &:hover, &:focus { // disable default hover effect
        &:before {
          text-shadow: none;
          color: $blue-light;
        }
      }
    }
    .vjs-control-bar {
      height: 50px;
      background-color: black;
      .vjs-volume-menu-button:before, .vjs-time-control > div, .vjs-fullscreen-control:before, .seek-backward:before, .seek-forward:before {
        line-height: 50px;
      }
      .vjs-playback-rate {
        .vjs-playback-rate-value {
          line-height: 50px;
        }
      }
      .vjs-menu-button-popup {
        .vjs-menu {
          .vjs-menu-content {
            z-index: 9999;
            bottom: 25px;
          }
        }
      }
      .vjs-resolution-button {
        .vjs-resolution-button-staticlabel:before {
          line-height: 50px;
        }
      }
    }
    .vjs-time-control {
      padding: 0;
      text-align: center;
      &.vjs-duration {
        padding-right: 20px;
      }
    }
    .vjs-volume-bar {
      margin: 23px 11px;
      &.vjs-slider-horizontal {
        width: 60px;
        height: 6px;
        @include border-radius(2px);
        background-color: $navy;
        .vjs-volume-level {
          height: 6px;
          @include border-radius(2px);
          background-color: $blue-light;
        }
      }
    }
    .vjs-volume-menu-button-horizontal:hover {
      .vjs-menu-content {
        height: 50px;
      }
    }

    .vjs-play-control.vjs-control.vjs-button {
      width: 60px;
      height: 30px;
      background-color: $navy;
      @include border-radius(5px);
      margin: auto 10px;
      &:before {
        line-height: 30px;
      }
      &:hover {
        background-color: lighten($navy, 10%);
      }
    }

    .vjs-control.vjs-volume-menu-button-horizontal {
      width: 12em !important;
    }
    .vjs-menu-button-inline {
      .vjs-menu {
        opacity: 1 !important;
        left: 25px;
      }
    }
    .vjs-menu li:focus, .vjs-menu li:hover {
      background-color: $navy;
    }
    .vjs-resolution-button {
      .vjs-menu {
        li {
          font-size: 16px;
          color: #2D455B;
          font-family: $font-family-base;
          font-weight: bold;
          &.vjs-selected, &.vjs-selected:focus, &.vjs-selected:hover, &:focus, &:hover {
            background-color: transparent;
            color: $blue-light;
          }
        }
      }
    }

    .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
      background-color: #000;
    }

    .vjs-control-bar {
      .vjs-current-time.vjs-time-control.vjs-control, .vjs-time-control.vjs-time-divider, .vjs-duration.vjs-time-control.vjs-control {
        font-family: $font-family-base;
        font-size: 12px;
        color: $blue-light;
      }
    }

    .vjs-progress-control {
      .vjs-progress-holder {
        background: transparent;
        border: 1px solid darken($aqua-dark, 10%);
        height: 12px;
        @include border-radius(2px);
        .vjs-play-progress, .vjs-load-progress, .vjs-load-progress div {
          height: calc(100% - 2px);
          margin: auto;
          top: 0;
          bottom: 0;
          left: 2px;
          background-color: $navy;
          @include border-radius(2px);
        }
        .vjs-play-progress {
          background-color: $blue-light;
          &:before {
            display: none;
          }
        }
        .vjs-load-progress {
          background-color: $navy;
        }
        .vjs-mouse-display {
          background-color: $blue-light;
        }
        &.vjs-slider {
          margin: 0;
        }
      }
      &:hover {
        .vjs-progress-holder {
          border: 1px solid $blue-light;
          .vjs-play-progress {
            &:before {
              display: block;
              font-size: 19px;
              color: $blue-light;
              text-shadow: 0 0 4px black;
            }
          }
        }
      }
    }

    .seek-forward {
      margin-right: 15px;
    }

    .vjs-icon-play:before, .vjs-big-play-button:before, .vjs-play-control:before { // Play button
      content: '4';
      font-size: 12px;
      font-family: 'ks-icons';
    }
    .vjs-icon-pause:before, .vjs-play-control.vjs-playing:before { // Stop button
      content: '3';
      font-size: 12px;
      font-family: 'ks-icons';
    }
    .vjs-icon-volume-mute:before, .vjs-mute-control.vjs-vol-0:before, .vjs-volume-menu-button.vjs-vol-0:before { // Volume mute button
      content: '9';
      font-size: 13px;
      font-family: 'ks-icons';
    }
    .vjs-icon-volume-low:before, .vjs-mute-control.vjs-vol-1:before, .vjs-volume-menu-button.vjs-vol-1:before { // Volume min button
      content: '8';
      font-size: 13px;
      font-family: 'ks-icons';
    }
    .vjs-icon-volume-mid:before, .vjs-mute-control.vjs-vol-2:before, .vjs-volume-menu-button.vjs-vol-2:before { // Volume medium button
      content: '7';
      font-size: 14px;
      font-family: 'ks-icons';
    }
    .vjs-icon-volume-high:before, .vjs-mute-control:before, .vjs-volume-menu-button:before { // Volume max button
      content: '6';
      font-size: 15px;
      font-family: 'ks-icons';
    }
    .vjs-icon-fullscreen-enter:before, .vjs-fullscreen-control:before { // Resize button
      content: '5';
      font-size: 15px;
      font-family: 'ks-icons';
    }
    .vjs-control.vjs-button.seek-backward:before { // Backward button
      content: '1';
      font-size: 10px;
      font-family: 'ks-icons';
    }
    .vjs-control.vjs-button.seek-forward:before { // Forward button
      content: '2';
      font-size: 10px;
      font-family: 'ks-icons';
    }
    .vjs-volume-level:before { // Circle volume button
      font-size: 13px;
      color: $blue-light;
    }
  }
}

@media (max-width: 768px) {
  #classroom {
    .video-js {
      .vjs-play-control.vjs-control.vjs-button {
        margin: auto 3px;
        width: 30px;
      }
      .vjs-menu-button-inline:before {
        width: 23px;
      }
    }
    .video-js .vjs-control.vjs-volume-menu-button-horizontal {
      width: 94px !important;
    }
    .video-js .vjs-volume-bar.vjs-slider-horizontal {
      width: 55px;
    }
    .video-js .vjs-volume-bar {
      margin: 23px 6px;
    }
    .seek-backward, .seek-forward {
      width: 22px;
    }
    .video-js .seek-forward {
      margin-right: 5px;
    }
    .video-js .vjs-time-control {
      min-width: 10px;
    }
  }
  #classroom .video-js .vjs-time-control.vjs-duration {
    padding-right: 10px;
  }
}

@media (max-width: 500px) {
  #classroom {
    .video-js {
      .vjs-menu-button-inline {
        &:before {
          display: none;
        }
      }
    }
  }
  #classroom .video-js .vjs-control.vjs-volume-menu-button-horizontal {
    width: 56px !important;
  }
  #classroom .video-js .vjs-menu-button-inline .vjs-menu {
    left: 0;
  }
  #classroom .video-js .vjs-volume-bar {
    margin: 23px 8px;
  }
  #classroom .video-js .vjs-volume-bar.vjs-slider-horizontal {
    width: 40px;
  }
  #classroom .seek-backward, #classroom .seek-forward {
    width: 20px;
  }
  #classroom .video-js .vjs-control-bar .vjs-current-time.vjs-time-control.vjs-control, #classroom .video-js .vjs-control-bar .vjs-time-control.vjs-time-divider, #classroom .video-js .vjs-control-bar .vjs-duration.vjs-time-control.vjs-control {
    font-size: 9px;
  }
  #classroom .video-js .vjs-control {
    &.vjs-playback-rate, &.vjs-menu-button, &.vjs-fullscreen-control {
      width: 18px;
    }
  }
  #classroom .video-js .vjs-icon-fullscreen-enter:before, #classroom .video-js .vjs-fullscreen-control:before {
    font-size: 12px;
  }
  .vjs-resolution-button .vjs-resolution-button-staticlabel:before {
    font-size: 14px;
  }
  #classroom .video-js .vjs-playback-rate, #classroom .video-js .vjs-playback-rate *  {
    font-size: 11px;
  }
}

#classroom {
  .video-js {
    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-color: $blue-light;
      z-index: 999;
      &:before {
        color: $blue-light;
      }
      @include breakpoint($desktop) {
        line-height: 2.5em;
        height: 2.5em;
        width: 6em;
        &:before {
          font-size: 22px;
        }
      }
    }
  }
  .video-js {
    .vjs-play-progress:after, .vjs-progress-control .vjs-mouse-display:after, .vjs-playback-rate {
      font-family: $font-family-base;
    }
  }
  .vjs-menu-button-popup {
    .vjs-menu {
      width: 0;
    }
  }
}