.cookies-popup {
  position: fixed;
  width: 90%;
  background-color: #8FC357;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  z-index: 9999999999;
  left: 0;
  right: 0;
  margin: 0 auto;


  @include breakpoint(0 767px) {
    bottom: 10px;
  }

  @include breakpoint($tablet) {
    bottom: 10px;
  }
  @include breakpoint($desktop-large) {
    width: 940px;
    bottom: 20px;
  }
  @include breakpoint(1900px) {
    bottom: 40px;
  }


  .img-holder {
    margin-right: 10px;
    @include breakpoint($mobile) {
      margin-right: 15px;
    }
  }
  .content-holder {
    margin-right: 10px;
    @include breakpoint($mobile) {
      margin-right: 60px;
    }
    @include breakpoint($tablet) {
      margin-right: 110px;
    }
    p, a {
      color: white;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
    }
  }
  .close-holder {
    border-left: 1px solid #77A148;
    padding-left: 20px;
    span {
      display: inline-block;
      cursor: pointer;
      padding: 5px;
    }
  }
}