@mixin user-avatar ($width) {
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    width: $width;
    height: $width;
    text-align: center;
    line-height: $width;
    i {
        font-size: $width - 22;
    }
}

@mixin avatar-img ($width) {
    width: $width;
    height: $width;
    text-align: center;
    line-height: $width;
    display: inline-block;
    @include border-radius(50%);
}


@mixin social ($width) {
    width: $width;
    height: $width;
    line-height: $width;
    text-align: center;
    float: left;
    a {
        display: block;
    }
    &.fb {
        background-color: $facebook;
    }
    &.tw {
        background-color: $twitter;
    }
    &.in {
        background-color: $linkedin;
    }
    &.gp {
        background-color: $googleplus;
    }
    &.gh {
        background-color: black;
    }
    [class^='ksi-'] {
        color: white;
    }
}