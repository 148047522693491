.main-footer, .user-footer {

  .container {

    padding-top: 0 !important;
    padding-bottom: 0 !important;

  }

  h3, h4 {
    color: #fff;
    margin-top: 0;
    text-transform: none;
    font-family: $font-family-cond;
  }
  h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 24px;
  }
  p {
    margin-bottom: 20px;
  }

  small {
    @extend .clearfix;
    font-family: $font-family-cond;
    font-size: 16px;
    display: block;
    line-height: 1.4;
    font-weight: bold;
    a {
      color: $red;
    }
    .footer-small-right {
      float: right;
      width: 100%;
      @include breakpoint($desktop) {
        width: auto;
      }
    }
  }
  a {
    font-family: $font-family-cond;
  }
  .social-badge {
    margin-bottom: 20px;
    .social-net {
      @extend .list-unstyled;
      @extend .clearfix;
      li {
        float: left;
        position: relative;
        a {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-size: contain;
          transition: all .2s;
          text-indent: -9999px;

        }
        .twitter {
          background-image: url(../images/svg/twitter-ico.svg);
        }
        .face {
          background-image: url(../images/svg/facebook-ico.svg);
        }
        .yout {
          background-image: url(../images/svg/youtube.svg);
        }
        .gplus {
          background-image: url(../images/svg/googleplus.svg);
        }
        .pinterest {
          background-image: url(../images/svg/pinterest-ico.svg);
        }
        .instagram {
          background-image: url(../images/svg/instagram.svg);
        }
      }
    }
    img {
      float: right;
    }
  }
}

.main-footer {
  background-color: #2f3c4e;
  color: white;
  border-top: 25px solid $aqua;
  padding: 40px 0 20px;
  @include breakpoint($desktop 1199px) {
    padding: 30px 0 20px;
  }
  @include breakpoint($desktop) {
    height: $footer-height;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .home-school {
    display: none;
    margin-bottom: 20px;
    @include breakpoint($desktop) {
      display: block;
    }
    @include breakpoint($desktop-large) {
      padding-bottom: 19px;
      margin-bottom: 30px;
    }
    a {
      display: block;
      font-size: 18px;
      color: $red;
      @include breakpoint($desktop-large) {
        margin-bottom: 10px;
      }
    }
    h3 {
      margin-bottom: 10px;
    }
    h4 {
      margin-bottom: 20px;
    }
  }
  .footer-right {
    margin-bottom: 10px;
    @include breakpoint($desktop-large) {
      margin-bottom: 40px;
    }
    .footer-links {
      margin-bottom: 30px;
      margin-top: 30px;
      padding-right: 20px;
      @include breakpoint($desktop) {
        margin-bottom: 60px;
        margin-top: 0;
      }
      ul {
        li {
          margin-bottom: 5px;
          a {
            color: #586371;
            &:hover {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
      .col-sm-4 {
        &:first-child, &:nth-child(2) {
          display: none;
          @include breakpoint($desktop) {
            display: block;
          }
        }
      }
    }
    .learn-in-classroom {
      display: none;
      @include breakpoint($desktop) {
        display: block;
      }
      h4 {
        margin-bottom: 20px;
      }
      .holder {
        @extend .clearfix;
        > a {
          max-width: 255px;
          width: 100%;
          &:first-of-type {
            margin-bottom: 15px;
            margin-right: 30px;
            @include breakpoint($desktop-large) {
              margin-bottom: 0;
              float: left;
            }
          }
          &:last-of-type {
            @include breakpoint($desktop-large) {
              float: right;

            }
          }
          @include breakpoint($desktop 1199px) {
            max-width: 200px;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
  .newslet-soc {
    h3 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
    .newslet-form {
      width: 100%;
      @include border-radius(5px);
      overflow: hidden;
      position: relative;
      font-family: $font-family-cond;
      margin-bottom: 30px;
      @include breakpoint($desktop) {
        margin-bottom: 40px;
        display: block;
      }
      input {
        padding: 0 15px 0 20px;
        height: 55px;
        border: 0 none;
        font-size: 20px;
        background-color: $navy;
        color: #fff;
        @include breakpoint($desktop) {
          padding: 0 20px;
          height: 55px;
          border: 0 none;
          font-size: 20px
        }
      }
      button {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 55px;
        padding: 13px 27px;
        @include breakpoint($desktop) {
          width: 150px;
          display: block;
        }
      }
    }
  }
  .social-badge {
    .social-net {
      li {
        margin-right: 15px;
        width: 50px;
        height: 50px;
        a {
          width: 50px;
          height: 50px;
          &:hover {
            top: -3px;
            left: -3px;
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
}

.user-footer {
  background-color: #2f3c4e;
  padding-top: 20px;
  padding-bottom: 20px;
  @include breakpoint($desktop) {
    height: $user-footer-height;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .signature {
    margin-bottom: 15px;
    @include breakpoint($tablet) {
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 15px;
      font-size: 20px;
      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
    }
  }
  small {
    font-weight: normal;
    font-size: 14px;
    a {
      font-weight: bold;
    }
  }
  h4, small, small a {
    color: $gray-lighter;
  }
  .social-badge {
    margin-bottom: 0;
    margin-top: 5px;
    .social-net {
      margin-bottom: 15px;
      @include breakpoint($tablet) {
        display: table;
        margin: 0 auto;
      }
    }
  }
  .skoljkica-img {
    display: none;
    float: right;
    margin-top: -58px;
    margin-left: 20px;
    @include breakpoint($tablet) {
      display: block;
    }
  }
  .social-badge {
    .social-net {
      li {
        margin-right: 10px;
        width: 40px;
        height: 40px;
        a {
          width: 40px;
          height: 40px;
          &:hover {
            width: 44px;
            height: 44px;
            top: -2px;
            left: -2px;
          }
        }
      }
    }
  }
  @include breakpoint($tablet) {
    .holder {
      @include clearfix;
      .social-badge {
        float: right;
      }
    }
  }
}