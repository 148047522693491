$lessons-list-width: 400px;
$classroom-header-height: 53px;
$color-classroom-panel: #2F3C4E;

.classroom {

  .classroom-header,
  .wrapper-right {
    a:not(.btn-primary), .link {
      //color: lighten($color-classroom-panel, 10%);
      color: #d8c5c5;
      cursor: pointer;
      &:hover, &:focus {
        color: lighten($color-classroom-panel, 20%);
      }
    }
  }

  // fix
  a.btn-blue, a.btn-green {
    color: white;
  }

  h1 {
    font-size: 40px;
  }

  background-color: #f4f4f4;
  main {
    overflow: auto;
    height: 100%;
    width: 100%;
    top: -$classroom-header-height;
  }
  .classroom-header {
    background-color: $color-classroom-panel;
    height: auto;
    position: relative;
    z-index: 300;
    @include breakpoint($tablet) {
      height: $classroom-header-height;
    }
  }

  // Buttons
  .return-back {
    display: block;
    float: left;
    height: $classroom-header-height - 10;
    line-height: $classroom-header-height - 10;
    margin-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 0;
    border-right: 0;
    width: auto;
    text-align: center;
    border-right: 1px solid lighten($color-classroom-panel, 10%);
    @include breakpoint($mobile) {
      margin-right: 30px;

    }
    @include breakpoint($tablet) {
      margin-right: 10px;
    }
    @include breakpoint($desktop) {
      margin-right: 30px;
    }
    &:hover, &:focus {
      background-color: darken($color-classroom-panel, 10%);
      border-color: darken($color-classroom-panel, 10%);
    }
    > .ksi-left-open {
      &:first-child {
        position: relative;
        left: 7px;
        margin-right: 3px;
      }
      &:last-child {
        position: relative;
        right: 3px;
      }
    }
  }
  .lessons-toggle-button {

    @extend .return-back;
    float: right;
    margin-right: 20px;
    border-right: 0;
    border-left: 1px solid lighten($color-classroom-panel, 10%);
    width: 25%;
    @include breakpoint($tablet) {
      width: auto;
      margin-right: 0;
    }
  }
  .classroom-header {
    .classroom-main-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-left: 10px;
      float: left;
      height: $classroom-header-height;
      position: relative;
      width: auto;
      @include breakpoint($mobile) {
        margin-right: 10px;
      }
      @include breakpoint($tablet) {
        margin-right: 0;
      }
      @include breakpoint($desktop) {
        margin-right: 20px;
      }
      li {
        float: left;
      }
      .btn {
        padding: 19px 12px;
        line-height: 1;
        font-size: 14px;
        border-radius: 0;
        border: 0;
        background-color: $color-classroom-panel;
        margin-left: 0;
        //color: lighten($color-classroom-panel, 20%);
        color: #a3b0c1;
        transition: all .3s;
        @include breakpoint($tablet) {
          padding: 19px 20px;
        }
        &.active {
          color: lighten($color-classroom-panel, 40%);
          background-color: lighten($color-classroom-panel, 10%);
        }
        &:hover, &:focus {
          color: lighten($color-classroom-panel, 40%);
          outline: none;
        }
      }
      > button {
        padding: 19px 0 20px;
        width: 22%;
        float: left;
        &:last-child {
          width: 34%;
        }
        @include breakpoint($mobile) {
          padding: 19px 25px 20px;
          width: auto;
          float: none;
          &:last-child {
            width: auto;
          }
        }
        @include breakpoint($tablet) {
          padding: 19px 20px 20px;
        }
        @include breakpoint($desktop) {
          padding: 19px 25px 20px;
        }
      }
    }
  }
  .classroom-right-nav {
    float: right;
    width: 100%;
    @include breakpoint(360px) {
      width: auto;
    }
    .prev-next-nav {
      @extend .classroom-main-nav;
      margin-left: 0;
      float: left;
      width: 75%;
      margin-right: 0;
      @include breakpoint(0 1199px) {
        display: none;
      }
      @include breakpoint($tablet) {
        width: auto;
        margin-right: inherit;
      }

      a.btn {
        padding: 19px 15px;
        @include breakpoint($mobile) {
          padding: 19px 20px;
        }
        &:first-child {
          float: left;
        }
        &:last-child {
          float: right;
        }
        @include breakpoint($mobile) {
          padding: 19px 25px;
          width: 50%;
        }
        @include breakpoint($tablet) {
          width: auto;
          &:first-child {
            float: none;
          }
          &:last-child {
            float: none;
          }
        }
        .second-word {
          @include breakpoint(0 543px) {
            display: none;
          }
        }
      }
      .ksi-left-open {
        margin-right: 3px;
      }
      .ksi-right-open {
        margin-left: 3px;
      }
    }
    .lessons-toggle-button {
      @include breakpoint(0 359px) {
        width: 98%;
        float: none;
        margin: 5px auto 0;
        border: 1px solid #42546e;
      }
    }
  }
  height: 100%;
  .wrapper-left {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .wrapper-right {
    display: none;
    overflow-y: auto;
    background-color: $gray-light;
    width: $lessons-list-width;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -($lessons-list-width);
    // Lessons list
    .lessons-list {
      padding: 20px;
      h2 {
        margin-top: 35px;
        text-transform: none;
        font-size: 18px;
        padding-bottom: 10px;
        color: #666;
        border-bottom: 1px solid #ccc;
      }
      .lesson {
        list-style: none;
        margin: 5px 0;
        padding: 5px;
        .lesson-state {
          float: left;
          display: inline-block;
          text-align: center;
          width: 24px;
          line-height: 24px;
          margin-right: 15px;
          span {
            margin-top: 4px;
          }
        }
        .lesson-state-1 { // not started
          @extend %lesson-state;
        }
        .lesson-state-2 { // in-progress
          @extend %lesson-state;
          border: 4px solid $orange;
        }
        .lesson-state-3 { // finished
          @extend %lesson-state;
          border: 4px solid $orange;
          background-color: $orange;
        }
        a {
          display: block;
          h3 {
            @extend %shorten-text;
            font-family: $font-family-base;
            line-height: 24px;
            margin: 0;
            text-transform: none;
            font-size: 14px;
            font-weight: normal;
          }
          &:hover, &:focus {
            text-decoration: none;
          }
        }
        &.active, &:hover {
          background-color: white;
        }
        &.active {
          h3 {
            font-weight: bold;
          }
        }
      }
      .quiz {
        padding: 10px 10px 10px 44px;
        a {
          display: block;
          line-height: 24px;
        }
        &.active {
          a {
            font-weight: bold;
          }
        }
      }
    }
  }

  main {
    > section {
      position: absolute;
      top: $classroom-header-height;
      left: 0;
      right: 0;
      bottom: 0;
      h1, h2, h3, h4, h5, h6 {
        text-transform: none;
        margin-top: 0;
        margin-bottom: 1em;
      }
    }
  }

  ////////////////////////////////////// TEMPLATES //////////////////////////////////////////////////////////

  #content-video {
    z-index: 10;
    .video-js {
      width: 100%;
      height: 100%;
    }
    .holder {
      position: absolute;
      z-index: 100;
      bottom: 70px;
      left: 0;
      right: 0;
      margin: auto;
      @include breakpoint($mobile) {
        right: 60px;
        margin: 0;
        left: auto;
      }
      .next-lesson-message {
        background-color: $green;
        color: white;
        padding: 20px;
        opacity: 0;
        font-size: 16px;
        h3 {
          font-size: 24px;
          color: inherit;
          margin-bottom: 15px;
        }
        &.show {
          opacity: 1;
          transition: all .4s;
        }
        .btn {
          color: white;
        }
      }
    }
  }

  .content-text {
    background-color: #f4f4f4;
    overflow-y: scroll;
    overflow-x: auto;
    > .sheet {
      background-color: #fff;
      padding: 10px;
      margin: 20px auto;
      max-width: 816px;
      width: 100%;
      border: 1px solid #ddd;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
      @include breakpoint($desktop) {
        padding: 96px;
      }
      pre {
        padding: 0;
        margin: 25px 0;
        font-size: 14px;
        border: 0;
        border-radius: 0;
        code {
          padding: 20px;
          tab-size: 3;
          white-space: pre-wrap;
        }
      }
      .classroom-main-nav {
        @extend .list-unstyled;
        margin-top: 60px;
      }
    }
  }

  .content-download {
    background-color: #f4f4f4;
    overflow-y: scroll;
    overflow-x: auto;
    .container {
      .download-body {
        background-color: #fff;

        padding: 10px;
        margin: 20px auto;
        max-width: 816px;
        width: 100%;
        border: 1px solid #ddd;
        @include breakpoint($desktop) {
          padding: 96px;
        }
        h2 {
          margin-top: 0;
          margin-bottom: 30px;
        }
        ul {
          list-style: none;
          padding: 0;
          li {
            border-bottom: 1px solid $gray-lighter;
            a {
              display: block;
              padding: 15px;
              color: $red;
              i {
                color: $gray-dark;
              }
            }
            a:visited {
              i {
                color: $gray-light;
              }
            }
          }
        }
      }
    }
  }

  /* When list is on */
  &.lessons-list-on {
    .wrapper-left {
      right: $lessons-list-width;
    }
    .wrapper-right {
      display: block;
      right: 0;
    }
    .lessons-toggle-button {
      background-color: $gray-light;
      color: $gray-darker;
    }
  }

  /********************** QUIZ ************************/
  .quiz-section {
    background-color: #fff;
    padding: 20px;
    h1 {
      font-size: 16px;
      margin-top: 0;
      color: #999;
      font-weight: normal;
    }
    .btn-answer {
      width: 100%;
      background-color: #f4f4f4;
      text-align: left;
      text-transform: none;
      font-weight: normal;
      margin-bottom: 15px;
      border-radius: 0;
      &.selected {
        background-color: $brand-warning;
      }
      &.wrong {
        background-color: $brand-danger;
      }
      &.correct {
        background-color: $brand-success;
      }
    }
    .explanation {
      padding: 10px;
    }
    /* Large devices (desktops, 62em lg 992px) */
    @media (min-width: 62em) {
      width: 800px;
      margin: 30px auto;
      padding: 30px;
      .btn-answer {
        width: 50%;
      }
    }
  }

  .quizzes-section {
    background-color: white;
    min-height: 100%;
    padding: 90px 30px;
    article {
      height: 100%;
      @include breakpoint($desktop-large) {
        &:not(.quiz-article-result) {
          width: 80%;
          margin: auto;
        }
      }
    }
    .answer {
      padding: 20px;
      font-size: 18px;
      cursor: default;
      transition: background-color .3s;
      &.avl:hover {
        background-color: #f2f2f2;
      }
      &.selected {
        border-left: 5px solid $blue;
        background-color: #f9f9f9;
      }
      &.wrong {
        background-color: $red-light;
      }
      &.correct {
        background-color: $green-light;
      }
    }
    .explanation {
      padding: 10px 20px;
      margin-bottom: 10px;
      margin-left: 20px;
      border-top: 1px solid $blue;
      color: #000;
      font-size: 16px;
      font-style: italic;
    }
    // Result
    .result-circle {
      width: 150px;
      height: 150px;
      .progressbar-text {
        letter-spacing: 2px;
        font-size: 19px;
        color: $gray-light;
        text-align: center;
      }
    }

    article {
      p {
        color: $blue;
        font-size: 21px;
        margin-bottom: 30px;
      }
    }
  }

  /*** HOMEWORK ****/
  .dz-processing {
    padding: 15px;
    background-color: $gray-lighter;
  }
  .uploads-section {
    h3 {
      margin: 2em 0 1em;
      font-size: 24px;
    }
  }
  .uploaded-homeworks {
    margin: 20px 0;
    article {
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-lighter;
      margin-bottom: 10px;
      line-height: 30px;
      .btn {
        float: right;
      }
    }
  }

  .material-notification {
    border-radius: 50%;
    background: $red;
    line-height: 1;
    text-align: center;
    font-size: 12px;
    color: white;
    font-style: normal;

    display: inline;
    padding: 3px 7px;
    position: relative;
    top: -2px;
    right: -3px;
  }

}

#classroom.classroom {
  .btn {
    box-shadow: none;
  }
}

/*Lesson list on*/

#classroom.lessons-list-on {
  .classroom-header, #content-video {
    z-index: auto;
  }
  @include breakpoint($desktop) {
    .classroom-header {
      z-index: 300;
    }
  }
  .classroom-header {
    height: auto;
  }
  .classroom-right-nav {
    width: 100%;
    @include breakpoint($tablet) {
      float: left;
      .prev-next-nav {
        width: 100%;
        a.btn {
          width: 50%;
          float: left;
        }
      }
    }
    @include breakpoint($desktop) {
      width: auto;
      float: right;
      .prev-next-nav {
        width: auto;
        a.btn {
          width: auto;
          float: none;
        }
      }
    }
  }
  .classroom-main-nav {
    @include breakpoint($desktop) {
      margin-left: 10px;
      margin-right: 10px;
    }
    @include breakpoint($desktop-large) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .wrapper-left {
    right: 250px;
    @include breakpoint($tablet) {
      right: 400px;
    }
  }
  .wrapper-right {
    width: 100%;
    @include breakpoint($mobile) {
      width: 250px;
    }
    @include breakpoint($tablet) {
      width: 400px;
    }
  }
  .lessons-toggle-button.link {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    margin: 5px;
    border: 1px solid lighten($color-classroom-panel, 10%);
    width: calc(100% - 10px);
    @include breakpoint($mobile) {
      width: 240px;
    }
    @include breakpoint($tablet) {
      width: 390px;
    }
    @include breakpoint($desktop) {
      position: static;
      margin: 5px 0 0;
      border: none;
      border-left: 1px solid lighten($color-classroom-panel, 10%);
      width: auto;
    }
  }
  @include breakpoint($mobile) {
    .return-back {
      margin-right: 5px;
    }
    .classroom-main-nav {
      margin: 0;
    }
    header .classroom-main-nav .btn {
      padding: 19px 14px;
    }
  }
  @include breakpoint($tablet) {
    .return-back {
      margin-right: 20px;
    }
    .classroom-main-nav {
      margin-left: 10px;
      margin-right: 20px;
    }
    header .classroom-main-nav .btn {
      padding: 19px 17px;
    }
  }

  /* Print */
}

@include breakpoint($desktop-large) {
  #classroom.lessons-list-on {
    .return-back {
      margin-right: 0;

    }
    .classroom-main-nav {
      margin-right: 10px;
      header .btn {
        font-size: 14px;
        padding: 19px 20px 19px;
      }
    }
  }
}

h2.video-title {
  position: fixed;
  top: 53px;
  padding: 20px;
  left: 0;
  right: 0;
  color: white;
  width: 100%;
  background-color: yellow;
  font-size: 26px;
  background: -moz-linear-gradient(top, rgba(47, 60, 78, 1) 0%, rgba(47, 60, 78, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(47, 60, 78, 1) 0%, rgba(47, 60, 78, 0) 100%);
  background: linear-gradient(to bottom, rgba(47, 60, 78, 1) 0%, rgba(47, 60, 78, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f3c4e', endColorstr='#002f3c4e', GradientType=0);
}

.vjs-fullscreen {
  h2.video-title {
    top: 0;
  }
}

#printableArea {
  display: none;
  visibility: visible;
}

@page {
  size: A4;
  margin: 2cm;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  html body > div, html body > aside, html body > input {
    display: none;
    visibility: hidden;
  }
  /* custom */
  html body #printableArea {
    display: block !important;
    visibility: visible !important;
  }

  /* global */
  body {
    color: #000;
    font-size: 9pt;
  }

  .page-break {
    page-break-before: always;
  }

  h1 {
    margin-bottom: 2em;
  }

  /* links */
  a {
    text-decoration: underline;
    color: #999;
  }
}

.popover-content {
  @extend .clearfix;
  > img {
    float: left;
  }
  > div {
    width: calc(100% - 75px);
    float: right;
  }
}

// Right Click Warning box

#rclick-warning {
  display: none;
  position: fixed;
  z-index: 9999;
  &.rc_open {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparentize(black, .1);
    .holder {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.rc_close {
    display: none;
  }
  .message-red {
    padding: 10px 40px 35px;
  }
}

@include breakpoint(0 767px) {
  #rclick-warning {
    .img-holder {
      display: none;
    }
    .message-red {
      padding: 20px 15px 25px;
    }
  }
}

@include breakpoint($mobile 767px) {
  #rclick-warning {
    &.rc_open {
      .holder {
        width: 100%;
      }
    }
  }
}

@include breakpoint(0 543px) {
  #rclick-warning {
    &.rc_open {
      .holder {
        position: static;
        transform: translate(0);
      }
    }
  }
}

// Right Click Warning box end