main {
    position: relative;
}

////
/// Containers
//

.main-container {
    @extend .container;
    padding-top: 25px;
    padding-bottom: 25px;
    @include breakpoint($tablet) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

$fhbe-excess-mobile: $footer-height-mobile + $header-height-mobile;
$fhbe-excess: $footer-height + $header-height;
.full-height-background-element {
    position: absolute;
    top: 0;
    height: calc(100vh - #{$fhbe-excess-mobile});
    width: 100%;
    z-index: -1;
    padding-bottom: $footer-height-mobile + $header-height-mobile;
    @include breakpoint($desktop) {
        padding-bottom: 0;
        height: calc(100vh - #{$fhbe-excess});
    }
}

////
/// Templates
//

.template-orange {
    @include create-layout($orange, $orange-dark, lighten($orange-light, 10%), $orange-dark);
}

.template-green {
    @include create-layout($green, $green-dark, lighten($green-light, 10%), $green-dark);
}

.template-blue {
    @include create-layout($blue, $blue-dark, lighten($blue-light, 10%), $blue-dark);
}

.template-dark-blue {
    @include create-layout($blue-dark, darken($blue-dark, 10%), lighten($blue-light, 10%), darken($blue-dark, 10%));

}


.full-width {
    margin: auto;
    @media (min-width: 1920px) {
        width: 100%;
        max-width: none;
    }
}