.external-lecture {
  .info-message-box {
    max-width: 1140px;
    transform: translateX(15px);
  }
  .silverDesc {
    strong {
      color: $gray-darker;
    }
  }
}