@mixin toptitle-variant($name, $background-color, $font-color) {
  > li.toptitle-#{$name} {
    background-color: $background-color;
    color: $font-color;
    a {
      display: block;
      color: $font-color;
    }
  }
}

.toptitle-relative {
  position: relative;
}

.toptitle-group {
  @extend .list-inline;
  @extend .list-unstyled;
  width: 100%;
  position: absolute;
  top: -25px;
  text-align: center;
  z-index: 800;
  padding-left: 0;
  margin-left: 0;
  left: 0;
  right: 0;
  .toptitle {
    @extend .clearfix;
    display: inline-block;
    padding-left: 15px;
    padding-right: 20px;
    background-color: $white;
    height: 50px;
    line-height: 50px;
    border-radius: 25px 0 25px 25px;
    font-family: $font-family-cond;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    span[class^="ksi-"] {
      margin-right: 10px;
      font-size: 20px;
    }
  }
  // Generisanje varijanti  /ime /back-c /font-c
  @include toptitle-variant(green, $green, white);
  @include toptitle-variant(red, $red, white);
  @include toptitle-variant(blue, $blue, white);
  @include toptitle-variant(blue-light, $blue-light, white);
}