.pagination {
    @extend .clearfix;
    li {
        margin: 0 5px 5px 0;
        float: left;

        @include breakpoint($desktop) {
            margin-bottom: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    li, li a, li span {
        text-align: center;
        min-width: 37px;
        max-width: 39px;
        height: 36px;
    }

    li, li.disabled {
        > a, > span {
            color: $blue-dark;
            background-color: $aqua-light;
            border: none;
            padding: 8px;
        }
        &.active {
            > a, > span {
                color: white;
                background-color: $blue-light;
            }
        }
        &.disabled {
            > a, > span {
                color: lighten($blue-dark, 18);
                background-color: lighten($aqua-light, 8);
            }
        }
        &:hover {
            > a, > span {
                color: lighten($blue-dark, 18);
                background-color: lighten($aqua-light, 8);
            }
        }
    }

    ////
    /// MEDIA-QUERIES FOR DISPLAYING LINKS
    //
    // Bootstrap the displayed state
    li {
        @include breakpoint(0 $desktop) {
            &:not([class]) {
                display: none;
            }
        }

        &:last-child, &:first-child, &:nth-last-child(2), &:nth-child(2) {
            display: inline;
        }
    }

    &.special-pagination {
        li {
            @include breakpoint(0 $desktop) {
                &:not([class]) {
                    display: inline;
                }
            }
        }
        &:not(.middle) {
            li {
                @include breakpoint(319px 355px) {
                    &:nth-last-child(2) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // Close to beginning
    &.start-pagination {
        li {
            @include breakpoint(319px 355px) {
                &:nth-last-child(2) {
                    margin-right: 0;
                }
            }
            @include breakpoint(0 413px) {
                &:nth-child(-n+5) {
                    display: inline;
                }
            }
            @include breakpoint(414px 449px) {
                &:nth-child(-n+6) {
                    display: inline;
                }
            }
            @include breakpoint(450px $desktop) {
                &:not([class]) {
                    display: inline;
                }
            }
        }
    }

    // In the middle
    &.mid-pagination {
        li {
            @include breakpoint(408px 486px) {
                &:nth-child(4) {
                    display: inline;
                }
                &.active + li {
                    display: inline;
                }
            }
            @include breakpoint(487px $desktop) {
                &:not([class]) {
                    display: inline;
                }
            }
        }
    }

    // Close to the end
    &.end-pagination {
        li {
            @include breakpoint(319px 355px) {
                &:nth-last-child(2) {
                    margin-right: 0;
                }
            }
            @include breakpoint(0 413px) {
                &:nth-last-child(-n+5) {
                    display: inline;
                }
            }
            @include breakpoint(414px 449px) {
                &:nth-last-child(-n+6) {
                    display: inline;
                }
            }
            @include breakpoint(450px $desktop) {
                &:not([class]) {
                    display: inline;
                }
            }
        }
    }
}