.students-recommendations {
    margin-bottom: 50px;
    @include breakpoint($desktop) {
        padding-bottom: 40px;

    }
    @extend .clearfix;
    header {
        margin-bottom: 20px;
    }
    .recommendation {
        width: 50%;
        float: left;
        padding: 0 8px;
        border-right: 1px solid $gray-lighter;
        position: relative;
        //margin-bottom: 15px;
        text-align: center;
        &:last-child {
            border-right: none;
        }
        .hover {
            opacity: 0;
            transition: all .3s;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            a {
                position: absolute;
                bottom: 30px;
                left: 0;
                right: 0;
                max-width: 100px;
                margin: auto;
            }

        }
        .user-avatar {
            margin: 0 auto 10px;
            @include avatar-img (60px);
            border: 1px solid $gray-light;
            &:not(.background) {
                border: 1px solid transparent;
            }
            img {
                @include avatar-img (60px);
            }
            i {
                font-size: 38px;
            }
            .w100 {
                width: 60px;
                height: 60px;
                line-height: 60px;
                img {
                    width: 30px;
                    height: auto;
                    line-height: 30px;
                }
            }
        }
        .title {
            font-size: 16px;
        }
        q {
            font-size: 12px;
            &:after, &:before {
                content: "";
            }
        }
        &:hover {
            .hover {
                transition: all .3s;
                cursor: pointer;
                background: transparentize($white, .2);
                opacity: 1;
            }
        }
    }
}