.students-page {
    .col-md-6 {
        @include breakpoint($desktop) {
            &:nth-child(2n+1) {
                clear: both;
            }
        }
    }
}

.student-page {
    .students-portfolio-row {
        @include breakpoint($desktop) {
            .col-md-4:nth-child(3n+1) {
                clear: both;
            }
        }
    }
}

.categories-box {
    margin-bottom: $grid-gutter-width;
}

.students-box {
    position: relative;
    @extend .box;
    margin-bottom: 45px;
    .user-avatar {
        >img {
            top: 15px;
            left: 15px;
            @include user-avatar(70px);
            @include breakpoint($desktop) {
                @include user-avatar(100px);
            }
        }
        .student-icons {
            position: absolute;
            top: 15px;
            left: 15px;
            @include breakpoint(0 990px) {
                width: 70px;
                height: 70px;
                line-height: 70px;
                img {
                    width: 40px;
                }
            }
        }
    }

    .name, .name a {
        font-size: 20px;
        color: $gray-darker;
        margin-bottom: 5px;
        font-weight: bold;
    }
    .description {
        font-size: 14px;
        color: $gray-dark;
        margin: 0;
        min-height: 40px;
    }
    .time {
        margin-bottom: 10px;
        color: $gray;
    }
    > .holder {
        @extend .clearfix;
        .portfolio-url {
            display: inline-block;
            float: right;
            color: $gray-dark;
            margin-bottom: 5px;
        }
    }
    .escape {
        .holder {
            position: relative;
        }
    }
    .hover {
        @extend .standard-hover-box;
        background-color: transparentize($navy, .3);
        @include breakpoint($desktop) {
            background-color: transparentize($navy, .1);
        }
        a {
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 9;
        }
    }
    .hover {
        opacity: 1;
        @include breakpoint($desktop) {
            opacity: 0;
        }
    }
    @include breakpoint($desktop) {
        &:hover {
            .hover {
                opacity: 1;
            }
        }
    }
    footer {
        padding-top: 10px;
        margin-top: 15px;
    }
}

.search-students {
    @extend .clearfix;
    >input {
        width: calc(100% - 100px);
        float: left;
    }
    >button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 16px;
        padding: 13px 15px;
        width: 100px;
        float: left;

    }
}