.course-archive {
    &.large-box {
        .holder {
            .hover {
                .onl {
                    &:before {
                        @include breakpoint($mobile) {
                            height: 130px;
                        }
                        @include breakpoint($desktop-large) {
                            height: 200px;
                        }
                    }
                }
            }
        }
        .holder-group {
            margin-bottom: 25px;
            @extend .clearfix;
            .free-space-school {
                padding-top: 6px;
                float: left;
            }
        }
        .student-icons {
            &.w100 {
                width: 60px;
                height: 60px;
                line-height: 60px;
                img {
                    width: 30px;
                }
            }
        }
        .background {
            @include breakpoint(0 767px) {
                .student-icons.w100 {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    img {
                        width: 20px;
                    }
                }
            }
        }
    }
}
.course-box:not(.large-box), .course-archive:not(.large-box) {
    .hit-badge-img {
        position: absolute;
        z-index: 1;
        bottom: -15px;
        right: -15px;
        width: 90px;
        @include breakpoint($mobile) {
            width: 120px;
        }
        @include breakpoint($desktop-large) {
            right: -25px;
            width: 161px;
        }
    }
}
.course-box.large-box, .course-archive.large-box {
    .students-comments {
        li {
            &:nth-child(7), &:nth-child(8) {
                @include breakpoint($desktop 1199px) {
                    display: none;
                }
            }
        }
    }
    .title {
        text-transform: none;
        margin-bottom: 5px;
        margin-top: 0;
        @include breakpoint($tablet) {
            font-size: 36px;
        }
        & + a {
            display: block;
        }
    }
    .hit-badge-img {
        position: absolute;
        z-index: 1;
        bottom: -15px;
        right: -15px;
        width: 90px;
        @include breakpoint($mobile) {
            bottom: -25px;
            width: 120px;
        }
        @include breakpoint($tablet) {
            bottom: -30px;
            right: -20px;
            width: 150px;
        }
        @include breakpoint($desktop-large) {
            bottom: -40px;
            right: -35px;
            width: auto;
        }
    }
}

.my-courses {
    .large-box {
        .box-body {
            margin-bottom: 20px;
        }
        .students-comments {
            margin-bottom: 20px;
        }
    }
}
