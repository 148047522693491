.user-activation {
    h2.title {
        @include breakpoint($tablet) {
            font-size: 50px;
        }
    }
    .user-activated-content {
        padding-top: 40px;
        padding-left: 20px;
        p {
            font-size: 16px;
        }
    }
}

.user-activated {
    .user-activated-content {
        h2,h3,h4,p {
            font-family: $font-family-goth;
        }
        .title {
            text-transform: uppercase;
            font-size: 30px;
            margin-bottom: 20px;
        }
        h4, p {
            font-size: 20px;
            letter-spacing: .7px;
        }
    }
}

.set-password-page {
    .user-activated-content {
        padding-top: 40px;
        padding-left: 20px;
        p {
            font-size: 16px;
        }
    }
    .main-container {
        @include breakpoint($desktop) {
            position: relative;
            &:before {
                width: 190px;
                height: 190px;
                background-color: #FEAC1C;
                @include border-radius(50%);
                position: absolute;
                right: 0;
                top: -96px;
                z-index: -1;
            }
        }
    }
}