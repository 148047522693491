// Rating stars samo za front
.ratings-sprite-holder {
  background: url("../images/rating-stars.png") repeat-x;
  font-size: 0;
  height: 13px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 73px;
  margin: 2px auto 3px;

  .ratings-sprite {
    background: url("../images/rating-stars.png") repeat-x;
    background-position: 0 100%;
    float: left;
    height: 12.9px;
    display:block;
    margin-bottom: 0 !important;
  }
  & + .rating-students {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }
}
// Rating stars samo za front END



// Rating stars klikabilni
/* Rating Star Widgets Style */
.rating-stars-list {
  ul {
    @extend .list-unstyled;
    @extend .clearfix;
    margin-bottom: 0;
    li {
      float: left;
      margin-right: 5px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 24px;
        path {
          fill: transparent;
          stroke: #FEC024;
          stroke-width: 2px;
          transition: all .3s;
        }
      }
      &.hover {
        svg {
          path {
            fill: $yellow;
          }
        }
      }
      &.selected {
        svg {
          path {
            fill: #FEC024;
          }
        }
      }
    }
  }
}
// Rating stars klikabilni END


.stars-ratings {
  font-size: 24px;
  height: 36px;
  width: 99px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0;

  .stars-ratings-top {
    color: #FFCC00;
    padding: 0;
    position: absolute;
    z-index: 1;
    display:block;
    left: 0;
    overflow: hidden;
  }
  .stars-ratings-bottom {
    z-index: 0;
    color: #FFCC00;
  }
}

.osx {
  .stars-ratings {
    width: 119px;
  }
}