.badge {
  display: inline-block;
  @include border-radius(50%);
  background: $red;
  line-height: 1;
  text-align: center;
  font-size: 12px;
  color: white;
  font-style: normal;
  padding: 4px 7px;
  position: absolute;
  top: -13px;
  right: -7px;
}