.form-group {
    margin-bottom: 30px;
}

legend {
    border-bottom: 1px solid $gray-light;
    margin-bottom: 20px;
    color: $gray-darker;
    font-size: 20px;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
    margin-left: 0;
}

.radio-inline, .checkbox-inline {
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}


.form-control {
    padding: 6px 10px;
    height: 50px;
    box-shadow: none;
    border-color: $gray-light;
    font-size: 16px;
    color: $gray-dark;
    &:focus {
        border-color: $blue;
        box-shadow: none;
    }
    &[disabled] {
        border-color: $gray;
    }
}

label {
    font-weight: normal;
    margin-bottom: 0;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
input[type="url"],
input[type="tel"],
textarea,
select {
    @extend .form-control;
}

textarea, textarea.form-control {
    height: 124px;
    resize: vertical;
}

.form-inline {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="search"],
    input[type="url"],
    input[type="tel"],
    textarea,
    select {
        width: 100%;
        //margin-bottom: 10px; OVO MI SMETA
        @include breakpoint($tablet) {
            width: 25%;
        }
        @include breakpoint($desktop) {
            width: 20%;
        }
    }
}

.form-icon {
    position: relative;
    [class^='ksi-'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
    }
    input, select {
        padding-left: 50px;
    }
}

%pointer {
    cursor: pointer;
}

.checkbox, .radio {
    @extend %pointer;
}

.radio-inline, .checkbox-inline {
    @extend %pointer;
    padding-left: 0;
    margin-right: 20px;
}

////
/// Validation
//
label.error {
    color: $red-dark;
    padding: 6px 19px 0;
}
input.error, textarea.error, select.error, span.error.select2 {
    border: 1px solid $red-dark !important;
}