.student-page {
  .title {
    margin-top: 10px;
    @include breakpoint($tablet) {
      font-size: 40px;
    }
    font-size: 30px;
  }

  .large-box {
    margin-bottom: 45px;
    @include breakpoint($tablet) {
      margin-bottom: 65px;
    }
    .text {
      font-style: italic;
    }
  }

  main {
    .user-avatar {
      margin-bottom: 20px;
      width: 95px;
      height: 95px;
      @include breakpoint($desktop-large) {
        width: 165px;
        height: 165px;
      }
      .student-icons {
        width: 95px;
        height: 95px;
        position: relative;
        @include breakpoint($desktop-large) {
          width: 165px;
          height: 165px;
        }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 50px;
          @include breakpoint($desktop-large) {
            width: 70px;
          }
        }
      }
    }
  }

  .student-social {
    display: table;
    margin: 0 auto 30px;
  }

  .cke-editable {
    margin-bottom: 40px;
  }

  .skills {
    margin: 0;
    li {
      background-color: $blue;
      color: white;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 5px;
      @include breakpoint($tablet) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &.haveMoreRows {
      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    & + {
      .btn {
        display: table;
        margin: auto;
        @include breakpoint($mobile) {
          display: inline-block;
        }
      }
    }
  }

  .about {
    margin-bottom: 70px;
    @include breakpoint($desktop) {
      margin-bottom: 10px;
    }
  }

  .students-box {
    margin-bottom: 75px;
    img:hover {
      opacity: 0.8;
    }
  }

  @include breakpoint(0 990px) {
    .cover + .block-white.pt30 {
      padding-bottom: 90px;
    }
  }

  .student-about {
    .title {
      margin-bottom: -5px;
      float: left;
      @include breakpoint($mobile) {
        margin-bottom: 5px;
      }
    }
    .badges-conquer-title {
      clear: both;
      margin-bottom: 10px;
      @include breakpoint($mobile) {
        clear: none;
        margin-left: 15px;
        margin-bottom: 0;
        float: left;
      }
      @include breakpoint($tablet) {
        margin-top: 10px;
      }
      .cls-3 {
        fill: #ffc021;
      }
    }
    .sub-title-img {
      margin-bottom: 20px;
    }
    .cke-editable {
      h3 {
        margin-top: 20px;
      }
    }
    .badges-holder {
      .row {
        margin-left: -7px;
        margin-right: -7px;
        [class^="col-"] {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
      .conquer-badge {
        margin-top: 10px;
        margin-bottom: 10px;
        @include breakpoint($tablet) {
          margin-top: 0;
          margin-bottom: 0;
        }
        .badge-holder {
          margin-bottom: 0;
        }
        @include breakpoint(0 543px) {
          width: 73px;
        }
        @include breakpoint($mobile 767px) {
          width: 100px;
        }
        @include breakpoint($tablet 991px) {
          width: 87px;
        }
        .hover-element {
          display: none;
          background-color: $gray-light;
          width: 130px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          position: absolute;
          left: -20px;
          top: -55px;
          @include border-radius(4px);
          @include breakpoint($mobile) {
            left: -6px;
          }
          @include breakpoint($tablet) {
            left: -13px;
          }
          @include breakpoint($desktop) {
            left: -2px;
          }
          @include breakpoint($desktop-large) {
            left: -24px;
          }
          &:after {
            @include trianle-bottom(10px, $gray-light);
            bottom: -10px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        &:hover {
          .hover-element {
            display: inline-block;
          }
        }
      }
      &.spbhMoreRows {
        .conquer-badge {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .student-social {
    @extend .clearfix;
    @extend .list-unstyled;
    li {
      @include social(30px);
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
      @include breakpoint($tablet 1199px) {
        &:nth-child(2n+1) {
          clear: both;
          margin-bottom: 10px;
        }
      }
    }
  }

  .titleCurly {
    .mainTC {
      font-size: 18px;
      font-family: $font-family-goth;
      color: #FDBE3E;
      margin-top: 6px;
      @include breakpoint($tablet) {
        width: 85px;
      }
      @include breakpoint($desktop) {
        width: 110px;
      }
    }
    .curlyB {
      color: #E6E9EE;
      font-family: $font-family-base;
      display: none;
      @include breakpoint($tablet) {
        display: inline-block;
        font-size: 76px;
      }
      @include breakpoint($desktop) {
        font-size: 96px;
      }
    }
  }

  .flex {
    margin-bottom: 30px;
  }

  @include breakpoint($tablet) {
    .flex {
      display: flex;
      >.col-sm-2 {
        display: flex;
        align-items: center;
      }
      >.col-sm-10 {
        display: flex;
        align-items: center;
        .badges-holder {
          flex: 1;
        }
      }
      .titleCurly {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }

}
