@font-face {
  font-family: 'ks-icons';
  src: url('../fonts/ks-icons.eot?36927290');
  src: url('../fonts/ks-icons.eot?36927290#iefix') format('embedded-opentype'),
       url('../fonts/ks-icons.woff2?36927290') format('woff2'),
       url('../fonts/ks-icons.woff?36927290') format('woff'),
       url('../fonts/ks-icons.ttf?36927290') format('truetype'),
       url('../fonts/ks-icons.svg?36927290#ks-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ks-icons';
    src: url('../font/ks-icons.svg?36927290#ks-icons') format('svg');
  }
}
*/
 
 [class^="ksi-"]:before, [class*=" ksi-"]:before {
  font-family: "ks-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: auto;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.ksi-left-big:before { content: '\21'; } /* '!' */
.ksi-spin5:before { content: '\22'; } /* '&quot;' */
.ksi-bell:before { content: '\27'; } /* ''' */
.ksi-doc-text:before { content: '\2b'; } /* '+' */
.ksi-angle-down:before { content: '\2c'; } /* ',' */
.ksi-right-big:before { content: '\2d'; } /* '-' */
.ksi-menu:before { content: '\2e'; } /* '.' */
.ksi-github:before { content: '\30'; } /* '0' */
.ksi-player-backward:before { content: '\31'; } /* '1' */
.ksi-player-forward:before { content: '\32'; } /* '2' */
.ksi-player-pause:before { content: '\33'; } /* '3' */
.ksi-player-play:before { content: '\34'; } /* '4' */
.ksi-player-resize:before { content: '\35'; } /* '5' */
.ksi-player-volume-max:before { content: '\36'; } /* '6' */
.ksi-player-volume-med:before { content: '\37'; } /* '7' */
.ksi-player-volume-min:before { content: '\38'; } /* '8' */
.ksi-player-volume-mute:before { content: '\39'; } /* '9' */
.ksi-lock:before { content: '\3a'; } /* ':' */
.ksi-gallery:before { content: '\3b'; } /* ';' */
.ksi-down:before { content: '\3c'; } /* '&lt;' */
.ksi-list:before { content: '\3f'; } /* '?' */
.ksi-relax-room-icon:before { content: '\41'; } /* 'A' */
.ksi-angle-left:before { content: '\42'; } /* 'B' */
.ksi-right-open:before { content: '\43'; } /* 'C' */
.ksi-learn-classroom:before { content: '\44'; } /* 'D' */
.ksi-quiz-check:before { content: '\45'; } /* 'E' */
.ksi-learn-online:before { content: '\46'; } /* 'F' */
.ksi-profile:before { content: '\47'; } /* 'G' */
.ksi-trigger:before { content: '\48'; } /* 'H' */
.ksi-internet-wifi:before { content: '\49'; } /* 'I' */
.ksi-trash:before { content: '\4a'; } /* 'J' */
.ksi-unlock:before { content: '\4b'; } /* 'K' */
.ksi-mail:before { content: '\4c'; } /* 'L' */
.ksi-angle-up:before { content: '\4d'; } /* 'M' */
.ksi-angle-right:before { content: '\4e'; } /* 'N' */
.ksi-calendar:before { content: '\4f'; } /* 'O' */
.ksi-attention-alt:before { content: '\50'; } /* 'P' */
.ksi-wish-list-enter:before { content: '\51'; } /* 'Q' */
.ksi-learn-class:before { content: '\52'; } /* 'R' */
.ksi-support:before { content: '\53'; } /* 'S' */
.ksi-learn-new-notes:before { content: '\54'; } /* 'T' */
.ksi-learn-script-icon:before { content: '\55'; } /* 'U' */
.ksi-up-open:before { content: '\56'; } /* 'V' */
.ksi-screenshot-chat:before { content: '\57'; } /* 'W' */
.ksi-left-open:before { content: '\58'; } /* 'X' */
.ksi-down-open:before { content: '\59'; } /* 'Y' */
.ksi-learn-course-test:before { content: '\5a'; } /* 'Z' */
.ksi-linkedin:before { content: '\5c'; } /* '\' */
.ksi-search:before { content: '\5f'; } /* '_' */
.ksi-prize:before { content: '\61'; } /* 'a' */
.ksi-video-notes:before { content: '\62'; } /* 'b' */
.ksi-video-chat:before { content: '\63'; } /* 'c' */
.ksi-notes:before { content: '\64'; } /* 'd' */
.ksi-dd:before { content: '\65'; } /* 'e' */
.ksi-pinterest:before { content: '\66'; } /* 'f' */
.ksi-portfolio:before { content: '\67'; } /* 'g' */
.ksi-recommendation-title:before { content: '\68'; } /* 'h' */
.ksi-google-plus:before { content: '\69'; } /* 'i' */
.ksi-cancel:before { content: '\6a'; } /* 'j' */
.ksi-settings:before { content: '\6b'; } /* 'k' */
.ksi-students:before { content: '\6c'; } /* 'l' */
.ksi-wallet:before { content: '\6d'; } /* 'm' */
.ksi-video-script:before { content: '\6e'; } /* 'n' */
.ksi-hat:before { content: '\6f'; } /* 'o' */
.ksi-wishlist-drop:before { content: '\70'; } /* 'p' */
.ksi-lesson-finished:before { content: '\71'; } /* 'q' */
.ksi-facebook:before { content: '\72'; } /* 'r' */
.ksi-male:before { content: '\73'; } /* 's' */
.ksi-facebook-circle:before { content: '\74'; } /* 't' */
.ksi-google:before { content: '\75'; } /* 'u' */
.ksi-video-download:before { content: '\76'; } /* 'v' */
.ksi-courses:before { content: '\77'; } /* 'w' */
.ksi-twitter-circle:before { content: '\78'; } /* 'x' */
.ksi-twitter:before { content: '\79'; } /* 'y' */
.ksi-female:before { content: '\7a'; } /* 'z' */
.ksi-views:before { content: '\7c'; } /* '|' */
.ksi-play-circle:before { content: '\7e'; } /* '~' */
.ksi-logout:before { content: '\f7'; } /* '÷' */
.ksi-material:before { content: '\201a'; } /* '‚' */
.ksi-progress-cup:before { content: '\20ac'; } /* '€' */
.ksi-lesson-in-progress:before { content: '\e800'; } /* '' */