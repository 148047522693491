// Buttons

.btn {
  border-radius: 3px;
  font-size: 18px;
  font-family: $font-family-cond;
  text-transform: uppercase;
  padding: 12px 30px 11px;
  &.btn-xs {
    height: 25px;
    min-width: 30px;
    font-size: 12px;
    padding: 3px 10px;
  }
  &.btn-sm {
    height: 40px;
    min-width: 140px;
    font-size: 14px;
    padding: 8px 15px;

  }
  &.btn-md {
    height: 40px;
    min-width: 140px;
    font-size: 16px;
    padding: 9px 37px;
  }
  &.btn-lg {
    height: 50px;
    min-width: 200px;
    font-size: 18px;
    padding: 12px 37px;
  }
}

.btn-browse {
  @extend .btn;
  @extend .btn-transparent-blue;
  @extend .btn-md;
  margin-right: 20px;
}

button {
  @extend .btn;
}

//.disable-shadow {
//  box-shadow: none !important;
//}

button.white, .btn-white {
  @include button-variant(white, transparent, white);
}

button.blue-light, .btn-blue-light {
  @include button-variant(white, $blue-light, $blue-light);
}

button.blue, .btn-blue {
  @include button-variant(white, $blue, $blue);
}

button.blue-alt, .btn-blue-alt {
  @include button-variant(white, $blue, white);
}
button.blue-new1, .btn-blue-new1 {
  @include button-variant(white, $blue-new1, $blue-new1);
}

button.green, .btn-green {
  @include button-variant(white, $green, $green);
}

button.green, .btn-green-light {
  @include button-variant(white, $green-light, $green-light);
}

button.green-darken, .btn-green-darken {
  @include button-variant(white, $green-darken, $green-darken);
}

button.green-alt, .btn-green-alt {
  @include button-variant(white, $green, white);
}

button.green-new1, .btn-green-new1 {
  @include button-variant(white, $green-new1, $green-new1);
}

button.orange, .btn-orange {
  @include button-variant(white, $orange, $orange);
}

button.orange-alt, .btn-orange-alt {
  @include button-variant(white, $orange, white);
}

button.yellow, .btn-yellow {
  @include button-variant(white, $yellow, $yellow);
}

button.yellow-light, .btn-yellow-light {
  @include button-variant(white, $yellow-light, $yellow-light);
}

button.red, .btn-red {
  @include button-variant(white, $red, $red);
}

button.red, .btn-red-light {
  @include button-variant(white, $red-light, $red-light);
}

button.red-alt, .btn-red-alt {
  @include button-variant(white, $red, white);
}

button.red-lighter, .btn-red-lighter {
  @include button-variant(white, $red-lighter, white);
}

button.red-new, .btn-red-new {
  @include button-variant(white, $red-new, $red-new);
}

button.pink, .btn-pink{
  @include button-variant(white, $pink, white);
}

button.aqua, .btn-aqua {
  @include button-variant(white, $aqua, $aqua);
}

button.aqua-alt, .btn-aqua-alt {
  @include button-variant(white, $aqua, white);
}

button.black, .btn-black {
  @include button-variant(white, $gray-darker, $gray-darker);
}

button.btn-transparent-blue, .btn-transparent-blue {
  @include button-variant($blue, transparent, $blue);
}

button.silver, .btn-silver {
  @include button-variant(#666, $gray-lighter, $gray-lighter);
}

button.btn-transparent-silver, .btn-transparent-silver {
  @include button-variant($gray-darker, transparent, $gray-light);
}

button.btn-transparent-silver2, .btn-transparent-silver2 {
  @include button-variant($gray-darker, transparent, #E0DEDC);
}

@mixin buttonsTransparent($name, $color) {
  .btn-#{$name}-transp, button.#{$name}-transp {
    color: white;
    border-color: $color;
    &:hover {
      background-color: $color;
      color: white;
    }
    &:focus {
      color: white;
    }
  }
}

@include buttonsTransparent(red, $red-lighter);
@include buttonsTransparent(blue, $blue);
@include buttonsTransparent(blue-light, $blue-light);