h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-cond;
    //font-weight: bold;
    color: $gray-darker;
    margin-top: 0;
}


h1, .h1 {
    font-size: 40px - 10;
}
h2, .h2 {
    font-size: 36px - 8;
}
h3, .h3 {
    font-size: 28px - 6;
}
h4, .h4 {
    font-size: 22px - 4;
}

@include breakpoint($tablet) {
    h1, .h1 {
        font-size: 40px;
    }
    h2, .h2 {
        font-size: 36px;
    }
    h3, .h3 {
        font-size: 28px;
    }
    h4, .h4 {
        font-size: 22px;
    }
}