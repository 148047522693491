
/**
 *  Master Slider
 *  Black 2 Skin
 */
/* controls */
/* retina */
.ms-skin-black-2 {
  .ms-slide {
    .ms-slide-vpbtn {
      width: 45px;
      height: 45px;
      background: url(black-skin-2.png) black no-repeat -16px -103px;
      border: solid 3px rgb(255, 255, 255);
      border-color: rgba(255, 255, 255, 0.4);
      -webkit-background-clip: padding-box;
      top: 50%;
      left: 50%;
      margin: -23px 0 0 -23px;
      &:hover {
        background-color: #3D3D3D;
      }
    }
    .ms-slide-vcbtn {
      width: 25px;
      height: 25px;
      background: url(black-skin-2.png) black no-repeat -27px -36px;
      border: solid 2px white;
      border-color: rgba(255, 255, 255, 0.4);
      top: 15px;
      right: 15px;
    }
  }
  .ms-video-btn {
    width: 45px;
    height: 45px;
    background: url(black-skin-2.png) black no-repeat -16px -103px;
    border: solid 3px rgb(255, 255, 255);
    border-color: rgba(255, 255, 255, 0.4);
    -webkit-background-clip: padding-box;
    top: 50%;
    left: 50%;
    margin: -23px 0 0 -23px;
    &:hover {
      background-color: #3D3D3D;
    }
  }
  .ms-nav-next {
    position: absolute;
    cursor: pointer;
    z-index: 110;
    width: 45px;
    height: 45px;
    background: url(black-skin-2.png) black;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    background-position: -88px -26px;
    border: solid 3px rgb(255, 255, 255);
    border-color: rgba(255, 255, 255, 0.4);
    top: 50%;
    left: 30px;
    margin-top: -22px;
    right: 30px;
    left: auto;
    background-position: -86px -103px;
    &:hover {
      background-color: #3d3d3d;
    }
  }
  .ms-nav-prev {
    position: absolute;
    cursor: pointer;
    z-index: 110;
    width: 45px;
    height: 45px;
    background: url(black-skin-2.png) black;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    background-position: -88px -26px;
    border: solid 3px rgb(255, 255, 255);
    border-color: rgba(255, 255, 255, 0.4);
    top: 50%;
    left: 30px;
    margin-top: -22px;
    &:hover {
      background-color: #3d3d3d;
    }
  }
  .ms-bullets.ms-dir-h {
    position: absolute;
    bottom: 20px;
    right: 50%;
    width: 100px;
    .ms-bullets-count {
      position: relative;
      right: -50%;
    }
  }
  .ms-bullet {
    //default css // width: 8px;
    // height: 8px;
    // background: white;
    // -webkit-background-clip: padding-box;
    // -moz-background-clip: padding-box;
    // -ms-background-clip: padding-box;
    // border: solid 3px white;
    // border-color: rgba(0, 0, 0, 0.44);
    // margin: 4px;
    // background-position: -34px -117px;
    //krojac css width: 20px;
    height: 6px;
    background-color: #A3E0C7;
    border-radius: 5px;
  }
  .ms-bullet-selected {
    //default css // background-color: black;
    // border-color: white;
    // border-color: rgba(255, 255, 255, 0.44);
    //krojac css background-color: #87B9A7;
  }
  .ms-time-bar {
    height: 3px;
    background: #000000;
  }
  .ms-tooltip {
    position: absolute;
    z-index: 210;
    cursor: auto;
    max-width: 200px;
  }
  .ms-tooltip-top {
    .ms-tooltip-arrow {
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      border-top: solid 10px;
      border-top-color: silver;
      border-top-color: rgba(240, 240, 240, 0.88);
      bottom: -10px;
    }
  }
  .ms-tooltip-bottom {
    .ms-tooltip-arrow {
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      border-bottom: solid 10px;
      border-bottom-color: silver;
      border-bottom-color: rgba(240, 240, 240, 0.88);
      top: -10px;
    }
  }
  .ms-tooltip-right {
    .ms-tooltip-arrow {
      border-top: solid 10px transparent;
      border-bottom: solid 10px transparent;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-right: solid 10px;
      border-right-color: silver;
      border-right-color: rgba(240, 240, 240, 0.88);
      left: -10px;
    }
  }
  .ms-tooltip-left {
    .ms-tooltip-arrow {
      border-top: solid 10px transparent;
      border-bottom: solid 10px transparent;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-left: solid 10px;
      border-left-color: silver;
      border-left-color: rgba(240, 240, 240, 0.88);
      right: -10px;
    }
  }
  .ms-tooltip-point {
    cursor: pointer;
    .ms-point-center {
      width: 12px;
      height: 12px;
      border-radius: 15px;
      border: solid 2px #BBB;
      background: white;
      margin: -8px 0 0 -8px;
      position: absolute;
      z-index: 105;
    }
    .ms-point-border {
      width: 22px;
      height: 22px;
      background: black;
      background: rgba(0, 0, 0, 0.36);
      margin: -11px 0 0 -11px;
      position: absolute;
      border-radius: 15px;
      animation: point-anim 1.5s infinite;
      -moz-animation: point-anim 1.5s infinite;
      -webkit-animation: point-anim 1.5s infinite;
      -o-animation: point-anim 1.5s infinite;
    }
  }
  .ms-tooltip-cont {
    padding: 10px;
    background: silver;
    background: rgba(240, 240, 240, 0.88);
    text-shadow: 0 1px 0px white;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04);
  }
}
.ms-skin-black-2.round-skin {
  .ms-slide {
    .ms-slide-vcbtn {
      border-radius: 50px;
    }
    .ms-slide-vpbtn {
      border-radius: 90px;
    }
  }
  .ms-nav-next {
    border-radius: 90px;
  }
  .ms-nav-prev {
    border-radius: 90px;
  }
  .ms-video-btn {
    border-radius: 90px;
  }
  .ms-bullet {
    border-radius: 15px;
  }
}
.ms-ie7.ms-skin-black-2 {
  .ms-tooltip-point {
    .ms-point-border {
      display: none;
    }
  }
}
.ms-ie8.ms-skin-black-2 {
  .ms-tooltip-point {
    .ms-point-border {
      display: none;
    }
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .ms-skin-black-2 {
    .ms-slide {
      .ms-slide-vpbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
      .ms-slide-vcbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
    }
    .ms-video-btn {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-next {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-prev {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
  }
}
@media only screen and ( min--moz-device-pixel-ratio: 2) {
  .ms-skin-black-2 {
    .ms-slide {
      .ms-slide-vpbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
      .ms-slide-vcbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
    }
    .ms-video-btn {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-next {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-prev {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
  }
}
@media only screen and ( -o-min-device-pixel-ratio: 2/1) {
  .ms-skin-black-2 {
    .ms-slide {
      .ms-slide-vpbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
      .ms-slide-vcbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
    }
    .ms-video-btn {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-next {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-prev {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
  }
}
@media only screen and ( min-device-pixel-ratio: 2) {
  .ms-skin-black-2 {
    .ms-slide {
      .ms-slide-vpbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
      .ms-slide-vcbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
    }
    .ms-video-btn {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-next {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-prev {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
  }
}
@media only screen and ( min-resolution: 192dpi) {
  .ms-skin-black-2 {
    .ms-slide {
      .ms-slide-vpbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
      .ms-slide-vcbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
    }
    .ms-video-btn {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-next {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-prev {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
  }
}
@media only screen and ( min-resolution: 2dppx) {
  .ms-skin-black-2 {
    .ms-slide {
      .ms-slide-vpbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
      .ms-slide-vcbtn {
        background-image: url(black-skin-2-retina.png);
        background-size: 152px 225px;
      }
    }
    .ms-video-btn {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-next {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
    .ms-nav-prev {
      background-image: url(black-skin-2-retina.png);
      background-size: 152px 225px;
    }
  }
}
