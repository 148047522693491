.relative {
  position: relative;
  padding-top: 67px;
  .arrow-separator {
    position: absolute;
    left: 50%;
    top: -57px;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    .holder-circle-main {
      background-color: $gray-lighter;
      @include avatar-img(114px);
      line-height: normal;
      position: relative;
      .holder-circle-second {
        @include avatar-img(60px);
        background-color: $yellow;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        i {
          color: white;
          font-size: 40px;
        }
      }
    }
    &.white {
      .holder-circle-main {
        background-color: white;
      }
    }
  }
}
[class^='block-'] {
  >.relative {
    margin-top: -30px;
    padding-top: 70px;
    @include breakpoint($desktop) {
      margin-top: -80px;
    }
  }
}
.main-container {
  >.relative {
    margin-top: -25px;
    @include breakpoint($tablet) {
      margin-top: -50px;
    }
  }
}