@mixin outline($color: aqua, $style: dotted, $offset: -1px) {
  outline: 1px $style $color;
  outline-offset: $offset;
}

body.env-local {
  &:after {
    content: "MOBILE";
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 6px 12px;
    background-color: white;
    color: #000;
    opacity: .5;
    @include breakpoint($mobile) {
      content: "MOBILE LANDSCAPE";
    }
    @include breakpoint($tablet) {
      content: "TABLET";
    }
    @include breakpoint($desktop) {
      content: "DESKTOP";
    }
    @include breakpoint($desktop-large) {
      content: "LARGE DESKTOP";
    }
  }
}

.dn {
  display: none;
}

.bold {
  font-weight: bold;
}

.pull-md-left {
  @include breakpoint($desktop) {
    float: left !important;
  }
}
.pull-sm-left {
  @include breakpoint($tablet) {
    float: left !important;
  }
}
.pull-md-right {
  @include breakpoint($desktop) {
    float: right !important;
  }
}
.pull-sm-right {
  @include breakpoint($tablet) {
    float: right !important;
  }
}

@mixin reset-shadow() {
  box-shadow: none;
}

@mixin helper-generator($class, $attribute) {
  @for $i from 0 through 10 {
    $i: $i * 5;
    .#{$class}#{$i} {
      #{$attribute}: #{$i}px !important;
    }
  }
}

// Margin
@include helper-generator(ma, margin);
@include helper-generator(mt, margin-top);
@include helper-generator(mr, margin-right);
@include helper-generator(mb, margin-bottom);
@include helper-generator(ml, margin-left);

// Padding
@include helper-generator(pa, padding);
@include helper-generator(pt, padding-top);
@include helper-generator(pr, padding-right);
@include helper-generator(pb, padding-bottom);
@include helper-generator(pl, padding-left);

// css for Mozilla
@-moz-document url-prefix() {
  .img-responsive {
    width: 100%;
  }
}

// css for IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .img-responsive {
    width: 100%;
  }
}