@mixin alert($color) {
    background-color: $color;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    .container {
        @extend .clearfix;
        position: relative;
        .content {
            float: left;;
            width: calc(100% - 35px);
            @include breakpoint($mobile) {
                width: calc(100% - 115px);
            }
            .title {
                margin-top: 10px;
                font-size: 22px;
                text-transform: none;
                color: white;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }
            ul {
                @extend .list-unstyled;
                li {
                    margin: 5px 0;
                }
            }
        }
        span[class^="ksi-"] {
            color: darken($color, 20%);
            float: left;
            display: block;
            font-size: 60px;
            margin-right: 30px;
        }
    }
    .close {
        position: absolute;
        right: 15px;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 30px;
        width: 40px;
        padding: 5px;
    }
}

.alert-error {
    @include alert($red-light);
}
.alert-success {
    @include alert($green-light);
}
.alert-info {
    @include alert($aqua-light);
}
.alert-warning {
    @include alert($orange-light);
}



.admin-alert {
    @extend .clearfix;
    margin-bottom: 25px;
    padding: 20px;
    span {
        color: white;
        line-height: 30px;
        display: block;
        text-align: center;
        margin-bottom: 10px;
        font-size: 16px;
        @include breakpoint($mobile) {
            display: inline-block;
            margin-bottom: 0;
            font-size: 20px;
        }
    }
    a {
        display: table;
        margin: auto;
        @include breakpoint($mobile) {
            float: right;
        }
    }
}