.typing-game {
  &.PlayerInfo {
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .game-result-holder {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 49;
      }
    }
  }
}

#user-panel {
  &.typing-game {
    textarea {
      height: 80%;
    }
  }
}

.user-scores {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  .user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: $font-family-goth;
    margin-bottom: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include breakpoint(0 1699px) {
      &:nth-child(n+7) {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: 0;

    }
    .place {
      font-size: 29px;
      color: white;
      min-width: 60px;
      display: inline-block;
    }
    .user-avatar {
      width: 42px;
      height: 42px;
      margin-right: 10px;
      .student-icons {
        &.w100, &.w50 {
          width: 42px;
          height: 42px;
          line-height: 42px;
          img {
            width: 25px;
          }
        }
      }
    }
    .user-name-time {
      .name {
        font-size: 16px;
        color: white;
        font-family: $font-family-goth;
        margin-bottom: 5px;
      }
      .time {
        font-size: 16px;
        color: black;
      }
    }
  }
  .score {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .user {
      .place {
        min-width: 50px;
      }
    }
  }
}

.your-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .infoHolder {
    margin-right: 50px;
    h3 {
      font-family: $font-family-goth;
      font-size: 26px;
      text-transform: uppercase;
      color: white;
    }
  }
}

.textare-code, .user-code {
  width: 50%;
}

.textare-code {
  background-color: #EBEBEB;
  pre {
    color: #333;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: $font-family-cond;
    padding: 0 0 0 65px;
    width: 90%;
    margin-top: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include breakpoint(1500px) {
      margin-top: 65px;
    }
  }
}

.user-code {
  background-color: black;
  position: relative;
  overflow: hidden;
  textarea {
    width: 60%;
    padding: 0 0 0 70px;
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    color: #CCCCCC;
    overflow: hidden;
  }
}

.game-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(30vh - 86px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  @include breakpoint(1500px) {
    height: calc(20vh - 86px);
  }
}

.result-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(70vh - 86px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  background-color: #33C9AF;
  opacity: 1;
  overflow: visible;
  @include breakpoint(1500px) {
    height: calc(80vh - 86px);
  }
  .main-container {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
  }
}

.points-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h3 {
    margin-right: 15px;
  }
  .listBtn {
    min-width: 90px;
    height: 30px;
    padding: 4px 10px;
    background-color: #33C9AF;
    border-color: #33C9AF;
    @include breakpoint(1500px) {
      min-width: 110px;
      padding: 4px 15px;
    }
  }
}

.onlyGame {
  .game-holder {
    height: calc(100vh - 172px);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
  }
  .result-holder {
    opacity: 0;
    overflow: hidden;
    height: 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
  }
}

.relative-parrent {
  width: 100%;
  position: relative;
  .close-top-list {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

.codeHeader {
  height: 70px;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  @include breakpoint(1500px) {
    height: 100px;
    padding-left: 65px;
    padding-right: 20px;
  }
  h3 {
    font-family: $font-family-goth;
    font-size: 16px;
    color: #F9B971;
    margin-bottom: 0;
    .place {
      color: #95C6EA;
      margin-left: 5px;
      margin-right: 5px;
    }
    .time {
      color: white;
    }
  }
  .start {
    .timeCount {
      position: relative;
      top: 1px;
      margin-right: 5px;
      font-size: 20px;
      color: white;
      font-family: $font-family-goth;
      @include breakpoint(1500px) {
        position: static;
        margin-right: 30px;
        font-size: 30px;
      }
    }
    button {
      position: relative;
      top: -1px;
      @include breakpoint(0 1499px) {
        min-width: 90px;
        height: 30px;
        padding: 3px 20px;
        font-size: 14px;
      }
      @include breakpoint(1500px) {
        top: -6px;
      }
    }
  }
}

.finishGameInfoBox {
  position: absolute;
  z-index: 50;
  top: 80px;
  right: 10px;
  width: 290px;
  padding: 10px;
  border-radius: 7px;
  background-color: #5DD5E5;
  -webkit-box-shadow: 0 0 4px 0 rgba(102, 102, 102, 1);
  box-shadow: 0 0 4px 0 rgba(102, 102, 102, 1);
  display: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: $font-family-goth;
  -webkit-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  @include breakpoint($mobile) {
    width: 350px;
    right: 20px;
    top: 100px;
  }
  @include breakpoint($tablet) {
    top: 80px;
    right: 20px;
    width: 420px;
    padding: 15px 45px 20px 15px;
  }
  h2 {
    font-size: 18px;
    font-family: $font-family-goth;
    margin-bottom: 10px;
    color: white;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 20px;
    color: #574D70;
    font-size: 16px;
    .not-time {
      margin-left: 5px;
    }
  }
  .img-holder {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 5px;
    img {
      max-width: 80px;
    }
  }
  .info-holder {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .buttonHolder {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      a {
        min-width: 110px;
        height: 30px;
        padding: 3px 11px;
      }
      .againBtn {
        background-color: #3FC0DD;
        border-color: #3FC0DD;
        &:hover {
          background-color: darken(#3FC0DD, 10);
        }
      }
    }
  }
  &.gameEnd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.game-result-holder:not(.onlyGame) {
  .user-code {
    overflow: visible;
  }
  .textare-code {
    overflow: hidden;
    pre {
      margin-top: 10px;
    }
  }
  textarea {
    height: 10% !important;
  }
  .gameEnd {
    top: -70px;
    @include breakpoint(1500px) {
      top: -150px;
    }
  }
}
