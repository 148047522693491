.skills {
    @extend .list-unstyled;
    @extend .clearfix;
    margin-bottom: 30px;
    margin-top: 30px;
    li {
        display: inline-block;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 8px 14px;
        background-color: $gray-light;
        font-size: 16px;
        color: $gray-dark;
        font-family: $font-family-cond;
        &:last-child {
            margin-right: 0;
        }
    }
}

.instructor-page {
    .title {
        @include breakpoint($tablet) {
            font-size: 40px;
        }
        font-size: 30px;
    }
    .about {
        text-align: center;
        margin-bottom: 50px;
        color: $gray-dark;
        .cke-editable {
            width: 70%;
        }
    }
    .block-white {
        .title {
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }
    main {
        .user-avatar {
            margin: auto;
            text-align: center;
            width: 95px;
            height: 95px;
            font-size: 60px;
            padding-top: 5px;
            @include border-radius(50%);
            @include breakpoint($tablet) {
                margin: 0;
            }
            @include breakpoint($desktop-large) {
                width: 140px;
                height: 140px;
                font-size: 80px;
                padding-top: 15px;
            }
            &.background {
                background-color: $gray-lighter;
            }
        }
    }
    @include breakpoint(0 990px) {
        .section-video {
            & + .block-white {
                padding-bottom: 60px;
            }
        }
    }
    @include breakpoint($tablet 1199px) {
        .col-sm-6.col-lg-4 {
            &:nth-child(2n+1) {
                clear: both;
            }
        }
    }
    @include breakpoint($desktop-large) {
        .col-sm-6.col-lg-4 {
            &:nth-child(3n+1) {
                clear: both;
            }
        }
    }
    .multi-badge + .escape {
        .action-price {
            top: 25px;
            @include breakpoint($mobile) {
                top: 25px;
            }
            @include breakpoint($tablet) {
                top: 30px;
            }
            @include breakpoint($desktop) {
                top: 40px;
            }
            @include breakpoint($desktop-large) {
                top: 35px;

            }
        }
    }
    .course-archive {
        .holder {
            .hover {
                .detail-holder {
                    top: 32%;
                }
            }
        }
    }
}

.ks-logo {
    display: inline-block;
    @include breakpoint($tablet) {
        margin-left: 5px;
    }
    svg {
        width: 130px;
    }
}

.sub-title-img {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
}