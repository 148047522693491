////
/// Fonts
//


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype'),
  url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto-Bold.ttf') format('truetype'),
  url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Regular.eot');
  src: url('../fonts/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoCondensed-Regular.woff2') format('woff2'),
  url('../fonts/RobotoCondensed-Regular.woff') format('woff'),
  url('../fonts/RobotoCondensed-Regular.ttf') format('truetype'),
  url('../fonts/RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Bold.eot');
  src: url('../fonts/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'),
  url('../fonts/RobotoCondensed-Bold.woff') format('woff'),
  url('../fonts/RobotoCondensed-Bold.ttf') format('truetype'),
  url('../fonts/RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/gotham-book/Gotham-Book.eot?#iefix') format('embedded-opentype'),  url('../fonts/gotham-book/Gotham-Book.otf')  format('opentype'),
  url('../fonts/gotham-book/Gotham-Book.woff') format('woff'), url('../fonts/gotham-book/Gotham-Book.ttf')  format('truetype'), url('../fonts/gotham-book/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Ultra';
  src: url('../fonts/gotham-ultra/Gotham-Ultra.eot');
  src: url('../fonts/gotham-ultra/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-ultra/Gotham-Ultra.woff') format('woff'),
  url('../fonts/gotham-ultra/Gotham-Ultra.ttf') format('truetype'),
  url('../fonts/gotham-ultra/Gotham-Ultra.svg#Gotham-Ultra') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Thin';
  src: url('../fonts/gotham-thin/Gotham-Thin.eot?#iefix') format('embedded-opentype'),  url('../fonts/gotham-thin/Gotham-Thin.otf')  format('opentype'),
  url('../fonts/gotham-thin/Gotham-Thin.woff') format('woff'), url('../fonts/gotham-thin/Gotham-Thin.ttf')  format('truetype'), url('../fonts/gotham-thin/Gotham-Thin.svg#Gotham-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-family-base: 'Roboto';
$font-family-cond: 'Roboto Condensed';
$font-family-goth-book: 'Gotham-Book';
$font-family-goth: 'Gotham-Ultra';
$font-family-goth-thin: 'Gotham-Thin';

// test css za fonts.blade file

.roboto {
  font-family: $font-family-base;
  &.bold {
    font-weight: bold;
  }
}
.roboto-cond {
  font-family: $font-family-cond;
  &.con-bold {
    font-weight: bold;
  }
}
