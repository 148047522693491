.calendar-activity {
  .badge-holder {
    max-width: 110px;
    margin: 0 0 20px 0 ;
    @include breakpoint($tablet) {
      max-width: 150px;
      margin: 0 0 0 auto;
    }
  }
  @include breakpoint($tablet) {
    .customFlex {
      display: flex;
      .customFlexItem {
        display: flex;
        align-items: center;
      }
    }
  }
  #logovanja {
    margin-top: 25px;
    margin-bottom: 30px;
    @include breakpoint($tablet) {
      margin-bottom: 70px;
    }
  }
}