@mixin sub-header($color) {
    background-color: $color;
    height: 50px;
    padding: 10px 0;
    .container {
        @extend .clearfix;
        .sub-left {
            float: left;
            @include breakpoint($desktop) {
                .breadcrumb {
                    @include list-unstyled;
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                    line-height: 30px;
                    li {
                        a {
                            color: $white;
                            font-size: 16px;
                            font-family: $font-family-cond;
                            text-transform: uppercase;
                            text-decoration: none;
                        }
                        &.active {
                            color: lighten($color, 40%);
                            font-size: 16px;
                            font-family: $font-family-cond;
                            text-transform: uppercase;
                        }
                        & + li {
                            &:before {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .sub-right {
            float: right;
            @extend .clearfix;
            .title {
                    display: block;
                    float: left;
                    margin: 0;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $white;
                    font-family: $font-family-cond;
                    line-height: 30px;
            }
        }
    }
}

.social {
    @include social(30px);
    &.fb {
        margin-right: 3px;
        margin-left: 8px;
    }
}

.sub-header-aqua {
    @include sub-header(darken($blue-dark, 3%));
}
.sub-header-blue {
    @include sub-header($blue-light);
}

.sub-header-red {
    @include sub-header($red-light);
}