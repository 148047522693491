.badges-more-bullets {
  @extend .list-unstyled;
  display: table;
  margin: 0 auto 5px;
  li {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #E2E2E2;
    text-indent: -9999px;
    &:not(:last-child) {
      margin-right: 3px;
    }
  }
}

.badges-conquer-page {
  font-family: $font-family-cond;
  .custom-col {
    @include breakpoint(0 543px) {
      width: 50%;
      float: left;
    }
    @include breakpoint($mobile 767px) {
      width: 33.3%;
      float: left;
    }
  }
  .col-clear {
    @include breakpoint(0 543px) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }
    @include breakpoint($mobile 991px) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
    @include breakpoint($desktop 1199px) {
      &:nth-child(4n+1) {
        clear: both;
      }
    }
    @include breakpoint($desktop-large) {
      &:nth-child(6n+1) {
        clear: both;
      }
    }
    @include breakpoint($tablet) {
      &:nth-child(6n+1) {
        clear: both;
      }
    }
  }
  .cmb30 {
    margin-bottom: 30px;
  }
  .badges-conquer-title {
    margin-bottom: 40px;
    @include breakpoint($desktop) {
      padding-left: 15px;
    }
  }

}

.badges-conquer-title {
  .svg-badge {
    margin-right: 5px;
    svg {
      width: 20px;
      height: 26px;
    }
  }
  .svg-badge {
    .cls-3 {
      fill: #ffc021;
    }
  }
  .user-total-points {
    font-family: $font-family-goth;
    font-size: 34px;
    color: #0DA6BE;
    text-transform: none;
  }
  p {
    font-size: 16px;
    color: $gray-darker;
  }
}

.badge-area-title {
  line-height: 100px;
  font-size: 30px;
  font-family: $font-family-goth;
  color: $gray-darker;
}

.conquer-badge {
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  .badge-holder {
    margin-bottom: 10px;
    img {
      margin: 0 auto;
      width: 100px;
    }
  }
  .badge-name {
    font-size: 16px;
    color: $gray-darker;
    display: block;
  }
  .badge-points {
    font-size: 18px;
    color: $blue-dark;
    font-family: $font-family-goth;
    display: block;
  }
  &.disable {
    opacity: .4;
    user-select: none;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    .badge-holder {
      img {
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
      }
    }
    &:hover {
      opacity: 1;
      .badge-holder {
        img {
          -webkit-transform: scale(1.05);
          -ms-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
  }
}

.user-points {
  .dd-menu-holder {
    position: absolute;
    top: 24px;
    right: 9999px;
    padding-top: 30px;
    transition: padding-top .3s;
    .user-dd-menu {
      background-color: white;
      padding-bottom: 25px;
      width: 280px;
      @include box-shadow(0px 1px 2px transparentize(#595959, 0.35%));
      z-index: 110;
      &:after {
        @extend %dd-menu-arr-up;
        top: -20px;
        right: 40px;
        transition: top .3s;
      }
      > a.btn {
        width: calc(100% - 50px);
        margin: 0 auto;
      }
    }
  }
  &.open {
    .dd-menu-holder {
      padding-top: 50px;
      transition: padding-top .3s;
      left: -85px;
      .user-dd-menu {
        //box-shadow: 0px 1px 2px rgba(89,89,89,.6);
        position: relative;
        &:after {
          top: -20px;
          transition: top .3s;
          right: 61px;
        }
      }
      @include breakpoint($mobile) {
        left: -190px;
      }
    }
  }
  .dd-menu-holder {
    .user-dd-menu {
      width: 280px;
      padding: 20px;
      .badges-conquer-title {
        padding: 0;
        margin-bottom: 15px;
        p {
          font-size: 14px;
          text-transform: none;
        }
      }
      .badges-holder {
        @extend .clearfix;
        @extend .list-unstyled;
        margin-bottom: 20px;
        li {
          width: 25%;
          float: left;
          padding: 0 5px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      .dd-footer {
        a {
          color: $gray-darker;
        }
      }
    }
  }
}

