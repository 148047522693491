.about-page {
  .section-instructor {
    .col-xs-6.col-sm-3 {
      @include breakpoint(0 767px) {
        &:nth-child(2n+1) {
          clear: both;
        }
      }
      @include breakpoint($tablet) {
        &:nth-child(4n+1) {
          clear: both;
        }
      }
    }
    .icon-holder {
      @include avatar-img(115px);
      background-color: $gray-lighter;
      @include breakpoint($mobile) {
        @include avatar-img(165px);
      }
    }
  }
  @include breakpoint(0 990px) {
    .main-container.pt30 + .block-white {
      padding-bottom: 60px;
    }
  }
  .cover {
    overflow: hidden;
    width: 100%;
    height: 500px;
    video {
      min-height: 100%;
      min-width: 100%;
      object-fit: contain;
      background-position: center center;
      display: inline-block;
      position: relative;
      top: -100px;
    }
  }
}

.about {
  text-align: center;
  margin-bottom: 50px;
  .cke-editable {
    width: 90%;
    margin: 0 auto 50px;
    @include breakpoint($desktop) {
      width: 70%;
    }
  }
  .reward {
    text-align: center;
    img {
      margin: 0 auto 10px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      font-size: 12px;

    }
  }
  .col-xs-6.col-sm-2 {
    @include breakpoint(0 767px) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }
}