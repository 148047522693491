.popup-userBadges {
  position: fixed;
  z-index: 1005;
  top: 80px;
  right: 10px;
  width: 290px;
  padding: 10px;
  border-radius: 7px;
  background-color: white;
  -webkit-box-shadow: 0 0 2px 0 #333;
  box-shadow: 0 0 2px 0 #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transform: translateX(500px);
  -ms-transform: translateX(500px);
  transform: translateX(500px);
  text-decoration: none !important;
  @include breakpoint($mobile) {
    width: 350px;
    right: 20px;
    top: 100px;
  }
  @include breakpoint($tablet) {
    top: 150px;
    right: 40px;
    width: 420px;
    padding: 15px;
  }
  h2, h3 {
    font-size: 16px;

    @include breakpoint($desktop) {
      font-size: 18px;
    }
  }
  h2 {
    font-family: $font-family-goth;
    margin-bottom: 15px;
    color: $blue-dark;
  }
  h3 {
    color: $gray-dark;
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    font-family: $font-family-cond;
    margin: 0;
    color: $gray-dark;
    font-size: 14px;

    @include breakpoint($desktop) {
      font-size: 16px;
    }
  }
  .img-holder {
    margin-right: 10px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    @include breakpoint($tablet) {
      margin-right: 20px;
    }
    img {
      max-width: 70px;
      @include breakpoint($mobile) {
        max-width: 90px;
      }
      @include breakpoint($tablet) {
        max-width: 120px;
      }
    }
  }
  .info-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  &.popupShowOn {
    -webkit-animation: animationPUB 6s 1 ease-in-out;
    animation: animationPUB 6s 1 ease-in-out;
  }
  &.badgePopup {
    h2,h3 {
      font-size: 16px;
    }
    h2 {
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
    }
  }
}

.popup-userBadges {
  &.notifications-popup {
    h2 {
      margin-bottom: 5px;
    }
    h2, p {
      color: white;
    }
    p {
      font-size: 16px;

      @include breakpoint($desktop) {
        font-size: 18px;
      }
    }
    .img-holder {
      margin-right: 0;
      img {
        max-width: 60px;

        @include breakpoint($desktop) {
          max-width: 80px;
        }
      }
    }
    .info-holder {
      -webkit-box-flex: 3;
      -ms-flex: 3;
      flex: 3;
    }
    &.xp-5 {
      background-color: #F9B54C;
    }
    &.unsuccess {
      background-color: #F25262;
    }
    &.success {
      background-color: #33C9AF;
    }
    &.extra-price {
      background-color: #EA483C;
      z-index: 50;
      .info-holder {
        h2 {
          font-family: $font-family-goth;
          font-size: 24px;
          text-transform: uppercase;
          span {
            font-size: 18px;
            font-family: $font-family-goth;
          }
        }
      }
      &.popupShowOn {
        @include breakpoint(0 767px) {
          -webkit-animation: animationPUB 5s 1 ease-in-out;
          animation: animationPUB 5s 1 ease-in-out;
        }
        @include breakpoint($tablet) {
          -webkit-animation: animationPUB2 6s 1 ease-in-out forwards;
          animation: animationPUB2 6s 1 ease-in-out forwards;
        }
      }
      .img-holder {
        img {
          @include breakpoint(0 991px) {
            max-width: 60px;
          }
        }
      }
    }
  }

  @-webkit-keyframes animationPUB {
    0% {
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
    7% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    95% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
  }

  @keyframes animationPUB {
    0% {
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
    7% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    95% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
  }

  @-webkit-keyframes animationPUB2 {
    0% {
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
    7% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes animationPUB2 {
    0% {
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
    7% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}

