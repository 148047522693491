#homepageSlider {
  display: none;
  @include breakpoint($desktop-large) {
    display: block;
  }
  a {
    display: block;
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: transparentize(white, .9);
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
    &:hover {
      &:after {
        display: block;
      }
    }
  }
  .carousel-indicators {
    bottom: 4px;
    li {
      width: 20px;
      height: 6px;
      @include border-radius(3px);
      background-color: #0596AF;
      margin: 0;
      border: none;
      &.active {
        background-color: #0A7D89;
      }
    }
  }
}