@mixin create-layout($bg-color, $input-bg, $input-placeholder-color, $footer-border-color) {
    background-color: $bg-color;
    color: white;

    // Inputs
    .form-control {
        background-color: $input-bg;
        border-color: $input-bg;
        color: white;
        @include placeholder($input-placeholder-color);
        &:focus {
            border-color: white;
        }
    }

    main {
        h1, h2, h3, h4 {
            color: white;
        }

        // links
        a:not(.btn) {
            color: white;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: white;
                text-decoration: none;
            }

            &:focus {
                @include tab-focus;
            }
        }
    }

    .main-footer {
        border-top-color: $footer-border-color;
    }
}
