.progress {
  height: 6px;
  box-shadow: none;
  background-color: #e6e5e5;
  position: relative;
  overflow: visible;
  width: 100%;
  >span {
    position: relative;
    left: -50px;
    color: $gray-dark;
    font-size: 12px;
    top: 0;
    @include breakpoint($mobile) {
      display: none;
    }
    @include breakpoint($tablet) {
      display: block;
    }
  }
  .progress-number {
    position: absolute;
    right: -80px;
    color: $gray-darker;
    font-size: 14px;
    line-height: normal;
    top: -5px;
    width: 65px;
    font-family: $font-family-cond;
  }
  .progress-bar {
    box-shadow: none;
    background-color: $blue;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: all .4s;
  }
}