.enrollment-page {
    background-color: $green;

    .general-info {
        p {
            font-size: 16px;
        }
    }

    .info-course {
        .title {
            margin: 0;
            color: white;
            background-color: $blue-dark;
            font-size: 16px;
            text-transform: uppercase;
            padding: 13px 0;
            text-align: center;
            letter-spacing: 4px;
            font-weight: normal;
            font-family: $font-family-base;
            @include breakpoint($desktop) {
                letter-spacing: 9px;
            }
        }
        .box {
            background-color: $gray-dark;
            box-shadow: none;
            dl {
                @extend .clearfix;
                dt {
                    width: 47%;
                    text-transform: uppercase;
                    color: $aqua-dark;
                    font-family: $font-family-cond;
                    float: left;
                }
                dd {
                    border-left: 1px solid $blue-dark;
                    color: white;
                    width: 53%;
                    padding-left: 30px;
                    float: left;
                }
                &.price-list {
                    dd {
                        border-color: transparent;
                    }
                }
            }
        }
    }
    .price {
        //margin-top: 20px;
        //@include breakpoint($tablet) {
        //    margin-top: 20px;
        //}
        .user {
            text-align: center;
            @include breakpoint($tablet) {
                text-align: left;
            }
            p {
                font-size: 18px;
                font-family: $font-family-cond;
                text-transform: uppercase;
                color: $blue-dark;
                strong {
                    color: $gray-darker;
                    font-size: 22px;
                    margin-left: 0;
                    display: block;
                    @include breakpoint($tablet) {
                        margin-left: 30px;
                        display: inline-block;
                    }
                }
            }
        }
        .submit {
            overflow: hidden;
            text-align: left;
            button {
                float: left;
                margin-right: 20px;
            }
            .submit-info-container {
                padding-top: 15px;
                font-size: 16px;
                float: left;
                display: inline-block;
            }
        }
    }

    // Enrollment end page

    .enrollment-end {
        @extend .box;
        .content {
            .cke-editable {
                margin-bottom: 20px;
            }
            @extend .clearfix;
        }
    }

    main {
        color: white;
        * {
            font-family: $font-family-cond;
        }
        a {
            color: white;
            text-decoration: underline;
        }
        p {
            &.lead {
                &:first-of-type {
                    margin-bottom: 0;
                }
            }
        }
        h1, h2 {
            color: white;
        }
        .main-title {
            margin-bottom: 15px;
        }
        .form-control, .select2-container--default .select2-selection--single {
            background-color: $green-dark;
        }

        form {
            input, select, span {
                color: white !important;
                border: none !important;
                &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                    color: $green-light;
                }
                &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                    color: $green-light;
                    opacity: 1;
                }
                &::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: $green-light;
                    opacity: 1;
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $green-light;
                }
            }
            .select2-selection__arrow {
                b {
                    border-color: white transparent transparent transparent !important;
                }
            }
            .select2-container--default.select2-container--open {
                .select2-selection--single {
                    .select2-selection__arrow {
                        b {
                            border-color: transparent transparent white transparent !important;
                        }
                    }
                }
            }
        }
    }

    .static-text {
        text-align: right;
        margin-top: -15px;
        margin-bottom: 30px;
        p {
            margin-bottom: 0;
        }
    }

    .form-group {
        margin-bottom: 20px;
        label:not(.icheck-orange) {
            margin-left: 10px;
        }
    }
    .enroll-installments {
        margin-bottom: 30px;
    }
    .enroll-promocode {
        margin-bottom: 40px;
    }
    .enroll-groups {
        margin-bottom: 50px;
    }
    .promo-info {
        font-size: 16px;
        min-height: 50px;
    }
    .action-promo-code {
        background: #EA483C;
        padding: 7px 31px;
        font-size: 16px;
        display: inline-block;
        border-radius: 5px;
        margin-top: 7px;
        position: relative;
        .animation-promo {
            display: none;
            position: absolute;
            right: 0;
            bottom: 24px;
            z-index: -1;
            transform-origin: center bottom;
            animation: promoScale 1s cubic-bezier(.67,.07,0,.97) 1;
            @include breakpoint($desktop-large) {
                display: inline-block;
            }
        }
    }
}

@keyframes promoScale {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.enrollment-page-end {
    background-color: $green;
    color: white;
    .content {
        margin: 50px 0;
        @include breakpoint($desktop) {
            margin: 100px 0;
        }
        @include breakpoint($tablet 990px) {
            margin: 200px 0;
        }
        .main-title {
            color: white;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 40px;
            font-family: $font-family-goth;
            text-transform: uppercase;
            @include breakpoint($desktop) {
                margin-top: 25px;
            }
            @include breakpoint($desktop-large) {
                font-size: 60px;
            }
        }
        .lead {
            margin-bottom: 50px;
            font-size: 18px;
        }
        .clearfix.text-left {
            a {

                &:first-child {
                    margin-right: 15px;
                }
                @include breakpoint(0 543px) {
                    margin-bottom: 15px;
                }
            }
        }
        @media (max-width: 1199px) {
            img {
                width: 250px;
                margin: auto;
            }
        }
    }
}

// Promocode
.promo-col {
    _overflow: hidden;

    .enroll-promocode {
        float: left;
        input.promocode-control {
            width: 200px;
            text-transform: uppercase;
            display: inline-block;
            float: left;
        }
        button.promo-submit {
            margin-right: 20px;
            float: left;
        }
        .promo-info-container {
            padding-top: 15px;
            float: left;
        }
        .promo-fields {
            _overflow: hidden;
        }
        .promo-message-ok, .promo-message-bad {
            padding: 10px 15px;
            background: white;
            color: $green;
            display: inline-block;
            margin: 15px 0;
        }
        .promo-message-bad {
            color: $red;
        }
    }
}

.enroll-price {
    margin-bottom: 20px;
    h2 {
        margin-bottom: 0;
    }
}

.enrollment-info-box {
    margin-top: 30px;
    padding: 25px;
    background-color: $green-dark;
    @include breakpoint($desktop) {
        margin-bottom: 0;
    }
    h2,h3 {
        font-family: $font-family-goth;
        font-size: 24px;
        text-transform: uppercase;
        color: white;
    }
    h2 {
        @include breakpoint($desktop-large) {
            font-size: 50px;
        }
    }
    h3 {
        margin-bottom: 20px;
    }
    ul {
        @extend .list-unstyled;
        li {
            font-size: 16px;
            a {
                text-decoration: none;
            }
        }
    }
}

.nothingAnyPay {
    margin-top: 15px;
    float: left;
}

@include breakpoint($desktop) {
    .enrollmentFlex {
        display: flex;
        .flexElement {
            align-self: flex-end;
        }
    }
}