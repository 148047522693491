.showcase-page {
  .students-box {
    footer {
      .holder {
        @extend .clearfix;
        a {
          float: right;
        }
      }
    }
  }
  .badges {
    > li {
      a {
        color: white;
        &:hover {
          color: inherit;
        }
      }
      span {
        svg {
          margin-left: 0;
        }
      }
    }
  }
  .user-avatar {
    >img {
      width: 75px;
      height: 75px;
      line-height: 75px;
      top: 0;
      left: 0;
    }
    .student-icons {
      top: 0;
      left: 0;
      &.w100 {
        width: 75px;
        height: 75px;
        line-height: 75px;
        img {
          width: 40px;
        }
      }
    }
  }
}


