@mixin triangle($top, $left) {
    position: relative;
    &:before {
        content: " ";
        display: inline-block;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right: 10px solid #fff;
        position: absolute;
        top: $top;
        left: $left;
    }
}

@mixin trianle-up($width, $color) {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-bottom: $width solid $color;
}

@mixin trianle-right($width, $color) {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-left: $width solid $color;
}

@mixin trianle-bottom($width, $color) {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-top: $width solid $color;
}

@mixin trianle-left($width, $color) {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-right: $width solid $color;
}