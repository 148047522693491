.icheckbox_square-blue {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background-color: white;
    margin: 0 5px 0 0;
    border: 1px solid transparent;
    &.hover {
        border: 1px solid $white;
        background-color: transparent;
    }
    &.checked {
        background: rgba(250,250,250, .55);
        &:before {
            content: '';
            display: block;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: -3px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
        &.hover {
            border: 1px solid transparent;
        }
    }
}

.iradio_square-blue {
    @extend .icheckbox_square-blue;
    @include border-radius(50%);
}

.icheck-orange {
    .icheckbox_square-blue {
        border: 1px solid #ccc;
        &.hover {
            border: 1px solid $orange;
        }
        &.checked {
            background: $orange;
            border: 1px solid $orange;
        }
    }
    .iradio_square-blue {
        @extend .icheckbox_square-blue;
    }
}

[class^='icheck-'] {
    span {
        position: relative;
        top: 1px;
    }
}