.modal {
  .modal-dialog {
    z-index: 9999;
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-header {
    padding: 20px;
    border: 0;
    .modal-title {
      text-transform: uppercase;
      font-size: 30px;
    }
    &:after {
      height: 17px;
      margin-top: 13px;
      background: url("../images/svg/line.svg") repeat-x;
      width: 100%;
    }
  }
  .modal-body {
    @extend .modal-header;
    &:after {
      display: none;
    }
  }
  .modal-footer {
    @extend .modal-header;
    &:after {
      display: none;
    }
  }
  button.close {
    border-radius: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    float: right;
    span {
      position: relative;
      bottom: 1px;
    }
  }
  @include breakpoint(0 990px) {
    .modal-dialog {
      width: 99%;
    }
  }
  @include breakpoint($desktop) {
  }

  .form-control {
    border-color: $gray-light;
  }
  &#answer-box {
    .modal-dialog {
      @include breakpoint($tablet) {
        width: 70%;
      }
      @include breakpoint(1920px) {
        width: 50%;
      }
    }
  }
}

#liModalTitle {
  .course-name {
    color: $blue-light;
    font-size: 20px;
    @include breakpoint(0 767px) {
      display: block;
    }
    @include breakpoint($tablet) {
      float: right;
    }
  }
}