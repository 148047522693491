.action-countdown {
  background-color: #E0698C;
  background-color: #E0698C;
  position: relative;
  transition: all .3s;
  >a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 50px;
    p {
      margin: 0;
      color: white;
      text-align: center;
      font-size: 16px;
      span {
        font-size: 22px;
        display: block;
        font-weight: bold;
      }
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    cursor: pointer;
    img {
      width: 10px;
    }
  }
}