.cardLogos {
  padding-bottom: 0 !important;
  footer {
    position: static;
  }
}

.card-logos-holder {
  @extend .clearfix;
  background-color: white;
  padding: 10px 0;
  .group-left {
    display: table;
    margin: 0 auto;
    @include breakpoint($tablet) {
      display: block;
      margin: 0;
      float: left;
      ul {
        li {
          &:first-child {
            margin-right: 30px;
          }
        }
      }
    }
  }
  .group-right {
    display: none;
    @include breakpoint($tablet) {
      display: block;
      float: right;
    }
  }
  ul {
    @extend .list-unstyled;
    @extend .clearfix;
    margin-bottom: 0;
    li {
      float: left;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}