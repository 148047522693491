@mixin border-radius($val) {
    -webkit-border-radius: $val;
    -moz-border-radius: $val;
    border-radius: $val;
}

@mixin list-unstyled() {
    padding-left:0;
    list-style:none;
}

@mixin placeholder($color) {
    ::-webkit-input-placeholder {
        color: $color;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $color;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        color: $color;
    }
    :-ms-input-placeholder {
        color: $color;
    }
}