.profile-breadcrumbs {
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
  }
  li ,li a {
    text-transform: uppercase;
    color: $gray-darker;
    font-family: $font-family-cond;
    &.active {
      color: $gray-dark;
    }
  }
  li + li:before {
    padding: 0 1px;
    color: $gray-darker;
  }
}