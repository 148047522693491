.toggle-aside {
  position: absolute;
  background: transparent;
  border: 1px solid black;
  padding: 10px 20px;
  right: 10px;
  transition: all .4s ease-in-out;
  @include breakpoint($desktop) {
    display: none;
  }
}

@include breakpoint(0 991px) {
  .responsive-category-menu {
    &.close {
      position: fixed;
      z-index: 999;
      width: 70%;
      left: -70%;
      top: 120px;
      transition: all .4s ease-in-out;
      .bordered-list li > a, .bordered-list label {
        border-bottom: none;
        transition: all .4s ease-in-out;
        &:after {
          display: none;
          transition: all .4s ease-in-out;
        }
      }
    }
    &.open {
      position: fixed;
      z-index: 999;
      width: 70%;
      top: 120px;
      left: 0;
      transition: all .4s ease-in-out;
    }
  }
}
@include breakpoint(0 767px) {
  .responsive-category-menu {
    &.close {
      .toggle-aside {
        padding: 1px 6px;
        right: -30px;
        transition: all .4s ease-in-out;
      }
    }
  }
}

@include breakpoint($tablet 991px) {
  .responsive-category-menu {
    &.close {
      left: -65%;
      .toggle-aside {
        padding: 5px 10px;
        right: 3px;
      }
    }
  }
}

.overflow-y {
  .responsive-category-menu {
    z-index: 0;
  }
}