.user-payment {
    //background-color: $green;
    *:not([class^="message"] *) {
        font-family: $font-family-cond;
    }
    p:not([class^="message"] *) {
        font-size: 16px;
    }
    .block-green {
        .title {
            margin-bottom: 15px;
        }
        .lead {
            &:first-of-type {
                margin-bottom: 0;
            }
        }
        padding-top: 0;
        color: white;
        input, select, span {
            background-color: $green-dark;
            color: white !important;
            .select2-selection__arrow {
                b {
                    border-color: white transparent transparent transparent;
                }
            }
        }
        h2 {
            color: white;
        }

        .select2-container--default.select2-container--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    b {
                        border-color: transparent transparent white transparent !important;
                    }
                }
            }
        }
    }
    .holder {
        margin-bottom: 30px;
    }
    footer {
        .holder {
            margin-bottom: 0;
        }
    }
    .user-payments-content {
        fieldset {
            padding-top: 30px;
        }
        .custom-link {
            color: white;
            text-decoration: underline;
        }
        .static-text {
            text-align: right;
            margin-top: -15px;
            @extend .clearfix;
            label {
                float: left;
                margin: 0;
            }
            p {
                float: right;
                margin-bottom: 0;
            }
        }
        .payment-method {
            @include breakpoint($tablet) {
                margin-bottom: 70px;
            }
        }
        .company-form {
            .row {
                &:last-child {
                    margin-bottom: 50px;
                }
            }
        }
        .lead {
            margin-bottom: 0;
        }
        .desc {
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    .form-group {
        margin-bottom: 20px;
        label:not(.radio) {
            margin-left: 10px;
        }
        input {
            border: none;
        }
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        border: none;
    }
    [class^="message-"] {
        @include breakpoint($desktop) {
            .row {
                display: flex;
                align-items: center;
                .img-holder {
                    margin: 0;
                }
                h4 {
                    margin-top: 0;
                }
            }
        }
    }
}

.card-payment {
    .content {
        margin-top: 0;
    }
}

.visa-payment {
    .content {
        .main-title {
            font-size: 40px;
            text-transform: uppercase;
            font-family: $font-family-goth;
            margin-top: 20px;
            margin-bottom: 10px;
            @include breakpoint($desktop-large) {
                font-size: 60px;
                margin-top: 25px;
            }
        }
    }
    p {
        font-size: 18px;
    }
    .border-title {
        border-bottom: 2px solid white;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: inline-block;
    }
    h4 {
        font-size: 24px;
        margin-bottom: 30px;
        color: white;
    }
    .btn {
        margin-bottom: 30px;
    }
    .user-content {
        margin-bottom: 30px;
    }
    .payment-holder-info {
        padding: 30px;
        background-color: $green-dark;
        h2 {
            font-family: $font-family-goth;
            text-transform: uppercase;
            font-size: 30px;
            margin-bottom: 15px;
            color: white;
            @include breakpoint($desktop-large) {
                font-size: 60px;
            }
        }
    }
    ul {
        @extend .list-unstyled;
        li {
            font-size: 18px;
        }
    }
}

.unsuccess-payment {
    background-color: $red;
    h4 {
        color: white;
    }
    .user-content {
        margin-bottom: 30px;
        margin-top: 30px;
        a {
            color: white;
            text-decoration: underline;
        }
    }
    .content .skoljkica {
        margin-bottom: 30px;
    }
}