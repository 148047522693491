html#base_html {
    min-height: 100%;
    position: relative;
    padding-top: $header-height-mobile;
    @include breakpoint($desktop) {
        padding-top: $header-height;
        padding-bottom: $footer-height;
    }
    // Ovo sam morao da sklonim jer pod ID-jem ne mogu da pregazim
    //p {
    //    line-height: 1.2;
    //}
    a {
        cursor: pointer;
        &:focus {
            outline: none;
        }
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

html#base_user_html {
    min-height: 100%;
    position: relative;
    padding-top: $header-height-mobile;
    @include breakpoint($desktop) {
        padding-top: $header-height;
        padding-bottom: $user-footer-height;
    }
    a {
        &:focus {
            outline: none;
        }
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

body {
    background-color: $body-bg;
    font-family: $font-family-base;
    color: $gray-dark;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    aside {
        margin-bottom: 50px;
        @include breakpoint($desktop) {
            margin-bottom: 0;
        }
    }
    &.white {
        background-color: white;
    }
}