////
/// Colors
//

// Blue
$blue-dark: #22a7ca;
$blue: #38b4d6;
$blue-light: #82bfd7;
$blue-new1: #4AC0DC;
  // Aqua
$aqua-dark: #84A0A7;
$aqua: #9DBEC7;
$aqua-light: #B5DBE4;
$aqua-lighten: #C2E4E7;

  // Red
$red-dark: #AE6464;
$red: #C77272;
$red-light: #E08283;
$red-lighter: #e0839c;
$red-new: #F35958;

  // Green
$green-darken: #007A87;
$green-dark: #38938f;
$green: #45aca8;
$green-light: #81beba;
$green-lighter: #92d8d4;
$green-new1: #33C9AF;


// Orange
$orange-dark: #D17450;
$orange: #EA845B;
$orange-light: #FA8B60;
$orange-lighter: #ffa173;

// Pink
$pink: #EE6788;

// Yellow
$yellow-light: #E9C87A;
$yellow: #EAB53F;
$yellow-dark: #C0893D;

//Beige
$beige: #FFEAB6;

// Grays
$gray-darker: #333;
//$gray-dark: #666;
$gray-dark: #373D3F;
$gray: #999;
$gray-light: #eee;
$gray-lighter: #f5f5f5;


// White
$white: #fff;

// Navy
$navy: #1E2E3D;
$navy-user: #3E5068;
$navy-link: #5B79A3;

$facebook: #3C5A98;
$twitter: #00ADEE;
$linkedin: #90CADD;
$googleplus: #dc4a38;

////
/// Breakpoints
//

$mobile: 544px;
$tablet: 768px;
$desktop: 992px;
$desktop-large: 1200px;

////
/// Sizes
//

$header-height: 82px;
$header-height-mobile: 70px;
$footer-height: 460px; //150
$footer-height-mobile: 150px;
$user-footer-height: 90px;

////
/// Misc
//

///////////////////////////////////////////
/// Bootstrap override - ovde se gaze defaultne vrednosti bootstrap varijabli.
//
$brand-success: $green;
$brand-primary: $blue;
$brand-warning: $orange;
$brand-danger: $red;
$brand-info: $aqua;

$link-color:            darken($blue-dark, 10%);
$link-hover-color:      $blue;

$body-bg: #f5f5f5;

$border-radius-base: 0;

//$grid-gutter-width: 18px;
//$grid-gutter-width: 30px;
