@mixin icons-dimension($holder, $image) {
  width: $holder;
  height: $holder;
  line-height: $holder;
  img {
    width: $image;
  }
}

.student-icons {
  display: inline-block;
  @include border-radius(50%);
  text-align: center;
  img {
    height: auto;
  }
  &.w40 {
    @include icons-dimension(40px, 25px);
  }
  &.w50 {
    @include icons-dimension(50px, 30px);
  }
  &.w80 {
    @include icons-dimension(80px, 50px);
  }
  &.w100 {
    @include icons-dimension(100px, 50px);
  }
  &.male {
    background-color: $aqua-light;
  }
  &.female {
    background-color: $blue;

  }
}

.top-list-page {
  font-family: $font-family-cond;
  main {
    background: #273654;
    background-image: url("../images/top40/starsky.png"), -moz-linear-gradient(top, #273654 0%, #4567a7 100%);
    background-image: url("../images/top40/starsky.png"), -webkit-linear-gradient(top, #273654 0%, #4567a7 100%);
    background-image: url("../images/top40/starsky.png"), linear-gradient(to bottom, #273654 0%, #4567a7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#273654', endColorstr='#4567a7', GradientType=0);
    background-repeat: no-repeat;
    background-position-y: 50px;
    overflow: hidden;
    background-position-x: center;
    padding-bottom: 35%;
    @include breakpoint($desktop-large) {
      padding-bottom: 25%;
    }
    @include breakpoint($desktop-large 1650px) {
      > .main-container {
        padding-left: 10%;
      }
    }
  }

  .content-holder {
    @include breakpoint($desktop-large 1650px) {
      width: 60%;
      position: relative;
      z-index: 9;
    }
    @include breakpoint(1651px) {
      width: 50%;

    }
  }

  .page-title {
    font-size: 30px;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 5px solid rgba(32, 92, 116, 0.33);
    position: relative;
    padding-left: 190px;
    margin-bottom: 55px;
    color: white;
    @include breakpoint($desktop) {
      width: 80%;
    }
    @include breakpoint(0 990px) {
      font-size: 24px;
      padding-left: 50px;
      margin-bottom: 20px;
    }
    @include breakpoint(0 543px) {
      padding-left: 0;
    }
    @include breakpoint(0 1199px) {
      margin-bottom: 45px;
    }
    &:before {
      content: "";
      display: block;
      background: url("../images/svg/user-points.svg");
      background-size: cover;
      width: 73px;
      height: 100px;
      position: absolute;
      left: 105px;
      top: -15px;
      @include breakpoint(0 990px) {
        display: none;
      }
    }
    > span {
      position: absolute;
      bottom: -35px;
      left: 190px;
      font-size: 20px;
      text-transform: none;
      @include breakpoint(0 543px) {
        left: 0;
      }
      @include breakpoint($mobile 990px) {
        left: 50px;
      }
    }
  }

  article {
    @extend .clearfix;
    height: 60px;
    line-height: 60px;
    color: white;
    > div {
      float: left;
      &:last-child {
        float: right;
      }
    }
    .have-image {
      width: 40px;
      height: 40px;
    }
    .number {
      padding: 0 15px 0 10px;
      min-width: 60px;
      text-align: right;
      @include breakpoint(0 543px) {
        min-width: 40px;
        padding: 0 10px;
      }
    }
    .name-holder {
      padding: 0 15px;

      > span {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
      .name {
        font-size: 16px;
        @include breakpoint(0 543px) {
          font-size: 14px;
        }
        @include breakpoint(0 350px) {
          .l-name {
            display: none;
          }
        }
      }
      .best-student {
        svg {
          width: 19px;
          height: 26px;
          position: relative;
          top: 7px;
        }
        @include breakpoint(0 543px) {
          display: none;
        }
      }
      @include breakpoint(0 543px) {
        padding-left: 0;
      }
      > img, > .student-icons {
        @include breakpoint(0 400px) {
          display: none;
        }
      }
    }
    .points {
      padding: 0 50px 0 10px;
      @include breakpoint(0 543px) {
        padding: 0 10px;
      }
    }
  }
}

.list-head {
  > div {
    font-size: 18px;
    text-transform: uppercase;
  }
}

.student-list {
  &:nth-child(even) {
    background-color: rgba(32, 92, 116, 0.33);
  }
  .number {
    font-size: 26px;
    @include breakpoint(0 543px) {
      font-size: 16px;
    }
  }
  .points {
    font-size: 26px;
    color: #B8E8EC;
    min-width: 85px;
    padding-right: 10px !important;
    text-align: right;
    @include breakpoint(0 543px) {
      min-width: auto;
    }
    .disable-shadow {
      position: relative;
      top: -2px;
      margin-right: 35px;
      height: 20px;
      padding: 0 10px;
      @include breakpoint(0 543px) {
        margin-right: 5px;
      }
    }
    > span {
      position: relative;
      &:before {
        content: "";
        display: block;
        background: url("../images/svg/star.svg");
        background-size: cover;
        width: 17px;
        height: 16px;
        position: absolute;
        left: -21px;
        top: 8px;
        @include breakpoint(0 543px) {
          display: none;
        }
      }
    }
  }
  &:nth-child(1n+1) {
    clear: both;
  }
  &:nth-child(2) {
    -webkit-animation: top-student-scan 15s 1s ease 1;
    animation: top-student-scan 15s 1s ease 1;
  }
}

.moon-holder {
  position: absolute;
  left: -123px;
  top: 85px;
  display: none;
  animation: moonRotate 100s linear infinite;
  @include breakpoint($desktop-large) {
    display: block;
  }
  @include breakpoint($desktop-large 1650px) {
    left: -263px;
  }
}

.rocket-holder {
  position: absolute;
  top: 270px;
  right: 385px;
  display: none;
  -webkit-animation: rocket 15s ease-in-out 1 both;
  animation: rocket 15s ease-in-out 1 both;
  @include breakpoint($desktop-large) {
    display: block;
  }
  @include breakpoint($desktop-large 1650px) {
    right: 165px;
    width: 200px;
  }
  @include breakpoint(1450px 1650px) {
    right: 265px;
  }
}

.saturn-holder {
  position: absolute;
  top: 110px;
  right: -410px;
  display: none;
  @include breakpoint($desktop-large) {
    display: block;
  }
  @include breakpoint($desktop-large 1650px) {
    width: 400px;
    right: -230px;
  }
}

.earth-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  img {
    &:first-child {
      margin: 0 auto;
    }
  }
  [class^="sat-"] {
    position: absolute;
  }
  .sat-one {
    top: -80px;
    left: 15%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sateliteOne 15s linear infinite;
    animation: sateliteOne 15s linear infinite;
    @include breakpoint(0 543px) {
      width: 40px;
      top: -40px;
    }
    @include breakpoint($mobile 767px) {
      width: 70px;
      top: -70px;
    }
  }
  .sat-two {
    left: 50%;
    top: -170px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-animation: sateliteTwo 16s linear infinite;
    animation: sateliteTwo 16s linear infinite;
    @include breakpoint(0 543px) {
      width: 40px;
      top: -60px;
    }
    @include breakpoint($mobile 767px) {
      width: 70px;
      top: -130px;
    }
  }
  .sat-three {
    top: -70px;
    right: 10%;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation: sateliteThree 14s linear infinite;
    animation: sateliteThree 14s linear infinite;
    @include breakpoint(0 543px) {
      width: 40px;
      top: -30px;
    }
    @include breakpoint($mobile 767px) {
      width: 70px;
      top: -70px;
    }
  }
}

.animate-image-holder {
  > * {
    position: absolute;
  }
}

.spaceship-animation {
  position: relative;
  display: none;
  @include breakpoint($desktop-large) {
    display: block;
  }
  .spaceship-holder {
    position: absolute;
    top: -110px;
    left: -200px;
    -webkit-animation: spaceship 15s 1s ease 1 forwards;
    animation: spaceship 15s 1s ease 1 forwards;
    .spaceship {
      width: 43px;
      position: absolute;
    }
    .spaceship-light {
      width: 40px;
      position: absolute;
      top: 22px;
      left: 2px;
      opacity: 0;
      -webkit-animation: spaceship-light 15s 1s ease-in 1;
      animation: spaceship-light 15s 1s ease-in 1;
    }
  }
}

.star-one {
  width: 30px;
  left: 280px;
  top: 260px;
  -webkit-animation: star-one 4s .5s linear infinite;
  -moz-animation: star-one 4s .5s linear infinite;
  animation: star-one 4s .5s linear infinite;
}

.star-two {
  width: 64px;
  left: 130px;
  top: 490px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: star-two 5s 1s linear infinite;
  animation: star-two 5s 1s linear infinite;
}

.star-three {
  width: 40px;
  left: 1000px;
  -webkit-animation: star-one 6s 1s linear infinite;
  -moz-animation: star-one 6s 1s linear infinite;
  animation: star-one 6s 1s linear infinite;
  @include breakpoint(0 1199px) {
    left: 70%;
  }
}

.star-four {
  width: 30px;
  right: 760px;
  top: 520px;
  opacity: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: star-four 3s .5s linear infinite;
  animation: star-four 3s .5s linear infinite;
  @include breakpoint(0 1199px) {
    right: 30%;
  }
}

.star-five {
  width: 55px;
  top: 80px;
  right: 480px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: star-two 4s .5s linear infinite;
  animation: star-two 4s .5s linear infinite;
  @include breakpoint(0 1199px) {
    top: 15%;
  }
}

.star-six {
  width: 30px;
  right: 230px;
  top: 610px;
  -webkit-animation: star-one 6s .5s linear infinite;
  -moz-animation: star-one 6s .5s linear infinite;
  animation: star-one 6s .5s linear infinite;

}

@media (max-width: 1199px) {
  [class^="star-"] {
    width: 20px;
  }
}

@-webkit-keyframes star-one {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(0);
    transform: scale(1.1) rotate(0);
  }
  16.6% {
    opacity: .75;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  33.3% {
    opacity: .5;
    -webkit-transform: scale(.9) rotate(-10deg);
    transform: scale(.9) rotate(-10deg);
  }
  50% {
    opacity: .25;
    -webkit-transform: scale(.9) rotate(0);
    transform: scale(.9) rotate(0);
  }
  66.6% {
    opacity: .5;
    -webkit-transform: scale(.9) rotate(10deg);
    transform: scale(.9) rotate(10deg);
  }
  83.3% {
    opacity: .75;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(0);
    transform: scale(1.1) rotate(0);
  }
}
@keyframes star-one {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(0);
    transform: scale(1.1) rotate(0);
  }
  16.6% {
    opacity: .75;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  33.3% {
    opacity: .5;
    -webkit-transform: scale(.9) rotate(-10deg);
    transform: scale(.9) rotate(-10deg);
  }
  50% {
    opacity: .25;
    -webkit-transform: scale(.9) rotate(0);
    transform: scale(.9) rotate(0);
  }
  66.6% {
    opacity: .5;
    -webkit-transform: scale(.9) rotate(10deg);
    transform: scale(.9) rotate(10deg);
  }
  83.3% {
    opacity: .75;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(0);
    transform: scale(1.1) rotate(0);
  }
}

@-webkit-keyframes star-two {
  0% {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
  25% {
    opacity: .9;
  }
  50% {
    opacity: .3;
    -webkit-transform: rotate(60deg) scale(.9);
    transform: rotate(60deg) scale(.9);
  }
  75% {
    opacity: .9;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}
@keyframes star-two {
  0% {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
  25% {
    opacity: .9;
  }
  50% {
    opacity: .3;
    -webkit-transform: rotate(60deg) scale(.9);
    transform: rotate(60deg) scale(.9);
  }
  75% {
    opacity: .9;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

@-webkit-keyframes star-four {
  0% {
    opacity: .4;
    -webkit-transform: rotate(45deg) scale(.9);
    transform: rotate(45deg) scale(.9);
  }
  50% {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
  100% {
    opacity: .4;
    -webkit-transform: rotate(45deg) scale(.9);
    transform: rotate(45deg) scale(.9);
  }
}
@keyframes star-four {
  0% {
    opacity: .4;
    -webkit-transform: rotate(45deg) scale(.9);
    transform: rotate(45deg) scale(.9);
  }
  50% {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
  100% {
    opacity: .4;
    -webkit-transform: rotate(45deg) scale(.9);
    transform: rotate(45deg) scale(.9);
  }
}

@-webkit-keyframes spaceship {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    top: -110px;
    left: -200px;
  }
  15% {
    top: 8px;
    left: -10px;
    z-index: 999;
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    top: 8px;
    left: -10px;
  }
  60% {
    top: 8px;
    left: 520px;
  }
  62% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  67% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    top: 8px;
    left: 520px;
  }
  75% {
    z-index: 999;
    top: -132px;
    left: 740px;
  }
  99% {
    top: 138px;
    left: 1790px;
    opacity: 1;
  }
  100% {
    top: 138px;
    left: 1790px;
    opacity: 0;
  }
}
@keyframes spaceship {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    top: -110px;
    left: -200px;
  }
  15% {
    top: 8px;
    left: -10px;
    z-index: 999;
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    top: 8px;
    left: -10px;
  }
  60% {
    top: 8px;
    left: 520px;
  }
  62% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  67% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    top: 8px;
    left: 520px;
  }
  75% {
    z-index: 999;
    top: -132px;
    left: 740px;
  }
  99% {
    top: 138px;
    left: 1790px;
    opacity: 1;
  }
  100% {
    top: 138px;
    left: 1790px;
    opacity: 0;
  }
}

@-webkit-keyframes spaceship-light {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  23% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  62% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes spaceship-light {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  23% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  62% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes top-student-scan {
  0% {
    background-color: rgba(32, 92, 116, 0.33);
  }
  20% {
    background-color: rgba(32, 92, 116, 0.33);
  }
  23% {
    background-color: rgba(255, 255, 255, .1);
  }
  60% {
    background-color: rgba(255, 255, 255, .1);
  }
  62% {
    background-color: rgba(32, 92, 116, 0.33);
  }
  100% {
    background-color: rgba(32, 92, 116, 0.33);
  }
}
@keyframes top-student-scan {
  0% {
    background-color: rgba(32, 92, 116, 0.33);
  }
  20% {
    background-color: rgba(32, 92, 116, 0.33);
  }
  23% {
    background-color: rgba(255, 255, 255, .1);
  }
  60% {
    background-color: rgba(255, 255, 255, .1);
  }
  62% {
    background-color: rgba(32, 92, 116, 0.33);
  }
  100% {
    background-color: rgba(32, 92, 116, 0.33);
  }
}

@-webkit-keyframes moonRotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes moonRotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes sateliteOne {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
@keyframes sateliteOne {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@-webkit-keyframes sateliteTwo {
  0% {
    -webkit-transform: rotate(0deg) translateX(-50%);
    transform: rotate(0deg) translateX(-50%);
  }
  33.33% {
    -webkit-transform: rotate(-15deg) translateX(-50%);
    transform: rotate(-15deg) translateX(-50%);
  }
  66.66% {
    -webkit-transform: rotate(15deg) translateX(-50%);
    transform: rotate(15deg) translateX(-50%);
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(-50%);
    transform: rotate(0deg) translateX(-50%);
  }
}
@keyframes sateliteTwo {
  0% {
    -webkit-transform: rotate(0deg) translateX(-50%);
    transform: rotate(0deg) translateX(-50%);
  }
  33.33% {
    -webkit-transform: rotate(-15deg) translateX(-50%);
    transform: rotate(-15deg) translateX(-50%);
  }
  66.66% {
    -webkit-transform: rotate(15deg) translateX(-50%);
    transform: rotate(15deg) translateX(-50%);
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(-50%);
    transform: rotate(0deg) translateX(-50%);
  }
}

@-webkit-keyframes sateliteThree {
  0% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}
@keyframes sateliteThree {
  0% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@-webkit-keyframes rocket {
  0% {
    -webkit-transform: scale(.1);
    transform: scale(.1);
    top: 3120px;
  }
  75% {
    top: 270px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  85% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  86% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  87% {
    -webkit-transform: scale(1) rotate(-1deg);
    transform: scale(1) rotate(-1deg);
  }
  88% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  89% {
    -webkit-transform: scale(1) rotate(-1deg);
    transform: scale(1) rotate(-1deg);
  }
  90% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  91% {
    -webkit-transform: scale(1) rotate(-1deg);
    transform: scale(1) rotate(-1deg);
  }
  92% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  93% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  94% {
    top: 270px;
  }
  100% {
    top: -1270px;
  }
}
@keyframes rocket {
  0% {
    -webkit-transform: scale(.1);
    transform: scale(.1);
    top: 3120px;
  }
  75% {
    top: 270px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  85% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  86% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  87% {
    -webkit-transform: scale(1) rotate(-1deg);
    transform: scale(1) rotate(-1deg);
  }
  88% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  89% {
    -webkit-transform: scale(1) rotate(-1deg);
    transform: scale(1) rotate(-1deg);
  }
  90% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  91% {
    -webkit-transform: scale(1) rotate(-1deg);
    transform: scale(1) rotate(-1deg);
  }
  92% {
    -webkit-transform: scale(1) rotate(1deg);
    transform: scale(1) rotate(1deg);
  }
  93% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  94% {
    top: 270px;
  }
  100% {
    top: -1270px;
  }
}


@include breakpoint($desktop-large 1650px) {
  @-webkit-keyframes spaceship {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    5% {
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg);
      top: -110px;
      left: -200px;
    }
    15% {
      top: 8px;
      left: -10px;
      z-index: 999;
    }
    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      top: 8px;
      left: -10px;
    }
    60% {
      top: 8px;
      left: 570px;
    }
    62% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    67% {
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg);
      top: 8px;
      left: 570px;
    }
    75% {
      z-index: 999;
      top: -132px;
      left: 740px;
    }
    99% {
      top: 138px;
      left: 1790px;
      opacity: 1;
    }
    100% {
      top: 138px;
      left: 1790px;
      opacity: 0;
    }
  }
  @keyframes spaceship {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    5% {
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg);
      top: -110px;
      left: -200px;
    }
    15% {
      top: 8px;
      left: -10px;
      z-index: 999;
    }
    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      top: 8px;
      left: -10px;
    }
    60% {
      top: 8px;
      left: 570px;
    }
    62% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    67% {
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg);
      top: 8px;
      left: 570px;
    }
    75% {
      z-index: 999;
      top: -132px;
      left: 740px;
    }
    99% {
      top: 138px;
      left: 1790px;
      opacity: 1;
    }
    100% {
      top: 138px;
      left: 1790px;
      opacity: 0;
    }
  }
}