.gallery-page {
    .gallery-main {
        .gallery-article {
            position: relative;
            margin-bottom: 30px;
            padding: 0 15px;
            .hover-box {
                @extend %hover-white;
                @include breakpoint($mobile) {
                    width: 100%;
                }
                h2 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    margin: 0;
                    width: 80%;
                    text-align: center;
                    @include breakpoint($tablet) {
                        font-size: 26px;
                    }
                    span {
                        text-align: center;
                        text-transform: lowercase;
                        font-size: 20px;
                        display: block;
                    }
                }
            }
            @include breakpoint($mobile) {
                float: left;
                width: 50%;
                margin-bottom: 40px;
            }
            @include breakpoint($desktop) {
                width: 33.3%;
            }
            @include breakpoint($desktop-large) {
                &.gallery-first {
                    width: 63.99317%;
                    height: 732px;
                }
                &.gallery-second, &.gallery-third, &.gallery-fourth {
                    width: 36.00683%;
                    height: 346px;
                }
                &.gallery-fifth {
                    width: 63.99317%;
                    height: 346px;

                }
            }
            > a {
                @include breakpoint($desktop-large) {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: calc(100% - 20px);
                    height: 100%;
                    overflow: hidden;
                }
                &:hover {
                    .hover-box {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/************************************/
/*          Gallery detail          */
/************************************/

.galleries-nav {
    @extend .list-unstyled;
    @extend .list-inline;
    margin-bottom: 45px;
    text-align: center;
    @include breakpoint($tablet) {
        text-align: left;
    }
    li {
        padding: 0;
        margin-right: 12px;
        margin-bottom: 10px;
        @include breakpoint($desktop) {
            margin-bottom: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            padding: 10px 20px;
            background-color: $green-light;
            display: inline-block;
            color: white;
            text-transform: uppercase;
            font-size: 14px;
            @extend %bold-roboto-cond;
            &:hover {
                background-color: $green;
            }
        }
        &.active {
            position: relative;
            &:hover {
                a {
                    @extend %disable-link;
                }
            }
            a {
                background-color: $green;
            }
            //&:after {
            //  @include breakpoint($desktop) {
            //    position: absolute;
            //    content: "";
            //    width: 0;
            //    height: 0;
            //    border-left: 9px solid transparent;
            //    border-right: 9px solid transparent;
            //    border-top: 9px solid $green;
            //    bottom: -9px;
            //    left: 0;
            //    right: 0;
            //    margin: auto;
            //  }
            //}
        }
    }
}

.gallery-entry {
    h1 {
        margin-top: 20px;
        margin-bottom: 10px;
        @include breakpoint($tablet) {
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 36px;
        }
    }
    .cke_editable {
        margin-bottom: 30px;
    }
}

.gallery-item {
    padding: 0 15px;
    margin-bottom: 30px;
    a {
        img {
            transition: all 0.3s;
            margin: auto;
            width: 100%;
            &:hover {
                opacity: 0.8;
            }
            @include breakpoint($tablet) {
                &:hover {
                    opacity: 0.8;
                    transform: scale(1.1);
                }
            }
        }
    }
    @include breakpoint($mobile) {
        float: left;
        width: 50%;
    }
    @include breakpoint($tablet) {
        width: 33.3%;
    }
    @include breakpoint($desktop) {
        width: 25%;
    }
}

.mfp-zoom-out-cur {
    overflow: hidden;
}