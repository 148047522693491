.alert-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  background-color: transparentize($gray-darker,.2);
}

// Finished course
.finished-course {
  @extend .alert-message;
  .cup {
    text-align: center;
    margin-bottom: 15px;
    &+a {
      margin-bottom: 10px;
    }
  }
  a {
    display: table;
    margin: 0 auto;
    &+a {
      font-family: $font-family-cond;
      text-transform: uppercase;
      color: white;
    }
  }
}

// Suspend course
.suspend-course {
  @extend .alert-message;
  text-align: center;
  padding-top: 40px;
  * {
    color: white;
    font-family: $font-family-cond;
  }
  h2 {
    font-size: 26px;
    margin-bottom: 5px;
  }
  .date {
    font-size: 16px;
    margin-bottom: 25px;
    display: inline-block;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 40px;
  }
}