%lesson-state {
  display: block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: white;
  background-color: transparent;
  border: 2px solid $gray-light;
  @include border-radius(50%);
}

%shorten-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%disable-link {
  pointer-events: none;
  cursor: default;
}

%hover-white {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  background: rgba(250, 250, 250, .9);
  transition: all .3s;
}

%social-shares {
  .title {
    float: left;
    font-size: 14px;
    color: $gray-light;

  }
}

%bold-roboto-cond {
  font-weight: bold;
  font-family: $font-family-cond;
}

.standard-hover-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  transition: all .3s;
  background-color: transparentize($navy, .1);
  z-index: 2;
}

.opacity-hover {
  &:hover {
    .user-avatar {
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include border-radius(50%);
        background-color: transparentize(black, .9);
        transition: background-color 0.5s ease;
      }
    }
  }
}