@import "base/vars";

$margins: 1.6em;

body.cke_editable {
    font-family: 'Roboto', 'Arial', sans-serif;
    color: $gray-darker;
    font-size: 16px;
    line-height: 1.6;
    width: 690px;
}


.cke_editable {

    // Headings
    h1 {
        text-transform: none;
        font-size: 32px;
        &:first-child {
            margin: 0;
        }
        &:last-child {
            margin: 0;
        }
    }
    h2 {
        @extend h1;
        font-size: 28px;
    }
    h3 {
        @extend h1;
        font-size: 24px;
    }
    h4 {
        @extend h1;
        font-size: 20px;
    }

    p {
        margin-top: $margins;
        margin-bottom: $margins;
    }

    a {
        text-decoration: underline;
        color: $orange;
    }

    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        margin-top: $margins;
        margin-bottom: $margins;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .info-box {
        font-family: inherit;
        background-color: $aqua;
        color: white;
        padding: 1em;
        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    blockquote {
        font-family: inherit;
        color: $gray;
        margin: $margins 0 $margins $margins;
        border-left: 2px solid $green;
        padding: 1em;
        font-style: italic;
        p {
            margin: 0;
            padding-bottom: $margins;
            &:last-child {
                padding-bottom: 0;
            }
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    // highlight
    pre code {
        overflow-x: auto;
        padding: $margins;
    }
}

#lecture-script {
    body.cke_editable, .cke_editable {
        font-size: 16px;
        line-height: 1.6;

        iframe {
            width: 100%;
        }

        h1 {
            font-size: 32px;
        }

        a {
            text-decoration: underline;
            color: $orange;
        }
    }
}

