.box {
    position: relative;
    @include box-shadow(1px 1px 6px $gray-light);
    padding: 15px;
    background-color: $white;
    .escape {
        display: block;
        margin: -15px -15px 15px;
    }
    h1, h2, h3 {
        &:first-of-type {
            margin-top: 0;
        }
    }
    header {
        margin-bottom: 40px;
    }
    footer {
        border-top: 1px solid $gray-light;
        padding-top: 15px;
        min-height: 55px;
    }
    @include breakpoint($tablet) {
        padding: 20px;
        .escape {
            margin: -20px -20px 15px;
        }
    }
}

.box15 {
    @extend .box;
    padding: 15px;
    position: relative;
    .escape {
        margin: -15px -15px 15px;
    }
}

.box25 {
    @extend .box;
    padding: 15px;
    position: relative;
    .escape {
        margin: -15px -15px 15px;
    }
    @include breakpoint($tablet) {
        padding: 25px;
        .escape {
            margin: -25px -25px 15px;
        }
    }
}