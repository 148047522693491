@mixin notification-variant($name, $background-color) {
  .notification-#{$name} {
    position: absolute;
    z-index: 20;
    top: 0;
    right: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100px 100px 0;
    border-color: transparent $background-color transparent transparent;
    >span, >svg {
      position: absolute;
      top: 15px;
    }
    >span {
      @include avatar-img(30px);
      background-color: $yellow;
      right: -85px;
      i {
        font-size: 18px;
      }
      span {
        font-size: 18px;
        font-weight: bold;
        color: white;
      }
    }
    >svg {
      right: -90px;
    }
    .wishlist {
      position: absolute;
      left: 53px;
      top: 15px;
      z-index: 60;
    }
  }
}

@include notification-variant(error, $red-light);
@include notification-variant(success, $green);
@include notification-variant(info, $blue-light);
@include notification-variant(wish, $yellow-light);

// My courses css start


#modal-edit-avatar {
  .modal-body {
    padding-top: 0;
  }
}
.form-edit-avatar {
  .modal-body-background {
    background-color: #F5F8FA;
    padding: 30px 20px;
    margin-bottom: 20px;
  }
}
.modal-buttons-par {
  input {
    display: block;
    margin-bottom: 20px;
    @include breakpoint($mobile) {
      margin-bottom: 0;
      display: inline-block;
    }
  }
}
// My courses css end



// My courses new design & filters

.latest-views {
  @extend .block-white;
  text-align: center;
  font-family: $font-family-cond;
  @include breakpoint($desktop) {
    padding: 60px 0;
  }
  .students-comments {
    @include breakpoint($tablet) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    ul {
      display: inline-block;
    }
    .progress {
      margin-top: 10px;
      @include breakpoint(0 768px) {
        margin-top: 5px;
      }
    }
    .hover-element {
      display: none;
      position: absolute;
      background-color: $gray-light;
      min-width: 140px;
      width: 100%;
      top: -70px;
      left: -40px;
      padding: 10px 0;
      @include border-radius(3px);
      h4 {
        font-size: 16px;
        margin: 0;
      }
      &:after {
      @include trianle-bottom(10px, $gray-light);
        left: 0;
        right: 0;
        margin: auto;
        bottom: -10px;
      }
    }
    li {
      margin-bottom: 30px;
      float: none;
      display: inline-block;
      &:hover {
        .hover-element {
          display: block;
        }
      }
    }
  }
  article {
    @include breakpoint($tablet) {
      width: 80%;
      margin: 0 auto 55px;
    }
    h1 {
      margin-bottom: 5px;

    }
    p {
      margin-bottom: 30px;
      color: $gray-darker;
    }
    .progress-holder {
      margin-bottom: 40px;
      .progress {
        height: 10px;
        @include breakpoint($tablet) {
          float: none;
          margin: auto;
          position: relative;
          left: -10px;
        }
      }
    }
    .course_link {
      margin-bottom: 10px;
    }
  }
}

section.my-courses {
  @include breakpoint($tablet) {
    position: relative;
    top: -96px;
  }

  .courses-filter {
    display: table;
    margin: auto;
    text-align: center;
    @include breakpoint(0 990px) {
      width: 100%;
    }
    form {
      @include clearfix;
      >label {
        float: left;
        margin: 0 4px;
        font-size: 16px;
        background-color: $blue;
        font-family: $font-family-cond;
        text-align: center;
        width: 100%;
        color: white;
        display: inline-block;
        padding: 12px 0;
        cursor: pointer;
        @include breakpoint($tablet) {
          width: 160px;
        }
        @include breakpoint($desktop-large) {
          width: 200px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.active {
          background-color: $gray-lighter;
          color: $gray-darker;
          @include breakpoint($tablet) {
            padding: 17px 0;
            position: relative;
            top: -10px;
          }
        }
        @include breakpoint(0 990px) {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .courses-content {
    padding-top: 45px;
    .course-box {
      .box-body {
        .title {
          min-height: 44px;
        }
      }
    }
  }
}

.my-courses {
  .course-box {
    min-height: 384px;
    margin-bottom: 30px;
    @include breakpoint($desktop) {
      margin-bottom: 50px;
    }
    img {
      margin: auto;
    }
  }
  header {
    margin-bottom: 0;
  }
  .box-body {
    margin-bottom: 30px;
  }

  .course-box {
    a.btn {
      margin-bottom: 10px;
    }
  }

  .quiz-status {
    line-height: 36px;
    [class^="ksi-"] {
      font-size: 16px;
      color: $orange-dark;
    }
    .progress-quiz {
      span {
        font-size: 16px;
        font-family: $font-family-cond;
        color: $gray-darker;
      }
    }
  }
  .progress-holder {
    @extend .clearfix;
    [class^="ksi-"] {
      float: right;
      position: relative;
      top: -9px;
      font-size: 20px;
      color: $blue-dark;
      margin-bottom: -6px;
    }
  }
  .progress {
    width: 85%;
    float: left;
    .progress-number {
      width: auto;
      right: -50px;
    }
  }
  .course-box.large-box {
    li {
      margin-bottom: 30px;
      .user-avatar {
        .progress {
          margin-top: 10px;
        }
      }
    }
  }
  .info-message-box {
    margin-top: 0;
    margin-bottom: 50px;
    @include breakpoint($desktop) {
      margin-bottom: 60px;
    }
  }
}

.sub-header-block {
  @extend .row;
  margin-bottom: 50px;
}

p.red {
  color: $red;
}
p {
  &.red, &.last-view {
    margin-bottom: 20px;
  }
}

.my-courses {
  .my-courses {
    .courses-content {
      .course-box {
        .button-wrapper {
          .course_link {
            @include breakpoint($desktop 1199px) {
              height: 36px;
              min-width: 100px;
              font-size: 14px;
              padding: 8px 5px;
            }
            @include breakpoint($desktop-large) {
              min-width: 130px;
              padding: 9px 22px;
            }
          }
        }
      }
    }
  }
}

