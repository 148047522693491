////
/// Master user
//

@mixin message-($color) {
  text-align: left !important;
  background-color: $color;
  padding: 10px 40px 25px;
  position: relative;
  .img-holder {
    margin-top: 15px;
    margin-bottom: 10px;

    img {
      margin: auto;
    }
  }
  p {
    margin: 0;
    font-size: 16px;
    color: white;

    a {
      text-decoration: underline;
    }
  }
  h4 {
    color: white;
    margin-top: 15px;
    @include breakpoint($tablet) {
      font-size: 20px;
    }
  }
  a {
    color: white;
  }
  .btn {
    margin-top: 20px;
  }
  i {
    position: absolute;
    font-size: 22px;
    color: white;
    cursor: pointer;
    right: 15px;
    top: 10px;
  }
}

.message-red {
  @include message-($red-light);
}

.message-green {
  @include message-($green-light);
}

.message-blue {
  @include message-($blue-light);
}

.message-orange {
  @include message-($orange-light);
}

.message-transp {
  @include message-(transparent);
  padding-left: 0;
  padding-right: 0;

  h4 {
    font-family: $font-family-goth;
    color: $gray-darker;
    @include breakpoint($tablet) {
      font-size: 26px;
    }
  }

  p, a {
    color: $gray-darker;
  }
}

.remove-btn {
  width: 40px;
  height: 40px;
  @include border-radius(50%);
  background-color: $red;
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: -20px;
  right: -20px;
  color: transparent;
  cursor: pointer;
  z-index: 99;

  i {
    color: white;
    font-size: 26px;
  }
}

#user-panel {
  &:not(.my-courses):not(.user-course-page):not(.user-payment) {
    background-color: white;
  }

  .user-header {
    background-color: $blue;
    font-family: $font-family-cond;
    position: relative;

    .container {
      @extend .clearfix;
    }

    .user-nav {
      @extend .list-unstyled;
      @extend .clearfix;
      margin: 0;
      padding-top: 10px;
      padding-bottom: 5px;
      @include breakpoint(0 767px) {
        display: none;
      }

      @include breakpoint($tablet) {
        float: left;
        padding-top: 0;
        padding-bottom: 0;
      }

      li {
        padding: 0;
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;

        a {
          text-transform: uppercase;
          color: white;
          display: block;
          padding-top: 5px;
          padding-bottom: 5px;

          &:hover, &:focus {
            text-decoration: none;
          }
        }

        @include breakpoint($mobile) {
          width: 25%;
        }
        @include breakpoint($tablet) {
          margin-bottom: 0;
          width: auto;
          text-align: left;
          a {
            padding: 20px 10px;
          }
        }

        &:hover {
          background-color: $blue-dark;
        }
      }

      li.active {
        background-color: $blue-dark;

        a {
          position: relative;

          &:hover, &:focus {
            text-decoration: none;
          }
        }

        @include breakpoint($tablet) {
          color: white;
        }
      }
    }
  }

  ////
  /// Algolia search
  //
  .cat-ask {
    position: relative;
    display: none;

    &.bottom-header-cat {
      margin: 0 auto 10px;
      width: 100%;
      @include breakpoint(0 991px) {
        display: table;
        margin: 15px auto 15px;
      }

      .form-control {
        margin-top: 5px;
        height: 40px;
      }
    }

    input {
      border: none;
      outline: none;

      &[type="search"] {
        background-color: #F2F3F5;
        color: #6D747E;
        font-size: 14px;
        @include placeholder(#6D747E);
        padding-right: 40px;
      }
    }

    &:after {
      content: "";
      background: url("../images/skoljkica-sm.png") no-repeat transparent;
      background-size: cover;
      width: 30px;
      height: 26px;
      text-indent: -9999px;
      position: absolute;
      right: 8px;
      top: 25px;
      transform: translateY(-50%);
    }

    // Algolia input
    .twitter-typeahead {
      width: 100%;
      @include breakpoint($tablet) {
        max-width: 250px;
      }
      @include breakpoint($desktop) {
        max-width: 340px;
      }
    }

    .tt-input {
      padding-left: 10px;

      &::selection {
        background-color: $aqua-light;
      }

      &::-moz-selection {
        background-color: $aqua-light;
      }
    }

    // Suggestion menu
    .tt-menu {
      display: none;
      z-index: 49 !important;
      position: absolute;
      right: 2px;
      top: 50px;
      width: 100%;
      @include breakpoint($tablet) {
        max-width: 300px;
      }
      @include breakpoint($desktop) {
        max-width: 340px;
      }
      min-height: 20px;
      background-color: white;
      cursor: pointer;
      box-shadow: 1px 1px 4px rgba(29, 176, 156, 0.3);

      article {
        padding: 10px;

        &:not(:first-child) {
          padding-top: 10px;
        }

        h3 {
          font-size: 12pt;
          margin: 0;
        }

        em {
          font-style: normal;
          background-color: $aqua-light;
        }
      }

      .tt-suggestion {
        &:hover {
          background-color: $gray-lighter !important;
        }
      }

      .tt-cursor {
        background-color: $gray-lighter !important;
      }

      .tt-footer {
        text-align: right;
        cursor: default !important;
        padding: 5px 10px !important;

        .algolia-img {
          height: 14px;
        }
      }
    }

    // New custom cat search start
    &.new {
      #lessons-list {
        background-color: white;
        position: absolute;
        width: 100%;
        left: 0;
        top: 45px;
        z-index: 40;
        box-shadow: 1px 1px 4px rgba(29, 176, 156, 0.3);

        ul {
          margin: 0;

          li {
            padding: 10px;
            cursor: pointer;
            font-size: 12pt;
            border-bottom: 1px solid $gray-lighter;
            @include clearfix;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: $gray-lighter;
            }

            &.selected-list {
              background-color: $gray-lighter;
            }

            .course-name {
              float: right;
              color: $blue-light;
              font-size: 12px;
            }
          }
        }
      }
    }

    // New custom cat search end
    &.main-header-cat {
      float: left;
      width: 290px;
      margin-top: 3px;

      input {
        height: 45px;
      }

      &:after {
        top: 22px;
      }

      @include breakpoint($desktop) {
        display: block;
      }
      @include breakpoint($desktop-large) {
        width: 450px;
      }
      @include breakpoint(1500px) {
        width: 630px;
      }
    }
  }

  // Answer box new
  #liModal {
    .modal-dialog {
      width: 90%;

      .modal-content {
        img {
          max-width: 100%;
          height: auto !important;
        }
      }

      @include breakpoint(0 543px) {
        .modal-footer {
          button {
            display: table;
            margin: 15px 0 auto auto;
          }
        }
      }
    }
  }

  /////// Algolia search END

  // Rotate style headers start

  .user-header {
    .user-nav {
      li {
        a {
          font-size: 14px;
          @include breakpoint($tablet) {
            padding: 20px 10px;
          }
          @include breakpoint($desktop) {
            padding: 20px 20px;
          }
        }
      }
    }
  }

  .icon-holder {
    .student-icons {
      position: absolute;
      left: -1px;
      top: -1px;

      &.w50 {
        width: 140px;
        height: 140px;
        @include breakpoint($tablet 990px) {
          width: 90px;
          height: 90px;
        }

        img {
          width: 70px;
          position: absolute;
          top: 50%;
          left: 50%;
          -moz-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          @include breakpoint($tablet 990px) {
            width: 45px;
          }
        }
      }
    }
  }

  // Rotate style headers end

  .user-cover {
    position: relative;
    //height: 90px;
    //overflow: hidden;
    display: none;
    @include breakpoint($mobile) {
      display: block;
    }
    @include breakpoint(1300px) {
      height: 300px;
      overflow: hidden;
    }

    .title {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      z-index: 9;
      margin-bottom: 0;
      @include breakpoint($desktop) {
        font-size: 40px;
      }
      @include breakpoint($desktop-large) {
        font-size: 50px;
      }
      @include breakpoint(1300px) {
        top: 50%;
      }
    }
  }

  .content-filters {
    @extend .list-unstyled;
    @extend .clearfix;
    @include breakpoint($tablet) {
      display: table;
      margin: 0 auto 4px;
      position: relative;
      top: -46px;
    }

    li {
      @include breakpoint(0 990px) {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include breakpoint($tablet) {
        float: left;
        margin: 0 4px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      a {
        font-size: 16px;
        color: white;
        background-color: $blue;
        text-align: center;
        padding: 0 20px;
        display: block;
        font-family: $font-family-cond;
        height: 46px;
        line-height: 46px;
        @include breakpoint($desktop) {
          display: inline-block;
          min-width: 200px;
        }

        &:hover {
          background-color: white;
          color: $gray-darker;
        }
      }

      &.active {
        a {
          background-color: white;
          color: $gray-darker;

          &:hover {
            background-color: white;
            color: $gray-darker;
          }
        }
      }
    }
  }

  .block-white {
    .form-control, input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="search"], input[type="url"], input[type="tel"], textarea, select {
      background-color: $gray-lighter;
      border: none;
      color: $gray-darker;
    }

    label {
      font-family: $font-family-cond;
    }
  }

  fieldset {
    margin-bottom: 40px;
  }

  .portfolios-block {
    .title, .desc {
      text-align: center;
    }

    .desc {
      margin-bottom: 50px;
    }

    .portfolios-preview {
      @include breakpoint($desktop) {
        &:nth-child(2n+1) {
          clear: both;
        }
      }

      .portfolio-description {
        word-wrap: break-word;
      }
    }
  }

  .user-portfolio-content {
    @include breakpoint(0 $desktop) {
      margin-bottom: 60px;
      margin-top: 20px;
    }

    .desc {
      @include breakpoint($tablet) {
        margin-bottom: 35px;
      }
    }
  }

  #portfolio {
    form {
      margin-top: 20px;
      @include breakpoint($tablet) {
        margin-top: 50px;
        margin-bottom: 40px;
      }

      textarea {
        margin-bottom: 35px;

        & + {
          .error {
            margin-top: -20px;
            position: relative;
            top: -30px;
          }
        }
      }
    }
  }

  .students-box {
    position: relative;

    .remove-btn {
      width: 40px;
      height: 40px;
      @include border-radius(50%);
      background-color: $red;
      text-align: center;
      line-height: 40px;
      position: absolute;
      top: -20px;
      right: -20px;
      color: transparent;
      content: "";

      i {
        color: white;
        font-size: 26px;
      }
    }
  }

  textarea {
    height: 250px;
  }

  &.edit-profile, &.team-company  {
    .select2-container--default .select2-selection--single {
      background-color: $gray-lighter;
    }
  }

  &.edit-profile {
    .note {
      margin-bottom: 5px;
      margin-top: -15px;
      float: left;
    }

    .content-filters {
      li {
        a {
          @include breakpoint($tablet 990px) {
            min-width: 140px;
          }
          @include breakpoint($desktop 1199px) {
            min-width: 180px;
          }
        }
      }
    }
  }

  .user-payments-content {
    fieldset {
      padding-top: 0;
    }
  }

  #neplaceno {
    @include breakpoint(0 543px) {
      .table {
        tr {
          td, th {
            &:first-child {
              strong {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
    @include breakpoint(0 767px) {
      .table {
        tr {
          td, th {
            display: none;

            &:first-child,
            &:last-child {
              display: table-cell;
            }
          }
        }
      }
    }

    .bill-info {
      text-transform: uppercase;
    }
  }

  #placeno {
    @include breakpoint(0 543px) {
      .table {
        tr {
          td, th {
            &:nth-child(4) {
              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
    @include breakpoint(0 767px) {
      .table {
        tr {
          td, th {
            display: none;

            &:nth-child(4),
            &:last-child {
              display: table-cell;
            }
          }
        }
      }
    }
  }

  #neplaceno,
  #placeno {
    .table thead tr td,
    .table thead tr th,
    .table tbody tr td,
    .table tbody tr th {
      padding: 13px 10px;
    }

    .courseTitleData {
      width: 250px;
    }

    .btn {
      padding: 3px 6px;
    }

    font-family: $font-family-cond;
  }

  //&.my-courses {
  //  @include breakpoint($desktop) {
  //    .course-box {
  //      &:nth-child(3n+1) {
  //        clear: both;
  //      }
  //    }
  //  }
  //}

}

.btn-custom {
  height: 30px !important;
  color: #333333 !important;
  padding: 4px 10px !important;
  font-size: 14px !important;

  &:hover {
    color: white !important;
  }
}

.userPanel-header {
  .menu {
    > .btn-custom {
      display: none;
      float: left;
      margin-right: 20px;
      margin-top: 10px;
      @include breakpoint($desktop) {
        display: inline-block;
      }
    }
  }

  .menu {
    nav {
      @include breakpoint($desktop) {
        display: none;
      }
    }
  }
}

.activation-info {
  background-color: lighten($orange-light, 10%);
  color: darken($orange-dark, 10%);
  padding: 20px 0;
  font-size: 16px;
  text-align: center;

  a {
    color: darken($orange-dark, 30%);
  }
}

#base_user_html, .user-payment-end, .user-payment-end main {
  @include breakpoint(1199px) {
    //height: 100%;
  }
}

.user-payment-end {
  .content {
    background: $green;
    //@media (min-height: 750px) and (min-width: 1200px) {
    //  position: fixed;
    //  width: 100%;
    //  height: calc(100% - 172px);
    //  > .container {
    //    position: relative;
    //    top: 50%;
    //    transform: translateY(-50%);
    //  }
    //}
    @include breakpoint($tablet 1199px) {
      padding: 50px 0;
    }
    @media (min-width: 1200px) and (max-height: 749px) {
      padding: 50px 0;
    }
    @include breakpoint(0 767px) {
      img {
        width: 150px;
        margin: 30px auto;
      }
    }
    @include breakpoint($desktop-large) {
      padding: 100px 0;
    }
    @include breakpoint(1920px) {
      padding: 150px 0;
      height: calc(100vh - 172px);
    }

    .user-payments-content {
      padding-top: 40px;
      padding-left: 20px;
      margin-bottom: 60px;

      h2 {

        color: white;
        @include breakpoint($tablet) {
          font-size: 50px;
        }
      }

      h4 {
        font-size: 20px;
        color: white;

        &:first-of-type {
          margin-bottom: 30px;
        }

        &:last-of-type {
          margin-bottom: 20px;
        }
      }

      p {
        @extend .clearfix;
        color: white;
        font-size: 16px;
        margin-bottom: 5px;

        span {
          display: inline-block;

          &:first-child {
            min-width: 130px;
            float: left;
            font-weight: bold;
            @include breakpoint($tablet) {
              min-width: 150px;
            }
          }

          &:last-child {
            width: calc(100% - 130px);
            @include breakpoint($tablet) {
              width: calc(100% - 150px);
            }
          }
        }
      }

      a {
        margin-top: 45px;
      }
    }
  }
}

// Wrong tab showing prevention ( all are hidden until easyTabs shows it)
.user-portfolio-content {
  > div {
    display: none;
  }
}

.user-payments-content {
  > div {
    display: none;
  }
}

.user-settings-content {
  > div {
    display: none;
  }

  #podesavanja {
    fieldset {
      margin-bottom: 10px;
    }

    form {
      fieldset {
        margin-bottom: 40px;
      }
    }
  }
}

.user-consultation:not(.user-consulting) {
  .user-consultation-content {
    > div {
      display: none;
    }
  }
}

//#portfolios {
//  _background-color: #f5f5f5;
//  display: none;
//}

.user-activation {
  .activation-holder {
    & * {
      font-family: $font-family-goth;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 50px;
      line-height: 2;
      letter-spacing: .7px;

      span {
        color: $blue;
        text-decoration: none;
        background-color: white;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .no-email {
      background-color: #33A7C8;
      padding: 40px;

      h3 {
        font-size: 30px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      p {
        font-size: 20px;
        margin-bottom: 20px;
        letter-spacing: .7px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #B9E7EB !important;
        }
      }
    }
  }
}

.set-password-page.custom-bkg, .user-activation.custom-bkg {
  .left {
    display: none;
  }

  .holder {
    padding: 0;
  }

  @include breakpoint($desktop) {
    .left {
      display: block;
    }
    .right {
      form {
        width: 80%;
      }

      .holder {
        padding-top: 150px;
        padding-left: 0;
        margin-left: -14px;
      }
    }
  }

  .main-container {
    @include breakpoint($desktop) {
      position: relative;
      &:before {
        width: 190px;
        height: 190px;
        background-color: #FEAC1C;
        @include border-radius(50%);
        position: absolute;
        right: 0;
        top: -96px;
        z-index: -1;
      }
    }
  }
}

.student-info {
  &.activate-account {
    border: 1px solid $green-dark;
    padding: 30px;
    margin-bottom: 30px;

    .left-column {
      @extend .col-md-8;

      .lead {
        margin-bottom: 20px;
        @include breakpoint($desktop) {
          margin-bottom: 0;
        }
      }
    }

    .right-column {
      @extend .col-md-4;
      @include breakpoint($desktop) {
        text-align: right;
      }
    }
  }
}

@include breakpoint($desktop) {
  .user-portfolio {
    [class^=message-] {
      margin-top: -4px;
    }
  }
}

#not-receive-email {
  .modal-title {
    color: $gray-darker;
  }

  label {
    color: $gray-dark;
  }

  input, textarea {
    border-color: $gray-light;
    color: $gray-dark;
    background-color: white;
  }

  h4 {
    color: $gray-darker;
  }

  p {
    color: $gray-dark;
  }
}

// New Design header

#user-panel {
  .logo-user {
    float: left;

    a {
      display: block;
      width: 50px;
      height: 50px;
      background: url("../images/logo-user.png") no-repeat;
      background-size: contain;
      text-indent: -9999px;
      transition: all .3s;
    }
  }

  .menu-toggle {
    float: left;
    margin-left: 15px;
    margin-top: 13px;

    a {
      width: 20px;
      height: 20px;
      background: url("../images/menu-toggle.png") no-repeat;
      display: block;
      text-indent: -9999px;
    }
  }

  .main-header {
    &.header-shrink {
      .logo-user {
        a {
          width: 45px;
          height: 45px;
          transition: all .3s;
        }
      }
    }
  }

  //New design start 17.05.18
  .bl {
    @include breakpoint($tablet) {
      border-left: 1px solid #EEEDE8;
    }
  }

  .user-submenu {
    @extend .list-unstyled;
    margin-top: 35px;
    margin-bottom: 30px;
    font-family: $font-family-cond;

    li {
      margin-bottom: 10px;

      a {
        display: block;
        font-size: 16px;
        color: $gray-darker;
        padding-bottom: 9px;
        border-bottom: 1px solid #EEEDE8;

        &:hover {
          font-weight: bold;
        }

        &.delete {
          color: $red-new;
        }
      }

      &:last-child {
        a {
          border-bottom: none;
        }
      }

      &.active {
        a {
          font-weight: bold;
        }
      }
    }
  }

  .userPageTitle {
    font-family: $font-family-goth;
    color: $blue-dark;
    @include breakpoint($desktop) {
      font-size: 34px;
    }

    &.delete {
      color: $red-new;
    }
  }

  .upInfo {
    margin-bottom: 30px;
    @include breakpoint($desktop) {
      margin-bottom: 60px;
    }
  }

  legend {
    font-size: 20px;
    border-color: #EEEDE8;
    margin-bottom: 15px;

    &.haveSwitch {
      font-size: 16px;
      padding-bottom: 24px;
    }
  }

  .form-group {
    input, textarea {
      font-size: 14px;
      _background-color: #f1f1f1;
    }

    label {
      font-size: 16px;
    }
  }

  .silverDesc {
    font-size: 16px;
    font-family: $font-family-cond;
    color: #999;
  }

  .profile-edit-information {
    .avatar-holder {
      display: flex;
      align-items: center;

      .user-avatar {
        margin-right: 20px;

        .student-icons {
          left: 0;
          top: 0;

          &.w50 {
            width: 130px;
            height: 130px;
          }
        }
      }

      .edit-link {
        a {
          font-family: $font-family-cond;
          color: $blue-dark;
          font-weight: bold;
          font-size: 20px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .custom-gutter {
    padding-left: 30px;
    padding-right: 30px;

    > .row {
      margin-left: -30px;
      margin-right: -30px;

      .cus-gutt-col-3,
      .cus-gutt-col-9 {
        padding-left: 30px;
        padding-right: 30px;
      }
      .cus-gutt-col-9.bl {
        @include breakpoint(1900px) {
          padding-right: 0;
        }
      }
    }
  }

  //New design end 17.05.18
}

.prepare, .consultation-confirmation-online {
  .payment-holder-info {
    padding: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $green-dark;

    ul {
      @extend .list-unstyled;
    }
  }

  .skoljkica {
    max-width: 270px;
    width: 100%;
    margin-bottom: 30px;
  }

  main {
    a {
      color: white;
      text-decoration: underline;
    }
  }

  label {
    margin-left: 0 !important;
  }
}

#utisci-ocene {
  .course-title {
    margin-bottom: 20px;
  }

  .rating-message-holder {
    .user-rate-feedback {
      color: $blue;
      font-size: 16px;
      font-family: $font-family-cond;
      margin-bottom: 40px;

      span {
        display: inline-block;
        position: relative;
        top: 9px;
        margin-left: 5px;

        svg {
          width: 30px;
        }
      }
    }
  }

  .message-holder {
    margin-bottom: 30px;
    @include breakpoint($desktop) {
      margin-bottom: 70px;
    }

    textarea {
      background-color: transparent !important;
      border-bottom: 1px solid #E0DEDC !important;
      height: auto;
      padding: 10px 15px 0;
      color: #666 !important;
      font-size: 30px;
    }
  }

  .rating-comment {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid #E0DEDC;

    &:last-child {
      border: none;
    }

    header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        margin-right: 20px;
        margin-bottom: 0;
        @include breakpoint($tablet) {
          font-size: 26px;
        }
      }

      .user-rating {
        margin-right: 15px;

        svg {
          position: relative;
          top: 2px;
        }
      }

      > a {
        text-transform: uppercase;
        color: $blue;
      }
    }
  }
}

.mb90 {
  margin-bottom: 90px;
}

.table-certificate {

}

.viewProfile {
  @include breakpoint(0 767px) {
    margin-bottom: 30px;
  }
  @include breakpoint($tablet 1199px) {
    display: none;
  }
  &:hover {
    background-color: #E0DEDC !important;
  }
}

